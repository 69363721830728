import React, { FC, useCallback } from "react";
import { Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { differenceInSeconds } from "date-fns";
import LogRocket from "logrocket";
import { LoginRedirect } from "../LoginRedirect";
import {
	useCurrentUserQuery,
	useRenewSessionMutation,
} from "../../types/graphql-types";
import { useGlobalSnackbar } from "../GlobalSnackbar";
import useInterval from "../../utils/useInterval";
import Routes from "../../routes/Routes";
import useIdleTimer from "../../utils/useIdleTimer";

const SESSION_TIMEOUT_IN_MINUTES = parseInt(
	process.env.SESSION_TIMEOUT_IN_MINUTES || "60"
);

export const ProtectedRoute: FC<RouteProps> = (props) => {
	const { data, loading } = useCurrentUserQuery();
	const { setSnackbarProps, closeSnackbar } = useGlobalSnackbar();
	const history = useHistory();
	const location = useLocation();

	React.useEffect(() => {
		if (data?.currentUser?.userId) {
			LogRocket.identify(data.currentUser.userId, {
				name: data.currentUser.displayName,
				roles: data.currentUser.roles.join(", "),
			});
		}
	}, [data]);

	const [renewSession] = useRenewSessionMutation();

	const onActive = React.useCallback(() => {
		closeSnackbar();
	}, [closeSnackbar]);
	const onIdle = React.useCallback(() => {
		setSnackbarProps({
			open: true,
			success: false,
			message:
				"Your session will expire in less than 5 minutes. To renew your session, please interact with the page.",
			autoHideDuration: null,
			horizontal: "center",
			vertical: "top",
		});
	}, [setSnackbarProps]);
	const onAction = React.useCallback(() => {
		renewSession();
	}, [renewSession]);

	useIdleTimer({
		delay: (SESSION_TIMEOUT_IN_MINUTES - 5) * 60 * 1000,
		onActive,
		onAction,
		onIdle,
		throttle: 5 * 60 * 1000,
	});

	const tokenExpires = data?.currentUser?.tokenExpires;

	const testExpiration = useCallback(() => {
		const diff = differenceInSeconds(tokenExpires || Infinity, Date.now());

		if (diff <= 0) {
			closeSnackbar();
			/**
			 * TODO: Is this necessary?
			 *
			 * If the entire component re-renders on the useInterval callback, this
			 * is unnecessary, as the if statement at the end will handle the redirect.
			 *
			 * If the useInterval hook just fires this callback, then we need to
			 * somehow manually redirect. But even then, it may be better to just
			 * keep `tokenExpires` in a state value and update it in this callback,
			 * so that the state update will force a re-render of the component.
			 */
			history.push({
				pathname: Routes.LOGIN.path,
				search: `?redirect=${encodeURIComponent(location.pathname)}`,
			});
		}
	}, [tokenExpires, closeSnackbar, history, location]);

	useInterval(testExpiration, 1000);

	if (loading) {
		return null;
	}
	if (tokenExpires && new Date(tokenExpires) >= new Date()) {
		return <Route {...props} />;
	} else {
		return <LoginRedirect />;
	}
};
