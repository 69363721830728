import React from "react";
import { useLocation, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Link, Menu, MenuItem, Modal, Fab, Theme } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";

import { FeedbackModal } from "./FeedbackModal";

const useStyles = makeStyles((theme: Theme) => ({
	help: {
		position: "fixed",
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
	help_icon: {
		fill: theme.color.white.main,
	},
}));

export const FloatingActionButton: React.FC<{}> = () => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
	const [modalOpen, setModalOpen] = React.useState(false);
	const location = useLocation();
	const params = useParams();
	const handleClick = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget as Element);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const baseRoute = "https://docs.lakestreet.heartland.com/reference/";
	let route = "";
	let reference = "";
	const portalIdLength = params["portalId"] ? params["portalId"].length : 0;

	if (location.pathname.length - portalIdLength > 2) {
		reference = location.pathname.substr(portalIdLength + 2);
	} else if (portalIdLength !== 0) {
		reference = "landing-page";
	}

	if (route === "") {
		route = baseRoute + reference + "/";
	}

	return params && Object.keys(params).length > 0 ? (
		<>
			<Fab
				size="small"
				color="primary"
				className={classes.help}
				sx={{
					position: "fixed",
				}}
				onClick={(e) => handleClick(e)}
			>
				<HelpIcon className={classes.help_icon} />
			</Fab>
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem>
					<Link href={route} target={`_${reference}`}>
						Documentation
					</Link>
				</MenuItem>
				<MenuItem onClick={() => setModalOpen(!modalOpen)}>
					Send Feedback
				</MenuItem>
			</Menu>
			<Modal open={modalOpen}>
				<FeedbackModal
					open={modalOpen}
					setOpen={setModalOpen}
					closeMenu={handleClose}
				/>
			</Modal>
		</>
	) : null;
};
