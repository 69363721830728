import * as Yup from "yup";
import { EditorState } from "draft-js";
import { FieldValidator } from "formik";
import { getWordCount, getCharCount } from "../components/RichTextEditor";
import PhoneUtil from "google-libphonenumber";

export const wordLimit = (limit: number) =>
	Yup.mixed().test(
		"Word Limit",
		`Cannot exceed ${limit} words`,
		function (value?: unknown) {
			let count = 0;
			if (typeof value === "string") {
				const regex = /(?:\r\n|\r|\n)/g;
				const cleanString = value.replace(regex, "").trim();
				const wordArray = cleanString.match(/\S+/g);
				count = wordArray ? wordArray.length : 0;
			} else if (value instanceof EditorState) {
				count = getWordCount(value.getCurrentContent());
			} else {
				return new Yup.ValidationError(
					"Expected a string or rich text EditorState object",
					value,
					this.path
				);
			}

			if (count <= limit) {
				return true;
			} else {
				return new Yup.ValidationError(
					`${count - limit} word${count - limit === 1 ? "" : "s"} over limit`,
					value,
					this.path
				);
			}
		}
	);

export const characterLimit = (limit: number) =>
	Yup.mixed().test(
		"Character Limit",
		`Cannot exceed ${limit} characters`,
		function (value?: unknown) {
			let count = 0;
			if (typeof value === "string") {
				const regex = /(?:\r\n|\r|\n)/g;
				const cleanString = value.replace(regex, "").trim();
				count = cleanString ? cleanString.length : 0;
			} else if (value instanceof EditorState) {
				count = getCharCount(value.getCurrentContent());
			} else {
				return new Yup.ValidationError(
					"Expected a string or rich text EditorState object",
					value,
					this.path
				);
			}

			if (count <= limit) {
				return true;
			} else {
				return new Yup.ValidationError(
					`${count - limit} character${
						count - limit === 1 ? "" : "s"
					} over limit`,
					value,
					this.path
				);
			}
		}
	);

export const isValidPhone = (value: string) => {
	const phoneInstance = PhoneUtil.PhoneNumberUtil.getInstance();
	let result = false;
	if (value.length >= 10) {
		const numbers = value.replace(/\D/g, "");
		try {
			if (numbers.length > 10 && numbers[0] !== "1") {
				result = phoneInstance.isValidNumber(
					phoneInstance.parse(`+${numbers}`)
				);
			} else if (numbers.length > 11) {
				result = phoneInstance.isValidNumber(
					phoneInstance.parse(`+${numbers}`)
				);
			} else {
				result = phoneInstance.isValidNumber(
					phoneInstance.parse(numbers, "US")
				);
			}
		} catch (ex) {
			result = false;
		}
	}

	return result;
};

export const required = createValidatorFunction(
	Yup.string().required("Required")
);

export function createValidatorFunction<T>(
	validator: Yup.Schema<T>
): FieldValidator {
	return (value: T) => {
		try {
			validator.validateSync(value);
			return;
		} catch (error) {
			return error.message;
		}
	};
}
