import React from "react";

import routes, { ProtectedRouteParams } from "../Routes";
import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import {
	useGetServicesQuery,
	ServiceWithChildrenFragment,
	useSetServicesMutation,
	GetServicesDocument,
	Scalars,
	Service as ServiceType,
	useGetFeaturedServicesQuery,
	useSetFeaturedServicesMutation,
	HomeOptionsDocument,
	GetFeaturedServicesDocument,
	useHomeOptionsQuery,
} from "../../types/graphql-types";

import { Formik, Form, Field, FieldProps } from "formik";
import { Service } from "./Service";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { mapGraphQlError } from "../../utils/errorHandler";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";
import {
	SortableFeaturedService,
	SortableFeaturedServiceProps,
} from "./SortableFeaturedService";
import {
	DraggableComponentProps,
	DraggableList,
} from "../../components/DraggableList";
import { WidgetNames } from "../../components/WidgetManager/WidgetNames";

export const Services: React.FC<RouteComponentProps<ProtectedRouteParams>> = ({
	match,
}) => {
	const portalId = match?.params.portalId || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useGetServicesQuery({
		variables: {
			portalId,
		},
	});
	const {
		data: featuredServicesData,
		loading: featuredServicesLoading,
		error: featuredServicesError,
	} = useGetFeaturedServicesQuery({
		variables: {
			portalId,
		},
	});
	const { data: homeOptionsData } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});
	const [selectedFeatured, setSelectedFeatured] = React.useState<string[]>([]);
	const sfOrderInput = selectedFeatured.map((sf) => ({ taxonomyId: sf }));

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Services!",
			});
		}
	}, [error, setSnackbarProps]);

	const [setServicesMutation, setServicesResponse] = useSetServicesMutation({
		variables: {
			portalId,
			taxonomyIds: [],
		},
	});

	const [setFeaturedServicesMutation] = useSetFeaturedServicesMutation({
		variables: {
			portalId,
			featuredServices: {
				settings: { enabled: true },
				order: [],
			},
		},
	});

	const servicesResult = data?.getServices;

	// Strip out __typename fields from the response for initial values
	// Memoize the initial value to optimize re-renders
	const initialValues: Array<ServiceWithChildrenFragment> = React.useMemo(() => {
		const services: Array<ServiceWithChildrenFragment> = [];

		servicesResult?.forEach((service: ServiceType) => {
			services.push({
				__typename: service.__typename || "Service",
				taxonomyId: service.taxonomyId,
				text: service.text,
				enabled: service.enabled,
				doctorChairId: service.doctorChairId || null,
				doctorName: service.doctorName || null,
				children: service.children || [],
				hasFeaturedServiceContent: service.hasFeaturedServiceContent,
			});
		});

		return services;
	}, [servicesResult]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || setServicesResponse.loading,
		});
	}, [loading, setServicesResponse, setLoadingIndicatorProps]);

	React.useEffect(() => {
		// Sets initial values
		setSelectedFeatured(
			featuredServicesData?.getFeaturedServices?.map(
				(featuredService) => featuredService.taxonomyId
			) || []
		);
	}, [featuredServicesData, setSelectedFeatured]);

	function handleFeaturedServicesClick(taxonomyId: string) {
		if (selectedFeatured.includes(taxonomyId)) {
			setSelectedFeatured(
				selectedFeatured.filter(
					(selectedTaxonomyId) => selectedTaxonomyId !== taxonomyId
				)
			);
		} else if (selectedFeatured.length < 3) {
			setSelectedFeatured([...selectedFeatured, taxonomyId]);
		} else {
			// this is handled by the service via disable
		}
	}
	const showFeaturedServices =
		homeOptionsData?.homeOptions?.widgets.find(
			(widget) => widget.name === WidgetNames.featured_services
		)?.enabled || false;

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title={routes.SERVICES.title}
				subtext="Select from the following services those that closest match your
                practice's services offered."
				button={<SaveButton form="services-form" />}
				tooltipText="Featured services can be added (up to 3), removed and arranged
				 below. Don't forget to enable the Featured Services widget in the Widget Manager!"
			/>

			{servicesResult && (
				<div>
					<div>
						{selectedFeatured.length > 0 ? (
							<DraggableList<
								Omit<
									SortableFeaturedServiceProps,
									keyof DraggableComponentProps<string>
								>,
								string
							>
								droppableId="featured-services"
								listItems={selectedFeatured.map((service) => ({
									draggable: true,
									item: service,
								}))}
								setListItems={setSelectedFeatured}
								component={SortableFeaturedService}
								services={servicesResult}
								disableService={handleFeaturedServicesClick}
							/>
						) : (
							""
						)}
					</div>
					<Formik<Array<ServiceWithChildrenFragment>>
						initialValues={initialValues}
						onSubmit={async (services) => {
							const taxonomyIds: Array<Scalars["ID"]> = [];

							// TODO: this step makes the loading indicator lag behind for a sec after pressing save...
							services.forEach((service: ServiceWithChildrenFragment) => {
								if (service.enabled) {
									taxonomyIds.push(service.taxonomyId);

									service.children?.forEach(
										(child: ServiceWithChildrenFragment) => {
											if (child.enabled) {
												taxonomyIds.push(child.taxonomyId);
											}
										}
									);
								}
							});

							const [fsResult, result] = await Promise.all([
								setFeaturedServicesMutation({
									variables: {
										portalId,
										featuredServices: {
											settings: { enabled: true },
											order: sfOrderInput,
										},
									},
									refetchQueries: [
										{
											query: GetFeaturedServicesDocument,
											variables: { portalId },
										},
										{
											query: HomeOptionsDocument,
											variables: { portalId },
										},
									],
									awaitRefetchQueries: false,
								}),
								setServicesMutation({
									variables: {
										portalId,
										taxonomyIds,
									},
									refetchQueries: [
										{
											query: GetServicesDocument,
											variables: { portalId },
										},
									],
									awaitRefetchQueries: true,
								}),
							]);

							if (result?.data && fsResult?.data) {
								setSnackbarProps({
									autoHideDuration: 5000,
									open: true,
									success: true,
									message: "Services Information Saved!",
								});
							} else {
								const error =
									result?.errors?.[0] && mapGraphQlError(result.errors[0]);
								setSnackbarProps({
									autoHideDuration: 5000,
									open: true,
									success: false,
									message:
										error?.displayableError ||
										"Services Information Not Saved!",
								});
							}
						}}
					>
						<Form id="services-form">
							{initialValues.map((_value, index) => (
								<Field key={index} name={index}>
									{({ field, form }: FieldProps) => (
										<Service
											field={field}
											form={form}
											selectedFeatured={selectedFeatured}
											setSelectedFeatured={setSelectedFeatured}
											featuredServicesLoading={featuredServicesLoading}
											featuredServicesError={featuredServicesError}
											handleFeaturedServicesClick={handleFeaturedServicesClick}
											parentIndex={index}
											showFeaturedServices={showFeaturedServices}
										/>
									)}
								</Field>
							))}
						</Form>
					</Formik>
				</div>
			)}
			<ContentFooter>
				<SaveButton form="services-form" />
			</ContentFooter>
		</Layout>
	);
};
