import React from "react";
import { Link, NavLink } from "react-router-dom";

import {
	useOfficeQuery,
	useBuildOfficeMutation,
	useCurrentUserQuery,
} from "../../types/graphql-types";

import { makeStyles } from "@mui/styles";
import {
	Container,
	AppBar,
	Toolbar,
	Avatar,
	Typography,
	Divider,
	Button,
	Theme,
	IconButton,
	Tooltip,
} from "@mui/material";
import { NightsStay, Brightness7 } from "@mui/icons-material";
import HomeOutlined from "@mui/icons-material/HomeOutlined";

import logo from "../../assets/images/logo.png";
import logoMobile from "../../assets/images/logo-mobile.png";

import { ActionMenu } from "./ActionMenu";
import { Nav } from "./Nav/Nav";
import routes, { ProtectedRouteParams } from "../../routes/Routes";
import { ProgressDialog } from "../ProgressDialog";
import { FloatingActionButton } from "./FloatingActionButton";
import { UseGlobalDarkModeContext } from "../GlobalDarkMode";
import { OfficeSearch } from "../OfficeSearch";

interface ThemeProps {
	signedIn: boolean;
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	appbar: {
		backgroundColor: (props) =>
			props.darkMode ? theme.color.darkBlue.main : theme.color.white.main,
	},
	logo: ({ signedIn }: ThemeProps) => ({
		display: "none",
		height: 40,
		marginLeft: theme.spacing(2),
		[theme.breakpoints.up(
			signedIn ? theme.customBreakpoints.lg : theme.customBreakpoints.md
		)]: {
			display: "block",
		},
	}),
	logo_mobile: ({ signedIn }: ThemeProps) => ({
		display: "block",
		height: 40,
		[theme.breakpoints.up(
			signedIn ? theme.customBreakpoints.lg : theme.customBreakpoints.md
		)]: {
			display: "none",
		},
	}),
	logo_link: {
		display: "flex",
	},
	search_container: {
		display: "flex",
		flex: 1,
		justifyContent: "flex-end",
	},
	office_search: {
		margin: theme.spacing(0, 2),
		flex: 1,
	},
	appbar_right: {
		alignItems: "center",
		display: "flex",
	},
	avatar: {
		display: "inline-block",
		marginLeft: "auto",
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			marginLeft: "unset",
		},
	},
	typography: {
		color: theme.color.grey.dark,
		display: "none",
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			display: "inline-block",
		},
	},
	header: {
		display: "flex",
		flexDirection: "column-reverse",
		alignItems: "center",
		height: 56,
		[theme.breakpoints.up(theme.customBreakpoints.sm)]: {
			flexDirection: "row",
			borderTop: `1px solid ${theme.color.grey.light}`,
			borderBottom: `1px solid ${theme.color.grey.light}`,
			margin: "0px auto",
		},
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			display: "block",
		},
		"& p": {
			color: (props) =>
				props.darkMode ? theme.color.black.dark : theme.color.black.main,
		},
	},
	header_left: {
		display: "flex",
		position: "relative",
		flex: 2,
		alignItems: "center",
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			display: "block",
		},
	},
	navLink: {
		marginLeft: theme.spacing(2),
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			marginLeft: theme.spacing(3),
		},
		[theme.breakpoints.up(theme.customBreakpoints.lg)]: {
			marginLeft: theme.spacing(4),
		},
		textDecoration: "none",
	},
	dashboardButton: {
		color: theme.color.grey.dark,
		fontSize: "1.25rem",
	},
	homeIcon: {
		marginRight: 8,
	},
	office_location: {
		color: theme.color.grey.main,
		flex: 2,
		fontSize: "0.875rem",
		alignItems: "center",
		justifyContent: "space-between",
	},
	preview: {
		alignItems: "center",
		flex: 1,
		textAlign: "right",
		paddingRight: 16,
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			paddingRight: 24,
		},
		[theme.breakpoints.up(theme.customBreakpoints.lg)]: {
			paddingRight: 32,
		},
	},
	preview_btn: {
		backgroundColor: theme.color.pink.main,
		color: (props) =>
			props.darkMode ? theme.color.black.main : theme.color.white.main,
		padding: "2px 24px",
		position: "unset",
		textTransform: "lowercase",

		"&:hover": {
			backgroundColor: theme.color.pink.dark,
		},
	},
	side_nav_container: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 224,
	},
	side_nav: {
		width: "fit-content",
		marginRight: "auto",
	},
	container: {
		flex: 1,
		padding: 0,
	},
	modal: {
		border: "none",
		outline: "none",
	},
	progress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		outline: "none",
	},
	darkMode: {
		color: theme.color.grey.main,
	},
}));

export const Layout: React.FC<Partial<ProtectedRouteParams>> = ({
	children,
	portalId,
}) => {
	const { data, loading } = useOfficeQuery({
		variables: {
			portalId: portalId as string,
		},
		skip: !portalId,
	});
	const { data: currentUserData } = useCurrentUserQuery();
	const [buildOffice, { loading: building }] = useBuildOfficeMutation();
	const { isDarkMode, setDarkMode } = UseGlobalDarkModeContext();
	const currentUser = currentUserData?.currentUser;
	const classes = useStyles({
		signedIn: !!currentUserData?.currentUser,
		darkMode: isDarkMode,
	});
	const appBarColor = isDarkMode ? "#393b41" : "#fffeff";
	return (
		<React.Fragment>
			<AppBar
				position="sticky"
				sx={{ backgroundColor: appBarColor }}
				className={classes.appbar}
			>
				<Toolbar disableGutters>
					<Link to="/" className={classes.logo_link}>
						<img className={classes.logo} src={logo} alt="logo" />
						<img className={classes.logo_mobile} src={logoMobile} alt="logo" />
					</Link>
					<div className={classes.search_container}>
						{portalId && currentUser && (
							<div className={classes.office_search}>
								<OfficeSearch inNav={true} />
							</div>
						)}
						{currentUser && (
							<div className={classes.appbar_right}>
								<Tooltip title="Toggle Dark Mode">
									<IconButton
										onClick={() => {
											setDarkMode(!isDarkMode);
										}}
									>
										{!isDarkMode && <NightsStay className={classes.darkMode} />}
										{isDarkMode && <Brightness7 className={classes.darkMode} />}
									</IconButton>
								</Tooltip>
								<Typography
									sx={{
										margin: "0px 8px 0px auto",
									}}
									className={classes.typography}
								>
									Welcome
									{currentUser?.displayName
										? `, ${currentUser.displayName}`
										: ""}
									!
								</Typography>
								<Avatar
									alt="avatar"
									src="https://placeimg.com/200/200/animals"
									className={classes.avatar}
								/>
								<ActionMenu />
							</div>
						)}
					</div>
				</Toolbar>
			</AppBar>
			{portalId && !loading && data?.office && (
				<React.Fragment>
					<div className={classes.header}>
						<div className={classes.header_left}>
							{currentUser ? (
								<NavLink
									to={routes.DASHBOARD.toPath({ portalId })}
									className={classes.navLink}
								>
									<Button
										sx={{
											fontSize: "1.25rem",
											textTransform: "none",
											color: "#4a4a4a",
										}}
										className={classes.dashboardButton}
									>
										<HomeOutlined className={classes.homeIcon} />
										{data.office.officeName}
									</Button>
								</NavLink>
							) : (
								<div className={classes.dashboardButton}>
									{data?.office.officeName}
								</div>
							)}
						</div>

						<div className={classes.office_location}>
							{`${data.office.city}, ${data.office.province}`}
						</div>
						<div className={classes.preview}>
							{currentUser && (
								<Button
									className={classes.preview_btn}
									sx={{
										textTransform: "none",
										color: "white",
										backgroundColor: "#C93E92",
										padding: "2px 24px",
										"&:hover": {
											backgroundColor: "#AE2169",
										},
									}}
									onClick={async () => {
										const url = `http://www.${data?.office?.contentUrl}.${
											data?.office?.tld
										}.s3-website-us-east-1.amazonaws.com/?preview=${Math.round(
											Date.now() / 1000
										)}`;
										// Open the tab early and synchronously to get around popup
										// blockers; the preview site has a loading modal that appears
										// until the page is built.
										window.open(url, url);
										if (portalId) {
											await buildOffice({
												variables: {
													portalId,
												},
											});
										}
									}}
									disabled={building}
								>
									preview
								</Button>
							)}
						</div>
					</div>
					<Divider />
				</React.Fragment>
			)}
			{portalId && currentUser ? (
				<Container
					sx={{
						display: "flex",
						margin: "48px auto 0px",
						maxWidth: "1280px !important",
					}}
				>
					<Container
						sx={{ padding: "0px !important" }}
						className={classes.side_nav_container}
					>
						<div className={classes.side_nav}>
							<Nav portalId={portalId} />
						</div>
					</Container>
					<Container
						sx={{ padding: "0px !important" }}
						className={classes.container}
					>
						<>{children}</>
					</Container>
				</Container>
			) : (
				<Container maxWidth="lg" className={classes.container}>
					<>{children}</>
				</Container>
			)}
			{currentUser && <FloatingActionButton />}
			{building && (
				<ProgressDialog
					open={true}
					message="Please wait while we compile the freshest data for your preview..."
				/>
			)}
		</React.Fragment>
	);
};
