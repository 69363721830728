/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY DIRECTLY
 */

import gql from "graphql-tag";
import * as Apollo from "@apollo/client";
import * as ApolloReactHooks from "./apollo-react-hooks";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** A Date value */
	Date: number;
	/** A File to send to the client for download */
	Download: any;
	/** The `Upload` scalar type represents a file upload. */
	Upload: File;
};

export type AboutEsteemWidget = AboutOptionsWidget & {
	__typename?: "AboutEsteemWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	imageId?: Maybe<Scalars["String"]>;
	videoPath?: Maybe<Scalars["String"]>;
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
};

export type AboutInvisalignWidget = AboutOptionsWidget & {
	__typename?: "AboutInvisalignWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	display?: Maybe<InvisalignWidgetOptions>;
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
};

export type AboutMissionStatementWidget = AboutOptionsWidget & {
	__typename?: "AboutMissionStatementWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	text?: Maybe<Scalars["String"]>;
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
};

export type AboutOptions = {
	__typename?: "AboutOptions";
	widgets: Array<AboutOptionsWidget>;
};

export type AboutOptionsInput = {
	widgets: Array<AboutOptionsWidgetInput>;
};

export type AboutOptionsWidget = {
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
};

export type AboutOptionsWidgetInput = {
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
	imageId?: Maybe<Scalars["String"]>;
	/** WelcomeBannerWidget */
	heroImage?: Maybe<HeroImageOptionsInput>;
	/** MissionStatementWidget */
	text?: Maybe<Scalars["String"]>;
	/** InvisalignWidget */
	display?: Maybe<Scalars["String"]>;
	/** EsteemWidget */
	videoPath?: Maybe<Scalars["String"]>;
};

export enum AboutSectionLayouts {
	Default = "default",
	OfficeCollage = "office_collage",
}

export type AboutWelcomeBannerWidget = AboutOptionsWidget & {
	__typename?: "AboutWelcomeBannerWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
};

export enum AlertTypes {
	Info = "Info",
	Warning = "Warning",
	Critical = "Critical",
}

export type AuthUser = {
	__typename?: "AuthUser";
	userId: Scalars["ID"];
	displayName: Scalars["String"];
	tokenExpires: Scalars["Date"];
	roles: Array<Scalars["String"]>;
};

export type BrandBadge = {
	__typename?: "BrandBadge";
	type: BrandBadgeNames;
	enabled: Scalars["Boolean"];
};

export type BrandBadgeInput = {
	type: BrandBadgeNames;
	enabled: Scalars["Boolean"];
};

export enum BrandBadgeNames {
	Invisalign = "invisalign",
}

export type BrandBadgesWidget = HomeOptionsWidget & {
	__typename?: "BrandBadgesWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	brandBadges: Array<BrandBadge>;
};

export enum ButtonStyles {
	Tab = "tab",
	Pill = "pill",
	Block = "block",
}

export type CloudinaryResource = {
	__typename?: "CloudinaryResource";
	publicId: Scalars["ID"];
	version: Scalars["Int"];
	format: Scalars["String"];
	width: Scalars["Int"];
	height: Scalars["Int"];
	type: CloudinaryResourceTypes;
	createdAt: Scalars["Date"];
	altText?: Maybe<Scalars["String"]>;
	faces?: Maybe<Array<FacialAttributes>>;
};

/** See the [Cloudinary Docs](https://cloudinary.com/documentation/upload_images#control_access_to_images) for more information. */
export enum CloudinaryResourceTypes {
	Upload = "upload",
	Private = "private",
	Authenticated = "authenticated",
}

export enum Colors {
	White = "white",
	Sand = "sand",
	Stone = "stone",
}

/**
 * A type to describe the open and closing hours of a day.
 *
 * **Note**: This is just for a proof of concept; it's probably overcomplicating
 * the problem, but it's good for visualizing the typing abilities of GraphQL.
 */
export type DayHours = {
	__typename?: "DayHours";
	/** The time the office opens */
	open: Scalars["String"];
	/** The time the office closes */
	close: Scalars["String"];
};

export type DayHoursInput = {
	open: Scalars["String"];
	close: Scalars["String"];
};

export type Doctor = {
	__typename?: "Doctor";
	/** Unique 10-digit NPI number assigned to each dentist */
	npi: Scalars["ID"];
	firstName?: Maybe<Scalars["String"]>;
	middleName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	/** For example: D.D.S. */
	designation?: Maybe<Scalars["String"]>;
	/** Whether the doctor is DMP certified */
	isDmp?: Maybe<Scalars["Boolean"]>;
	/** The doctor's headshot, as a Cloudinary resource */
	headShot?: Maybe<CloudinaryResource>;
	bio?: Maybe<DoctorBio>;
};

export type DoctorBio = {
	__typename?: "DoctorBio";
	biography?: Maybe<Scalars["String"]>;
	education?: Maybe<Scalars["String"]>;
	professionalMemberships?: Maybe<Scalars["String"]>;
	continuingEducation?: Maybe<Scalars["String"]>;
	hobbiesAndInterests?: Maybe<Scalars["String"]>;
	missionStatement?: Maybe<Scalars["String"]>;
};

export type DoctorBioInput = {
	biography?: Maybe<Scalars["String"]>;
	education?: Maybe<Scalars["String"]>;
	professionalMemberships?: Maybe<Scalars["String"]>;
	continuingEducation?: Maybe<Scalars["String"]>;
	hobbiesAndInterests?: Maybe<Scalars["String"]>;
	missionStatement?: Maybe<Scalars["String"]>;
};

export type DoctorInput = {
	bio?: Maybe<DoctorBioInput>;
};

/** A partial doctor record from Heartland instead of DNN, with only a few fields returned */
export type DoctorSearchResult = {
	__typename?: "DoctorSearchResult";
	/** Unique 10-digit NPI number assigned to each dentist */
	npi: Scalars["ID"];
	firstName?: Maybe<Scalars["String"]>;
	middleName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	/** For example: D.D.S. */
	designation?: Maybe<Scalars["String"]>;
};

export type DoctorsChair = {
	__typename?: "DoctorsChair";
	services: Array<DoctorsChairService>;
	doctors: Array<Doctor>;
};

export type DoctorsChairOption = {
	__typename?: "DoctorsChairOption";
	taxonomyId: Scalars["String"];
	text: Scalars["String"];
};

export type DoctorsChairService = {
	__typename?: "DoctorsChairService";
	taxonomyId: Scalars["ID"];
	enabled: Scalars["Boolean"];
	/** The doctor associated with this service */
	doctor?: Maybe<Doctor>;
	/** The comment associated with this doctor's offering of this service */
	text?: Maybe<Scalars["String"]>;
};

export type EsteemWidget = HomeOptionsWidget & {
	__typename?: "EsteemWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	videoPath?: Maybe<Scalars["String"]>;
	imageId?: Maybe<Scalars["String"]>;
};

export type FacialAttributes = {
	__typename?: "FacialAttributes";
	smile: Scalars["Float"];
	gender: Genders;
	age: Scalars["Int"];
	blur: Scalars["String"];
	noise: Scalars["String"];
	mouthOccluded?: Maybe<Scalars["Boolean"]>;
};

/**
 * The taxonomyId and text of a Service; for use in the featured services widget
 * on the Customize Home page only.
 */
export type FeaturedService = {
	__typename?: "FeaturedService";
	taxonomyId: Scalars["ID"];
	text: Scalars["String"];
};

/**
 * The taxonomyId and text of a Service; for use in the featured services widget
 * on the Customize Home page only.
 */
export type FeaturedServiceInput = {
	taxonomyId: Scalars["ID"];
	text: Scalars["String"];
};

export type FeaturedServiceOrderInput = {
	taxonomyId: Scalars["String"];
};

export type FeaturedServicesInput = {
	settings: FeaturedServicesSettingsInput;
	order: Array<FeaturedServiceOrderInput>;
};

export type FeaturedServicesSettingsInput = {
	enabled: Scalars["Boolean"];
};

export type FeaturedServicesWidget = HomeOptionsWidget & {
	__typename?: "FeaturedServicesWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	services: Array<Maybe<FeaturedService>>;
};

export enum FooterTypes {
	Default = "default",
	Map = "map",
}

export enum Genders {
	Male = "male",
	Female = "female",
	Other = "other",
}

export type GoogleWidget = HomeOptionsWidget & {
	__typename?: "GoogleWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	option?: Maybe<GoogleWidgetOptions>;
};

export enum GoogleWidgetOptions {
	Map = "map",
	VirtualTour = "virtual_tour",
	FiftyFifty = "fifty_fifty",
}

export enum HeaderTypes {
	Default = "default",
	Cta = "cta",
}

export type HeroImage = {
	__typename?: "HeroImage";
	publicId?: Maybe<Scalars["String"]>;
	altText?: Maybe<Scalars["String"]>;
	selectedDoctorNpi?: Maybe<Scalars["String"]>;
	text?: Maybe<Scalars["String"]>;
};

export type HeroImageInput = {
	publicId?: Maybe<Scalars["String"]>;
	altText?: Maybe<Scalars["String"]>;
	selectedDoctorNpi?: Maybe<Scalars["String"]>;
	text?: Maybe<Scalars["String"]>;
};

export type HeroImageOptions = {
	__typename?: "HeroImageOptions";
	type?: Maybe<HeroImageTypes>;
	standard?: Maybe<HeroImage>;
	upload?: Maybe<HeroImage>;
	configured?: Maybe<HeroImage>;
	doctor?: Maybe<HeroImage>;
	prelaunch?: Maybe<PrelaunchHeroImage>;
};

export type HeroImageOptionsInput = {
	type?: Maybe<HeroImageTypes>;
	standard?: Maybe<HeroImageInput>;
	upload?: Maybe<HeroImageInput>;
	configured?: Maybe<HeroImageInput>;
	doctor?: Maybe<HeroImageInput>;
	prelaunch?: Maybe<PrelaunchHeroImageInput>;
};

export enum HeroImageTypes {
	Standard = "standard",
	Upload = "upload",
	Configured = "configured",
	Doctor = "doctor",
}

export type HomeOptions = {
	__typename?: "HomeOptions";
	portalId: Scalars["ID"];
	template: Templates;
	accentColor: Colors;
	buttonStyle: ButtonStyles;
	aboutSection: AboutSectionLayouts;
	widgets: Array<HomeOptionsWidget>;
	header: HeaderTypes;
	footer: FooterTypes;
};

export type HomeOptionsInput = {
	template: Templates;
	accentColor: Colors;
	buttonStyle: ButtonStyles;
	aboutSection: AboutSectionLayouts;
	widgets: Array<HomeOptionsWidgetInput>;
	header: HeaderTypes;
	footer: FooterTypes;
};

export type HomeOptionsWidget = {
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
};

/**
 * Due to constraints with graphql, input types cannot inherit from interfaces;
 * thus, unlike the non-input widget types, where each widget gets its own type that
 * inherits from the base type, for the input we have one giant type, where all the
 * custom widget properties are optional.
 */
export type HomeOptionsWidgetInput = {
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	/** WelcomeBannerWidget */
	heroImage?: Maybe<HeroImageOptionsInput>;
	/** MissionStatementWidget */
	text?: Maybe<Scalars["String"]>;
	/** GoogleWidget */
	option?: Maybe<GoogleWidgetOptions>;
	/** SmileGalleryWidget */
	members?: Maybe<Array<SmileGalleryMemberInput>>;
	/** FeaturedServicesWidget */
	services?: Maybe<Array<Maybe<FeaturedServiceInput>>>;
	/** ProvidersWidget */
	npi?: Maybe<Array<Scalars["ID"]>>;
	/** InvisalignWidget */
	display?: Maybe<Scalars["String"]>;
	/** BrandBadgesWidget */
	brandBadges?: Maybe<Array<BrandBadgeInput>>;
	/** EsteemWidget */
	videoPath?: Maybe<Scalars["String"]>;
	imageId?: Maybe<Scalars["String"]>;
};

export type InvisalignWidget = HomeOptionsWidget & {
	__typename?: "InvisalignWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	display?: Maybe<InvisalignWidgetOptions>;
};

export enum InvisalignWidgetOptions {
	Full = "full",
	Split = "split",
}

export type MissionStatementWidget = HomeOptionsWidget & {
	__typename?: "MissionStatementWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	text: Scalars["String"];
};

export type Mutation = {
	__typename?: "Mutation";
	logIn?: Maybe<AuthUser>;
	renewSession?: Maybe<AuthUser>;
	logOut: Scalars["Boolean"];
	addTeamMember: Array<TeamMember>;
	editTeamMember: TeamMember;
	deleteTeamMember: Scalars["Boolean"];
	/** Updates the doctor */
	editDoctor: Doctor;
	/** Updates the current office's data */
	editOfficeMetadata: OfficeMetadata;
	/** Adds a single service to the office collection */
	addService: Array<Service>;
	/** Adds each of the given services to the office collection */
	addServices: Array<Service>;
	/**
	 * Replaces the office's current services with the given services
	 *
	 * **Important**: This will overwrite any existing service associations
	 */
	setServices: Array<Service>;
	/** Removes a service from the office collection */
	deleteService: Array<Service>;
	/** Removes each of the given services from the office collection */
	deleteServices: Array<Service>;
	/** Puts and orders the chosen featured services */
	setFeaturedServices: Array<FeaturedService>;
	/** Updates the office's homepage options */
	setHomeOptions?: Maybe<HomeOptions>;
	/** Uploads a single cloudinary image */
	uploadImage: CloudinaryResource;
	/** Updates the alt text (context) of a cloudinary image */
	updateAltText: Scalars["Boolean"];
	/** Removes multiple cloudinary images by publicIds */
	deleteImages: Scalars["Boolean"];
	/** Updates the status, comment, and doctor assignment of a doctor's chair service */
	editDoctorsChair: DoctorsChairService;
	arrangeDoctors: Scalars["Boolean"];
	arrangeTeamMembers: Scalars["Boolean"];
	assignDoctor: Scalars["Boolean"];
	deleteDoctorAssignment: Scalars["Boolean"];
	buildOffice: Scalars["Boolean"];
	deployOffice: Scalars["Boolean"];
	sendTelemetryMessage: Scalars["Boolean"];
	sendFeedback: Scalars["Boolean"];
	addSmileGalleryMember: HomeOptions;
	deleteSmileGalleryMember: HomeOptions;
	setAboutOptions?: Maybe<AboutOptions>;
};

export type MutationLogInArgs = {
	username: Scalars["String"];
	password: Scalars["String"];
};

export type MutationAddTeamMemberArgs = {
	portalId: Scalars["ID"];
	teamMember: TeamMemberInput;
};

export type MutationEditTeamMemberArgs = {
	portalId: Scalars["ID"];
	id: Scalars["ID"];
	teamMember: TeamMemberInput;
};

export type MutationDeleteTeamMemberArgs = {
	portalId: Scalars["ID"];
	id: Scalars["ID"];
};

export type MutationEditDoctorArgs = {
	npi: Scalars["ID"];
	doctor: DoctorInput;
};

export type MutationEditOfficeMetadataArgs = {
	portalId: Scalars["ID"];
	officeMetadata: OfficeMetadataInput;
};

export type MutationAddServiceArgs = {
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
};

export type MutationAddServicesArgs = {
	portalId: Scalars["ID"];
	taxonomyIds: Array<Scalars["ID"]>;
};

export type MutationSetServicesArgs = {
	portalId: Scalars["ID"];
	taxonomyIds: Array<Scalars["ID"]>;
};

export type MutationDeleteServiceArgs = {
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
};

export type MutationDeleteServicesArgs = {
	portalId: Scalars["ID"];
	taxonomyIds: Array<Scalars["ID"]>;
};

export type MutationSetFeaturedServicesArgs = {
	portalId: Scalars["ID"];
	featuredServices: FeaturedServicesInput;
};

export type MutationSetHomeOptionsArgs = {
	portalId: Scalars["ID"];
	homeOptions: HomeOptionsInput;
};

export type MutationUploadImageArgs = {
	file: Scalars["Upload"];
	publicId: Scalars["ID"];
	tags?: Maybe<Array<Scalars["String"]>>;
	altText?: Maybe<Scalars["String"]>;
	requestAdvFace?: Maybe<Scalars["Boolean"]>;
};

export type MutationUpdateAltTextArgs = {
	publicId: Scalars["ID"];
	altText: Scalars["String"];
};

export type MutationDeleteImagesArgs = {
	publicIds?: Maybe<Array<Scalars["String"]>>;
};

export type MutationEditDoctorsChairArgs = {
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
	enabled: Scalars["Boolean"];
	text: Scalars["String"];
	npi: Scalars["ID"];
};

export type MutationArrangeDoctorsArgs = {
	portalId: Scalars["ID"];
	order: Array<Scalars["ID"]>;
};

export type MutationArrangeTeamMembersArgs = {
	portalId: Scalars["ID"];
	order: Array<Scalars["ID"]>;
};

export type MutationAssignDoctorArgs = {
	portalId: Scalars["ID"];
	npi: Scalars["ID"];
};

export type MutationDeleteDoctorAssignmentArgs = {
	portalId: Scalars["ID"];
	npi: Scalars["ID"];
};

export type MutationBuildOfficeArgs = {
	portalId: Scalars["ID"];
};

export type MutationDeployOfficeArgs = {
	portalId: Scalars["ID"];
};

export type MutationSendTelemetryMessageArgs = {
	service: Scalars["String"];
	message: Scalars["String"];
	level?: Maybe<TelemetryLevels>;
};

export type MutationSendFeedbackArgs = {
	comments: Scalars["String"];
	appState: Scalars["String"];
	browserInfo: Scalars["String"];
	stackTrace?: Maybe<Scalars["String"]>;
};

export type MutationAddSmileGalleryMemberArgs = {
	portalId: Scalars["ID"];
	smileGalleryMember: SmileGalleryMemberInput;
};

export type MutationDeleteSmileGalleryMemberArgs = {
	portalId: Scalars["ID"];
	guid: Scalars["ID"];
};

export type MutationSetAboutOptionsArgs = {
	portalId: Scalars["ID"];
	aboutOptions: AboutOptionsInput;
};

/**
 * A type to describe the open and closing hours of each day of the week.
 *
 * **Note**: This is just for a proof of concept; it's probably overcomplicating
 * the problem, but it's good for visualizing the typing abilities of GraphQL.
 */
export type OfficeHours = {
	__typename?: "OfficeHours";
	monday?: Maybe<DayHours>;
	tuesday?: Maybe<DayHours>;
	wednesday?: Maybe<DayHours>;
	thursday?: Maybe<DayHours>;
	friday?: Maybe<DayHours>;
	saturday?: Maybe<DayHours>;
	sunday?: Maybe<DayHours>;
};

export type OfficeHoursInput = {
	monday?: Maybe<DayHoursInput>;
	tuesday?: Maybe<DayHoursInput>;
	wednesday?: Maybe<DayHoursInput>;
	thursday?: Maybe<DayHoursInput>;
	friday?: Maybe<DayHoursInput>;
	saturday?: Maybe<DayHoursInput>;
	sunday?: Maybe<DayHoursInput>;
};

/**
 * A type to describe an office's metadata.
 *
 * ---
 *
 * You can add documentation to a type using string literals directly above the type's definition.
 *
 * String literals can span multiple lines using triple-quotes.
 *
 * You can also use **markdown formatting** in your documentation.
 */
export type OfficeMetadata = {
	__typename?: "OfficeMetadata";
	/**
	 * The portal id.
	 *
	 * **Note**: This field actually isn't returned by the metadata API, so any resolver
	 * that returns this type must add it to the API response or an error will be thrown.
	 *
	 * It is important that the type has a unique ID field, which will be used for caching.
	 */
	portalId: Scalars["ID"];
	officeName: Scalars["String"];
	officeLegalName?: Maybe<Scalars["String"]>;
	domain?: Maybe<Scalars["String"]>;
	linkedinUrl?: Maybe<Scalars["String"]>;
	twitterUrl?: Maybe<Scalars["String"]>;
	youtubeUrl?: Maybe<Scalars["String"]>;
	facebookUrl?: Maybe<Scalars["String"]>;
	instagramUrl?: Maybe<Scalars["String"]>;
	snapchatUrl?: Maybe<Scalars["String"]>;
	tiktokUrl?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	street1?: Maybe<Scalars["String"]>;
	street2?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	province?: Maybe<Scalars["String"]>;
	provinceAbbr?: Maybe<Scalars["String"]>;
	postalCode?: Maybe<Scalars["String"]>;
	longitude?: Maybe<Scalars["Float"]>;
	latitude?: Maybe<Scalars["Float"]>;
	hdcid?: Maybe<Scalars["Int"]>;
	carecreditId?: Maybe<Scalars["String"]>;
	gtmCode?: Maybe<Scalars["String"]>;
	gtagId?: Maybe<Scalars["String"]>;
	speeddial?: Maybe<Scalars["Int"]>;
	epicorId?: Maybe<Scalars["String"]>;
	officeHours?: Maybe<OfficeHours>;
	alertType?: Maybe<AlertTypes>;
	alertMessage?: Maybe<Scalars["String"]>;
	/**
	 * Prelaunch Configuration: If the launchDate is configured as a date in the
	 * future, the practice is placed into "prelaunch mode." This mode overrides the
	 * welcome banner to the prelaunch welcome banner, using the options in the
	 * "prelaunch" hero image config of the welcome banner widget, or sensible
	 * defaults if none are provided. After the launch date, the welcome banner
	 * reverts to the user's template/welcome banner selection automatically in the
	 * nightly builds.
	 */
	launchDate?: Maybe<Scalars["String"]>;
	hasMedicaidStatement?: Maybe<Scalars["Boolean"]>;
	medicaidStatementType?: Maybe<Scalars["String"]>;
};

export type OfficeMetadataInput = {
	officeName?: Maybe<Scalars["String"]>;
	officeLegalName?: Maybe<Scalars["String"]>;
	domain?: Maybe<Scalars["String"]>;
	linkedinUrl?: Maybe<Scalars["String"]>;
	twitterUrl?: Maybe<Scalars["String"]>;
	youtubeUrl?: Maybe<Scalars["String"]>;
	facebookUrl?: Maybe<Scalars["String"]>;
	instagramUrl?: Maybe<Scalars["String"]>;
	snapchatUrl?: Maybe<Scalars["String"]>;
	tiktokUrl?: Maybe<Scalars["String"]>;
	phone?: Maybe<Scalars["String"]>;
	email?: Maybe<Scalars["String"]>;
	street1?: Maybe<Scalars["String"]>;
	street2?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	province?: Maybe<Scalars["String"]>;
	provinceAbbr?: Maybe<Scalars["String"]>;
	postalCode?: Maybe<Scalars["String"]>;
	longitude?: Maybe<Scalars["Float"]>;
	latitude?: Maybe<Scalars["Float"]>;
	hdcid?: Maybe<Scalars["Int"]>;
	carecreditId?: Maybe<Scalars["String"]>;
	gtmCode?: Maybe<Scalars["String"]>;
	gtagId?: Maybe<Scalars["String"]>;
	speeddial?: Maybe<Scalars["Int"]>;
	epicorId?: Maybe<Scalars["String"]>;
	officeHours?: Maybe<OfficeHoursInput>;
	launchDate?: Maybe<Scalars["String"]>;
	hasMedicaidStatement?: Maybe<Scalars["Boolean"]>;
	medicaidStatementType?: Maybe<Scalars["String"]>;
};

export type OfficeSearchResult = {
	__typename?: "OfficeSearchResult";
	portalId: Scalars["ID"];
	officeName: Scalars["String"];
	contentUrl?: Maybe<Scalars["String"]>;
	tld?: Maybe<Scalars["String"]>;
	street1?: Maybe<Scalars["String"]>;
	city?: Maybe<Scalars["String"]>;
	province?: Maybe<Scalars["String"]>;
	provinceAbbr?: Maybe<Scalars["String"]>;
	postalCode?: Maybe<Scalars["String"]>;
};

export type PrelaunchHeroImage = {
	__typename?: "PrelaunchHeroImage";
	publicId?: Maybe<Scalars["String"]>;
	altText?: Maybe<Scalars["String"]>;
	text?: Maybe<Scalars["String"]>;
	hiringUrl?: Maybe<Scalars["String"]>;
	instagramPost?: Maybe<Scalars["String"]>;
};

export type PrelaunchHeroImageInput = {
	publicId?: Maybe<Scalars["String"]>;
	altText?: Maybe<Scalars["String"]>;
	text?: Maybe<Scalars["String"]>;
	hiringUrl?: Maybe<Scalars["String"]>;
	instagramPost?: Maybe<Scalars["String"]>;
};

export type ProvidersWidget = HomeOptionsWidget & {
	__typename?: "ProvidersWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	npi?: Maybe<Array<Maybe<Scalars["ID"]>>>;
};

export type Query = {
	__typename?: "Query";
	officeMetadata: OfficeMetadata;
	officeSearch: Array<OfficeSearchResult>;
	/**
	 * Retrieves office data for a given portal ID from the Heartland API.
	 *
	 * **NOTE**: Not the same as office metadata, which comes from DNN and has more information.
	 */
	office: OfficeSearchResult;
	teamMember?: Maybe<TeamMember>;
	teamMembers: Array<TeamMember>;
	/** Returns a list of services that a given office may offer */
	getServices: Array<Service>;
	/** Retrieves information about a specific service at a specific office */
	getService?: Maybe<Service>;
	/** Retrieves featured service for a specific office */
	getFeaturedServices?: Maybe<Array<FeaturedService>>;
	doctor?: Maybe<Doctor>;
	doctors: Array<Doctor>;
	currentUser?: Maybe<AuthUser>;
	homeOptions?: Maybe<HomeOptions>;
	/** RATE LIMITED! Use with caution! */
	getImageByPublicId?: Maybe<CloudinaryResource>;
	/**
	 * RATE LIMITED! Use with caution!
	 *
	 * You should honestly probably just use getImagesByTag instead, but that's
	 * predicated on the assumption that your images have been tagged properly.
	 *
	 * This query is just a proof-of-concept in case future features need to query
	 * for images based on the image "folder" (really just the public ID prefix),
	 * which we would only need to do for images uploaded a long time ago and not
	 * tagged.
	 */
	getImagesByFolder?: Maybe<Array<CloudinaryResource>>;
	getImagesByTag?: Maybe<Array<CloudinaryResource>>;
	/**
	 * Retrieves a list of doctor's chair services under the given parent
	 * service ID and the doctor's that are available to be assigned to them.
	 */
	doctorsChair: DoctorsChair;
	doctorsChairOption: DoctorsChairOption;
	doctorSearch: Array<DoctorSearchResult>;
	getAboutOptions?: Maybe<AboutOptions>;
};

export type QueryOfficeMetadataArgs = {
	portalId: Scalars["ID"];
};

export type QueryOfficeSearchArgs = {
	query: Scalars["String"];
	limit?: Maybe<Scalars["Int"]>;
};

export type QueryOfficeArgs = {
	portalId: Scalars["ID"];
};

export type QueryTeamMemberArgs = {
	portalId: Scalars["ID"];
	teamMemberId: Scalars["ID"];
};

export type QueryTeamMembersArgs = {
	portalId: Scalars["ID"];
};

export type QueryGetServicesArgs = {
	portalId: Scalars["ID"];
};

export type QueryGetServiceArgs = {
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
};

export type QueryGetFeaturedServicesArgs = {
	portalId: Scalars["ID"];
};

export type QueryDoctorArgs = {
	npi: Scalars["ID"];
};

export type QueryDoctorsArgs = {
	portalId: Scalars["ID"];
};

export type QueryHomeOptionsArgs = {
	portalId: Scalars["ID"];
};

export type QueryGetImageByPublicIdArgs = {
	publicId: Scalars["String"];
};

export type QueryGetImagesByFolderArgs = {
	folder: Scalars["String"];
};

export type QueryGetImagesByTagArgs = {
	tag: Scalars["String"];
};

export type QueryDoctorsChairArgs = {
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
};

export type QueryDoctorsChairOptionArgs = {
	npi: Scalars["ID"];
	taxonomyId: Scalars["ID"];
};

export type QueryDoctorSearchArgs = {
	query: Scalars["String"];
	limit?: Maybe<Scalars["Int"]>;
};

export type QueryGetAboutOptionsArgs = {
	portalId: Scalars["String"];
};

export type Service = {
	__typename?: "Service";
	taxonomyId: Scalars["ID"];
	text: Scalars["String"];
	/** Whether the office currently offers this service */
	enabled: Scalars["Boolean"];
	children?: Maybe<Array<Service>>;
	hasFeaturedServiceContent: Scalars["Boolean"];
	/** The ID of the doctor who offers this service */
	doctorChairId?: Maybe<Scalars["ID"]>;
	/** The name of the doctor who offers this service */
	doctorName?: Maybe<Scalars["String"]>;
};

export type SmileGalleryMember = {
	__typename?: "SmileGalleryMember";
	guid: Scalars["ID"];
	patientName: Scalars["String"];
	beforeImagePublicId: Scalars["String"];
	afterImagePublicId: Scalars["String"];
	approverId: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
};

/** Input type for Smile Gallery members. */
export type SmileGalleryMemberInput = {
	guid?: Maybe<Scalars["String"]>;
	patientName: Scalars["String"];
	beforeImagePublicId: Scalars["String"];
	afterImagePublicId: Scalars["String"];
	approverId: Scalars["String"];
	description?: Maybe<Scalars["String"]>;
};

export type SmileGalleryWidget = HomeOptionsWidget & {
	__typename?: "SmileGalleryWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	members: Array<SmileGalleryMember>;
};

export type StandardAboutOptionsWidget = AboutOptionsWidget & {
	__typename?: "StandardAboutOptionsWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	contentItemId?: Maybe<Scalars["Int"]>;
	variationName?: Maybe<Scalars["String"]>;
	isShared?: Maybe<Scalars["Boolean"]>;
	overriddenBy?: Maybe<Scalars["String"]>;
};

export type StandardWidget = HomeOptionsWidget & {
	__typename?: "StandardWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
};

export type TeamMember = {
	__typename?: "TeamMember";
	/** Unique 10-digit number assigned to each team member */
	teamMemberId: Scalars["ID"];
	firstName?: Maybe<Scalars["String"]>;
	middleName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	/** For example: Dental Hygienist */
	designation?: Maybe<Scalars["String"]>;
	/** The team member's headshot, as a Cloudinary resource */
	headShot?: Maybe<CloudinaryResource>;
	biography?: Maybe<Scalars["String"]>;
};

export type TeamMemberInput = {
	firstName?: Maybe<Scalars["String"]>;
	middleName?: Maybe<Scalars["String"]>;
	lastName?: Maybe<Scalars["String"]>;
	/** For example: Dental Hygienist */
	designation?: Maybe<Scalars["String"]>;
	biography?: Maybe<Scalars["String"]>;
};

export enum TelemetryLevels {
	Good = "good",
	Warning = "warning",
	Critical = "critical",
	Start = "start",
	Stop = "stop",
}

export enum Templates {
	Acadia = "acadia",
	Biscayne = "biscayne",
	Congaree = "congaree",
	Denali = "denali",
	Everglades = "everglades",
	Atlanta = "atlanta",
	Boston = "boston",
	Chicago = "chicago",
	Dallas = "dallas",
	Eugene = "eugene",
	Esteem = "esteem",
}

export type WelcomeBannerWidget = HomeOptionsWidget & {
	__typename?: "WelcomeBannerWidget";
	name: Scalars["String"];
	enabled: Scalars["Boolean"];
	moveable: Scalars["Boolean"];
	isPromo?: Maybe<Scalars["Boolean"]>;
	heroImage?: Maybe<HeroImageOptions>;
};

export type AboutOptionsFragment = { __typename?: "AboutOptions" } & {
	widgets: Array<
		| ({
				__typename?: "AboutEsteemWidget";
		  } & AboutOptionsWidget_AboutEsteemWidget_Fragment)
		| ({
				__typename?: "AboutInvisalignWidget";
		  } & AboutOptionsWidget_AboutInvisalignWidget_Fragment)
		| ({
				__typename?: "AboutMissionStatementWidget";
		  } & AboutOptionsWidget_AboutMissionStatementWidget_Fragment)
		| ({
				__typename?: "AboutWelcomeBannerWidget";
		  } & AboutOptionsWidget_AboutWelcomeBannerWidget_Fragment)
		| ({
				__typename?: "StandardAboutOptionsWidget";
		  } & AboutOptionsWidget_StandardAboutOptionsWidget_Fragment)
	>;
};

type AboutOptionsWidget_AboutEsteemWidget_Fragment = {
	__typename?: "AboutEsteemWidget";
} & AboutEsteemWidgetFragment &
	BaseAboutOptionsWidget_AboutEsteemWidget_Fragment;

type AboutOptionsWidget_AboutInvisalignWidget_Fragment = {
	__typename?: "AboutInvisalignWidget";
} & AboutInvisalignWidgetFragment &
	BaseAboutOptionsWidget_AboutInvisalignWidget_Fragment;

type AboutOptionsWidget_AboutMissionStatementWidget_Fragment = {
	__typename?: "AboutMissionStatementWidget";
} & AboutMissionStatementWidgetFragment &
	BaseAboutOptionsWidget_AboutMissionStatementWidget_Fragment;

type AboutOptionsWidget_AboutWelcomeBannerWidget_Fragment = {
	__typename?: "AboutWelcomeBannerWidget";
} & AboutWelcomeBannerWidgetFragment &
	BaseAboutOptionsWidget_AboutWelcomeBannerWidget_Fragment;

type AboutOptionsWidget_StandardAboutOptionsWidget_Fragment = {
	__typename?: "StandardAboutOptionsWidget";
} & BaseAboutOptionsWidget_StandardAboutOptionsWidget_Fragment;

export type AboutOptionsWidgetFragment =
	| AboutOptionsWidget_AboutEsteemWidget_Fragment
	| AboutOptionsWidget_AboutInvisalignWidget_Fragment
	| AboutOptionsWidget_AboutMissionStatementWidget_Fragment
	| AboutOptionsWidget_AboutWelcomeBannerWidget_Fragment
	| AboutOptionsWidget_StandardAboutOptionsWidget_Fragment;

type BaseAboutOptionsWidget_AboutEsteemWidget_Fragment = {
	__typename?: "AboutEsteemWidget";
} & Pick<
	AboutEsteemWidget,
	| "enabled"
	| "moveable"
	| "contentItemId"
	| "variationName"
	| "isShared"
	| "overriddenBy"
	| "name"
>;

type BaseAboutOptionsWidget_AboutInvisalignWidget_Fragment = {
	__typename?: "AboutInvisalignWidget";
} & Pick<
	AboutInvisalignWidget,
	| "enabled"
	| "moveable"
	| "contentItemId"
	| "variationName"
	| "isShared"
	| "overriddenBy"
	| "name"
>;

type BaseAboutOptionsWidget_AboutMissionStatementWidget_Fragment = {
	__typename?: "AboutMissionStatementWidget";
} & Pick<
	AboutMissionStatementWidget,
	| "enabled"
	| "moveable"
	| "contentItemId"
	| "variationName"
	| "isShared"
	| "overriddenBy"
	| "name"
>;

type BaseAboutOptionsWidget_AboutWelcomeBannerWidget_Fragment = {
	__typename?: "AboutWelcomeBannerWidget";
} & Pick<
	AboutWelcomeBannerWidget,
	| "enabled"
	| "moveable"
	| "contentItemId"
	| "variationName"
	| "isShared"
	| "overriddenBy"
	| "name"
>;

type BaseAboutOptionsWidget_StandardAboutOptionsWidget_Fragment = {
	__typename?: "StandardAboutOptionsWidget";
} & Pick<
	StandardAboutOptionsWidget,
	| "enabled"
	| "moveable"
	| "contentItemId"
	| "variationName"
	| "isShared"
	| "overriddenBy"
	| "name"
>;

export type BaseAboutOptionsWidgetFragment =
	| BaseAboutOptionsWidget_AboutEsteemWidget_Fragment
	| BaseAboutOptionsWidget_AboutInvisalignWidget_Fragment
	| BaseAboutOptionsWidget_AboutMissionStatementWidget_Fragment
	| BaseAboutOptionsWidget_AboutWelcomeBannerWidget_Fragment
	| BaseAboutOptionsWidget_StandardAboutOptionsWidget_Fragment;

export type AboutWelcomeBannerWidgetFragment = {
	__typename?: "AboutWelcomeBannerWidget";
} & Pick<
	AboutWelcomeBannerWidget,
	| "enabled"
	| "moveable"
	| "contentItemId"
	| "variationName"
	| "isShared"
	| "overriddenBy"
>;

export type AboutMissionStatementWidgetFragment = {
	__typename?: "AboutMissionStatementWidget";
} & Pick<AboutMissionStatementWidget, "text">;

export type AboutInvisalignWidgetFragment = {
	__typename?: "AboutInvisalignWidget";
} & Pick<AboutInvisalignWidget, "display">;

export type AboutEsteemWidgetFragment = {
	__typename?: "AboutEsteemWidget";
} & Pick<AboutEsteemWidget, "imageId" | "videoPath">;

export type AuthUserFragment = { __typename?: "AuthUser" } & Pick<
	AuthUser,
	"userId" | "displayName" | "tokenExpires" | "roles"
>;

export type FacesFragment = { __typename?: "FacialAttributes" } & Pick<
	FacialAttributes,
	"smile" | "gender" | "age" | "blur" | "noise" | "mouthOccluded"
>;

export type UploadImageFragment = { __typename?: "CloudinaryResource" } & Pick<
	CloudinaryResource,
	| "publicId"
	| "version"
	| "format"
	| "width"
	| "height"
	| "type"
	| "createdAt"
	| "altText"
> & {
		faces?: Maybe<Array<{ __typename?: "FacialAttributes" } & FacesFragment>>;
	};

export type DoctorsQueryDoctorFragment = { __typename?: "Doctor" } & Pick<
	Doctor,
	"npi" | "firstName" | "middleName" | "lastName" | "designation" | "isDmp"
>;

export type DoctorsQueryDoctorHeadShotFragment = { __typename?: "Doctor" } & {
	headShot?: Maybe<
		{ __typename?: "CloudinaryResource" } & Pick<
			CloudinaryResource,
			| "publicId"
			| "version"
			| "format"
			| "width"
			| "height"
			| "type"
			| "createdAt"
			| "altText"
		>
	>;
};

export type DoctorsQueryDoctorBioFragment = { __typename?: "Doctor" } & {
	bio?: Maybe<
		{ __typename?: "DoctorBio" } & Pick<
			DoctorBio,
			| "biography"
			| "education"
			| "professionalMemberships"
			| "continuingEducation"
			| "hobbiesAndInterests"
			| "missionStatement"
		>
	>;
};

export type DoctorsChairFragment = { __typename?: "DoctorsChair" } & {
	services: Array<
		{ __typename?: "DoctorsChairService" } & DoctorsChairServiceFragment
	>;
	doctors: Array<{ __typename?: "Doctor" } & DoctorsChairDoctorFragment>;
};

export type DoctorsChairServiceFragment = {
	__typename?: "DoctorsChairService";
} & Pick<DoctorsChairService, "taxonomyId" | "enabled" | "text"> & {
		doctor?: Maybe<{ __typename?: "Doctor" } & DoctorsChairDoctorFragment>;
	};

export type DoctorsChairDoctorFragment = { __typename?: "Doctor" } & Pick<
	Doctor,
	"npi" | "firstName" | "lastName" | "designation"
>;

export type DoctorsChairOptionFragment = {
	__typename?: "DoctorsChairOption";
} & Pick<DoctorsChairOption, "taxonomyId" | "text">;

export type HomeOptionsFragment = { __typename?: "HomeOptions" } & Pick<
	HomeOptions,
	| "template"
	| "accentColor"
	| "buttonStyle"
	| "aboutSection"
	| "header"
	| "footer"
> & {
		widgets: Array<
			| ({
					__typename?: "BrandBadgesWidget";
			  } & HomeOptionsWidget_BrandBadgesWidget_Fragment)
			| ({
					__typename?: "EsteemWidget";
			  } & HomeOptionsWidget_EsteemWidget_Fragment)
			| ({
					__typename?: "FeaturedServicesWidget";
			  } & HomeOptionsWidget_FeaturedServicesWidget_Fragment)
			| ({
					__typename?: "GoogleWidget";
			  } & HomeOptionsWidget_GoogleWidget_Fragment)
			| ({
					__typename?: "InvisalignWidget";
			  } & HomeOptionsWidget_InvisalignWidget_Fragment)
			| ({
					__typename?: "MissionStatementWidget";
			  } & HomeOptionsWidget_MissionStatementWidget_Fragment)
			| ({
					__typename?: "ProvidersWidget";
			  } & HomeOptionsWidget_ProvidersWidget_Fragment)
			| ({
					__typename?: "SmileGalleryWidget";
			  } & HomeOptionsWidget_SmileGalleryWidget_Fragment)
			| ({
					__typename?: "StandardWidget";
			  } & HomeOptionsWidget_StandardWidget_Fragment)
			| ({
					__typename?: "WelcomeBannerWidget";
			  } & HomeOptionsWidget_WelcomeBannerWidget_Fragment)
		>;
	};

type HomeOptionsWidget_BrandBadgesWidget_Fragment = {
	__typename?: "BrandBadgesWidget";
} & BrandBadgesWidgetFragment &
	BaseHomeOptionsWidget_BrandBadgesWidget_Fragment;

type HomeOptionsWidget_EsteemWidget_Fragment = {
	__typename?: "EsteemWidget";
} & EsteemWidgetFragment &
	BaseHomeOptionsWidget_EsteemWidget_Fragment;

type HomeOptionsWidget_FeaturedServicesWidget_Fragment = {
	__typename?: "FeaturedServicesWidget";
} & FeaturedServicesWidgetFragment &
	BaseHomeOptionsWidget_FeaturedServicesWidget_Fragment;

type HomeOptionsWidget_GoogleWidget_Fragment = {
	__typename?: "GoogleWidget";
} & GoogleWidgetFragment &
	BaseHomeOptionsWidget_GoogleWidget_Fragment;

type HomeOptionsWidget_InvisalignWidget_Fragment = {
	__typename?: "InvisalignWidget";
} & InvisalignWidgetFragment &
	BaseHomeOptionsWidget_InvisalignWidget_Fragment;

type HomeOptionsWidget_MissionStatementWidget_Fragment = {
	__typename?: "MissionStatementWidget";
} & MissionStatementWidgetFragment &
	BaseHomeOptionsWidget_MissionStatementWidget_Fragment;

type HomeOptionsWidget_ProvidersWidget_Fragment = {
	__typename?: "ProvidersWidget";
} & BaseHomeOptionsWidget_ProvidersWidget_Fragment;

type HomeOptionsWidget_SmileGalleryWidget_Fragment = {
	__typename?: "SmileGalleryWidget";
} & SmileGalleryWidgetFragment &
	BaseHomeOptionsWidget_SmileGalleryWidget_Fragment;

type HomeOptionsWidget_StandardWidget_Fragment = {
	__typename?: "StandardWidget";
} & BaseHomeOptionsWidget_StandardWidget_Fragment;

type HomeOptionsWidget_WelcomeBannerWidget_Fragment = {
	__typename?: "WelcomeBannerWidget";
} & WelcomeBannerWidgetFragment &
	BaseHomeOptionsWidget_WelcomeBannerWidget_Fragment;

export type HomeOptionsWidgetFragment =
	| HomeOptionsWidget_BrandBadgesWidget_Fragment
	| HomeOptionsWidget_EsteemWidget_Fragment
	| HomeOptionsWidget_FeaturedServicesWidget_Fragment
	| HomeOptionsWidget_GoogleWidget_Fragment
	| HomeOptionsWidget_InvisalignWidget_Fragment
	| HomeOptionsWidget_MissionStatementWidget_Fragment
	| HomeOptionsWidget_ProvidersWidget_Fragment
	| HomeOptionsWidget_SmileGalleryWidget_Fragment
	| HomeOptionsWidget_StandardWidget_Fragment
	| HomeOptionsWidget_WelcomeBannerWidget_Fragment;

type BaseHomeOptionsWidget_BrandBadgesWidget_Fragment = {
	__typename?: "BrandBadgesWidget";
} & Pick<BrandBadgesWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_EsteemWidget_Fragment = {
	__typename?: "EsteemWidget";
} & Pick<EsteemWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_FeaturedServicesWidget_Fragment = {
	__typename?: "FeaturedServicesWidget";
} & Pick<FeaturedServicesWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_GoogleWidget_Fragment = {
	__typename?: "GoogleWidget";
} & Pick<GoogleWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_InvisalignWidget_Fragment = {
	__typename?: "InvisalignWidget";
} & Pick<InvisalignWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_MissionStatementWidget_Fragment = {
	__typename?: "MissionStatementWidget";
} & Pick<MissionStatementWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_ProvidersWidget_Fragment = {
	__typename?: "ProvidersWidget";
} & Pick<ProvidersWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_SmileGalleryWidget_Fragment = {
	__typename?: "SmileGalleryWidget";
} & Pick<SmileGalleryWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_StandardWidget_Fragment = {
	__typename?: "StandardWidget";
} & Pick<StandardWidget, "name" | "enabled" | "moveable" | "isPromo">;

type BaseHomeOptionsWidget_WelcomeBannerWidget_Fragment = {
	__typename?: "WelcomeBannerWidget";
} & Pick<WelcomeBannerWidget, "name" | "enabled" | "moveable" | "isPromo">;

export type BaseHomeOptionsWidgetFragment =
	| BaseHomeOptionsWidget_BrandBadgesWidget_Fragment
	| BaseHomeOptionsWidget_EsteemWidget_Fragment
	| BaseHomeOptionsWidget_FeaturedServicesWidget_Fragment
	| BaseHomeOptionsWidget_GoogleWidget_Fragment
	| BaseHomeOptionsWidget_InvisalignWidget_Fragment
	| BaseHomeOptionsWidget_MissionStatementWidget_Fragment
	| BaseHomeOptionsWidget_ProvidersWidget_Fragment
	| BaseHomeOptionsWidget_SmileGalleryWidget_Fragment
	| BaseHomeOptionsWidget_StandardWidget_Fragment
	| BaseHomeOptionsWidget_WelcomeBannerWidget_Fragment;

export type WelcomeBannerWidgetFragment = {
	__typename?: "WelcomeBannerWidget";
} & {
	heroImage?: Maybe<
		{ __typename?: "HeroImageOptions" } & HeroImageOptionsFragment
	>;
};

export type HeroImageFragment = { __typename?: "HeroImage" } & Pick<
	HeroImage,
	"publicId" | "altText" | "text" | "selectedDoctorNpi"
>;

export type PrelaunchHeroImageFragment = {
	__typename?: "PrelaunchHeroImage";
} & Pick<
	PrelaunchHeroImage,
	"publicId" | "altText" | "text" | "hiringUrl" | "instagramPost"
>;

export type HeroImageOptionsFragment = {
	__typename?: "HeroImageOptions";
} & Pick<HeroImageOptions, "type"> & {
		standard?: Maybe<{ __typename?: "HeroImage" } & HeroImageFragment>;
		upload?: Maybe<{ __typename?: "HeroImage" } & HeroImageFragment>;
		configured?: Maybe<{ __typename?: "HeroImage" } & HeroImageFragment>;
		doctor?: Maybe<{ __typename?: "HeroImage" } & HeroImageFragment>;
		prelaunch?: Maybe<
			{ __typename?: "PrelaunchHeroImage" } & PrelaunchHeroImageFragment
		>;
	};

export type MissionStatementWidgetFragment = {
	__typename?: "MissionStatementWidget";
} & Pick<MissionStatementWidget, "text">;

export type GoogleWidgetFragment = { __typename?: "GoogleWidget" } & Pick<
	GoogleWidget,
	"option"
>;

export type SmileGalleryWidgetFragment = {
	__typename?: "SmileGalleryWidget";
} & {
	members: Array<
		{ __typename?: "SmileGalleryMember" } & SmileGalleryMemberFragment
	>;
};

export type SmileGalleryMemberFragment = {
	__typename?: "SmileGalleryMember";
} & Pick<
	SmileGalleryMember,
	| "guid"
	| "patientName"
	| "beforeImagePublicId"
	| "afterImagePublicId"
	| "approverId"
	| "description"
>;

export type FeaturedServicesWidgetFragment = {
	__typename?: "FeaturedServicesWidget";
} & {
	services: Array<
		Maybe<{ __typename?: "FeaturedService" } & FeaturedServiceFragment>
	>;
};

export type FeaturedServiceFragment = { __typename?: "FeaturedService" } & Pick<
	FeaturedService,
	"taxonomyId" | "text"
>;

export type BrandBadgesWidgetFragment = { __typename?: "BrandBadgesWidget" } & {
	brandBadges: Array<{ __typename?: "BrandBadge" } & BrandBadgesFragment>;
};

export type BrandBadgesFragment = { __typename?: "BrandBadge" } & Pick<
	BrandBadge,
	"type" | "enabled"
>;

export type InvisalignWidgetFragment = {
	__typename?: "InvisalignWidget";
} & Pick<InvisalignWidget, "display">;

export type EsteemWidgetFragment = { __typename?: "EsteemWidget" } & Pick<
	EsteemWidget,
	"videoPath" | "imageId"
>;

export type OfficeSearchFragment = { __typename?: "OfficeSearchResult" } & Pick<
	OfficeSearchResult,
	| "portalId"
	| "officeName"
	| "contentUrl"
	| "tld"
	| "street1"
	| "city"
	| "province"
	| "provinceAbbr"
	| "postalCode"
>;

export type OfficeHoursMetadataFragment = { __typename?: "OfficeMetadata" } & {
	officeHours?: Maybe<{ __typename?: "OfficeHours" } & OfficeHoursFragment>;
};

export type OfficeHoursFragment = { __typename?: "OfficeHours" } & {
	monday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
	tuesday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
	wednesday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
	thursday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
	friday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
	saturday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
	sunday?: Maybe<{ __typename?: "DayHours" } & DayHoursFragment>;
};

export type DayHoursFragment = { __typename?: "DayHours" } & Pick<
	DayHours,
	"open" | "close"
>;

export type PracticeMetadataFragment = { __typename?: "OfficeMetadata" } & Pick<
	OfficeMetadata,
	| "officeName"
	| "epicorId"
	| "carecreditId"
	| "street1"
	| "postalCode"
	| "city"
	| "provinceAbbr"
	| "hdcid"
	| "latitude"
	| "longitude"
	| "phone"
	| "speeddial"
	| "alertType"
	| "alertMessage"
	| "launchDate"
	| "hasMedicaidStatement"
	| "medicaidStatementType"
>;

export type ServiceWithChildrenFragment = { __typename?: "Service" } & {
	children?: Maybe<
		Array<{ __typename?: "Service" } & ServiceWithoutChildrenFragment>
	>;
} & ServiceWithoutChildrenFragment;

export type ServiceWithoutChildrenFragment = { __typename?: "Service" } & Pick<
	Service,
	| "taxonomyId"
	| "text"
	| "enabled"
	| "doctorChairId"
	| "doctorName"
	| "hasFeaturedServiceContent"
>;

export type SiteMetadataFragment = { __typename?: "OfficeMetadata" } & Pick<
	OfficeMetadata,
	| "facebookUrl"
	| "twitterUrl"
	| "instagramUrl"
	| "youtubeUrl"
	| "snapchatUrl"
	| "linkedinUrl"
>;

export type TeamMemberFragment = { __typename?: "TeamMember" } & Pick<
	TeamMember,
	| "teamMemberId"
	| "firstName"
	| "middleName"
	| "lastName"
	| "designation"
	| "biography"
> & {
		headShot?: Maybe<
			{ __typename?: "CloudinaryResource" } & TeamMemberHeadshotFragment
		>;
	};

export type TeamMemberHeadshotFragment = {
	__typename?: "CloudinaryResource";
} & Pick<
	CloudinaryResource,
	"publicId" | "version" | "format" | "width" | "height" | "type" | "createdAt"
>;

export type AddSmileGalleryMemberMutationVariables = Exact<{
	portalId: Scalars["ID"];
	smileGalleryMember: SmileGalleryMemberInput;
}>;

export type AddSmileGalleryMemberMutation = { __typename?: "Mutation" } & {
	addSmileGalleryMember: { __typename?: "HomeOptions" } & HomeOptionsFragment;
};

export type AddTeamMemberMutationVariables = Exact<{
	portalId: Scalars["ID"];
	teamMember: TeamMemberInput;
}>;

export type AddTeamMemberMutation = { __typename?: "Mutation" } & {
	addTeamMember: Array<{ __typename?: "TeamMember" } & TeamMemberFragment>;
};

export type ArrangeDoctorsMutationVariables = Exact<{
	portalId: Scalars["ID"];
	order: Array<Scalars["ID"]>;
}>;

export type ArrangeDoctorsMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"arrangeDoctors"
>;

export type ArrangeTeamMembersMutationVariables = Exact<{
	portalId: Scalars["ID"];
	order: Array<Scalars["ID"]>;
}>;

export type ArrangeTeamMembersMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"arrangeTeamMembers"
>;

export type AssignDoctorMutationVariables = Exact<{
	portalId: Scalars["ID"];
	npi: Scalars["ID"];
}>;

export type AssignDoctorMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"assignDoctor"
>;

export type BuildOfficeMutationVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type BuildOfficeMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"buildOffice"
>;

export type DeleteDoctorAssignmentMutationVariables = Exact<{
	portalId: Scalars["ID"];
	npi: Scalars["ID"];
}>;

export type DeleteDoctorAssignmentMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"deleteDoctorAssignment"
>;

export type DeleteSmileGalleryMemberMutationVariables = Exact<{
	portalId: Scalars["ID"];
	guid: Scalars["ID"];
}>;

export type DeleteSmileGalleryMemberMutation = { __typename?: "Mutation" } & {
	deleteSmileGalleryMember: {
		__typename?: "HomeOptions";
	} & HomeOptionsFragment;
};

export type DeleteTeamMemberMutationVariables = Exact<{
	portalId: Scalars["ID"];
	id: Scalars["ID"];
}>;

export type DeleteTeamMemberMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"deleteTeamMember"
>;

export type DeployOfficeMutationVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type DeployOfficeMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"deployOffice"
>;

export type EditDoctorMutationVariables = Exact<{
	npi: Scalars["ID"];
	doctor: DoctorInput;
}>;

export type EditDoctorMutation = { __typename?: "Mutation" } & {
	editDoctor: { __typename?: "Doctor" } & DoctorsQueryDoctorHeadShotFragment &
		DoctorsQueryDoctorBioFragment &
		DoctorsQueryDoctorFragment;
};

export type EditDoctorsChairMutationVariables = Exact<{
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
	enabled: Scalars["Boolean"];
	text: Scalars["String"];
	npi: Scalars["ID"];
}>;

export type EditDoctorsChairMutation = { __typename?: "Mutation" } & {
	editDoctorsChair: {
		__typename?: "DoctorsChairService";
	} & DoctorsChairServiceFragment;
};

export type EditOfficeMetadataMutationVariables = Exact<{
	portalId: Scalars["ID"];
	officeMetadata: OfficeMetadataInput;
}>;

export type EditOfficeMetadataMutation = { __typename?: "Mutation" } & {
	editOfficeMetadata: {
		__typename?: "OfficeMetadata";
	} & OfficeHoursMetadataFragment &
		PracticeMetadataFragment &
		SiteMetadataFragment;
};

export type EditTeamMemberMutationVariables = Exact<{
	portalId: Scalars["ID"];
	id: Scalars["ID"];
	teamMember: TeamMemberInput;
}>;

export type EditTeamMemberMutation = { __typename?: "Mutation" } & {
	editTeamMember: { __typename?: "TeamMember" } & TeamMemberFragment;
};

export type LogInMutationVariables = Exact<{
	username: Scalars["String"];
	password: Scalars["String"];
}>;

export type LogInMutation = { __typename?: "Mutation" } & {
	logIn?: Maybe<{ __typename?: "AuthUser" } & AuthUserFragment>;
};

export type LogOutMutationVariables = Exact<{ [key: string]: never }>;

export type LogOutMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"logOut"
>;

export type RenewSessionMutationVariables = Exact<{ [key: string]: never }>;

export type RenewSessionMutation = { __typename?: "Mutation" } & {
	renewSession?: Maybe<{ __typename?: "AuthUser" } & AuthUserFragment>;
};

export type SendFeedbackMutationVariables = Exact<{
	comments: Scalars["String"];
	appState: Scalars["String"];
	browserInfo: Scalars["String"];
	stackTrace?: Maybe<Scalars["String"]>;
}>;

export type SendFeedbackMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"sendFeedback"
>;

export type SendTelemetryMessageMutationVariables = Exact<{
	service: Scalars["String"];
	message: Scalars["String"];
	level?: Maybe<TelemetryLevels>;
}>;

export type SendTelemetryMessageMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"sendTelemetryMessage"
>;

export type SetAboutOptionsMutationVariables = Exact<{
	portalId: Scalars["ID"];
	aboutOptions: AboutOptionsInput;
}>;

export type SetAboutOptionsMutation = { __typename?: "Mutation" } & {
	setAboutOptions?: Maybe<
		{ __typename?: "AboutOptions" } & AboutOptionsFragment
	>;
};

export type SetFeaturedServicesMutationVariables = Exact<{
	portalId: Scalars["ID"];
	featuredServices: FeaturedServicesInput;
}>;

export type SetFeaturedServicesMutation = { __typename?: "Mutation" } & {
	setFeaturedServices: Array<
		{ __typename?: "FeaturedService" } & FeaturedServiceFragment
	>;
};

export type SetHomeOptionsMutationVariables = Exact<{
	portalId: Scalars["ID"];
	homeOptions: HomeOptionsInput;
}>;

export type SetHomeOptionsMutation = { __typename?: "Mutation" } & {
	setHomeOptions?: Maybe<{ __typename?: "HomeOptions" } & HomeOptionsFragment>;
};

export type SetServicesMutationVariables = Exact<{
	portalId: Scalars["ID"];
	taxonomyIds: Array<Scalars["ID"]>;
}>;

export type SetServicesMutation = { __typename?: "Mutation" } & {
	setServices: Array<{ __typename?: "Service" } & ServiceWithChildrenFragment>;
};

export type UpdateAltTextMutationVariables = Exact<{
	publicId: Scalars["ID"];
	altText: Scalars["String"];
}>;

export type UpdateAltTextMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"updateAltText"
>;

export type UploadImageMutationVariables = Exact<{
	file: Scalars["Upload"];
	publicId: Scalars["ID"];
	tags?: Maybe<Array<Scalars["String"]>>;
	altText?: Maybe<Scalars["String"]>;
	requestAdvFace?: Maybe<Scalars["Boolean"]>;
}>;

export type UploadImageMutation = { __typename?: "Mutation" } & {
	uploadImage: { __typename?: "CloudinaryResource" } & UploadImageFragment;
};

export type DeleteImagesMutationVariables = Exact<{
	publicIds?: Maybe<Array<Scalars["String"]>>;
}>;

export type DeleteImagesMutation = { __typename?: "Mutation" } & Pick<
	Mutation,
	"deleteImages"
>;

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: "Query" } & {
	currentUser?: Maybe<{ __typename?: "AuthUser" } & AuthUserFragment>;
};

export type DoctorSearchQueryVariables = Exact<{
	query: Scalars["String"];
}>;

export type DoctorSearchQuery = { __typename?: "Query" } & {
	doctorSearch: Array<
		{ __typename?: "DoctorSearchResult" } & DoctorSearchFragment
	>;
};

export type DoctorSearchFragment = { __typename?: "DoctorSearchResult" } & Pick<
	DoctorSearchResult,
	"npi" | "firstName" | "middleName" | "lastName" | "designation"
>;

export type DoctorsQueryVariables = Exact<{
	portalId: Scalars["ID"];
	includeHeadShot?: Maybe<Scalars["Boolean"]>;
	includeBio?: Maybe<Scalars["Boolean"]>;
}>;

export type DoctorsQuery = { __typename?: "Query" } & {
	doctors: Array<
		{ __typename?: "Doctor" } & DoctorsQueryDoctorFragment &
			DoctorsQueryDoctorHeadShotFragment &
			DoctorsQueryDoctorBioFragment
	>;
};

export type DoctorsChairQueryVariables = Exact<{
	portalId: Scalars["ID"];
	taxonomyId: Scalars["ID"];
}>;

export type DoctorsChairQuery = { __typename?: "Query" } & {
	doctorsChair: { __typename?: "DoctorsChair" } & DoctorsChairFragment;
};

export type DoctorsChairOptionQueryVariables = Exact<{
	npi: Scalars["ID"];
	taxonomyId: Scalars["ID"];
}>;

export type DoctorsChairOptionQuery = { __typename?: "Query" } & {
	doctorsChairOption: {
		__typename?: "DoctorsChairOption";
	} & DoctorsChairOptionFragment;
};

export type GetAboutOptionsQueryVariables = Exact<{
	portalId: Scalars["String"];
}>;

export type GetAboutOptionsQuery = { __typename?: "Query" } & {
	getAboutOptions?: Maybe<
		{ __typename?: "AboutOptions" } & AboutOptionsFragment
	>;
};

export type GetFeaturedServicesQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type GetFeaturedServicesQuery = { __typename?: "Query" } & {
	getFeaturedServices?: Maybe<
		Array<{ __typename?: "FeaturedService" } & FeaturedServiceFragment>
	>;
};

export type GetImageByPublicIdQueryVariables = Exact<{
	publicId: Scalars["String"];
}>;

export type GetImageByPublicIdQuery = { __typename?: "Query" } & {
	getImageByPublicId?: Maybe<
		{ __typename?: "CloudinaryResource" } & GetImageByPublicIdFragment
	>;
};

export type GetImageByPublicIdFragment = {
	__typename?: "CloudinaryResource";
} & Pick<
	CloudinaryResource,
	| "publicId"
	| "version"
	| "format"
	| "width"
	| "height"
	| "type"
	| "createdAt"
	| "altText"
>;

export type GetImagesByTagQueryVariables = Exact<{
	tag: Scalars["String"];
}>;

export type GetImagesByTagQuery = { __typename?: "Query" } & {
	getImagesByTag?: Maybe<
		Array<{ __typename?: "CloudinaryResource" } & GetImagesByTagFragment>
	>;
};

export type GetImagesByTagFragment = {
	__typename?: "CloudinaryResource";
} & Pick<
	CloudinaryResource,
	| "publicId"
	| "version"
	| "format"
	| "width"
	| "height"
	| "type"
	| "createdAt"
	| "altText"
>;

export type GetServicesQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type GetServicesQuery = { __typename?: "Query" } & {
	getServices: Array<{ __typename?: "Service" } & ServiceWithChildrenFragment>;
};

export type HomeOptionsQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type HomeOptionsQuery = { __typename?: "Query" } & {
	homeOptions?: Maybe<{ __typename?: "HomeOptions" } & HomeOptionsFragment>;
};

export type OfficeQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type OfficeQuery = { __typename?: "Query" } & {
	office: { __typename?: "OfficeSearchResult" } & OfficeSearchFragment;
};

export type OfficeHoursMetadataQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type OfficeHoursMetadataQuery = { __typename?: "Query" } & {
	officeMetadata: {
		__typename?: "OfficeMetadata";
	} & OfficeHoursMetadataFragment;
};

export type OfficeSearchQueryVariables = Exact<{
	query: Scalars["String"];
}>;

export type OfficeSearchQuery = { __typename?: "Query" } & {
	officeSearch: Array<
		{ __typename?: "OfficeSearchResult" } & OfficeSearchFragment
	>;
};

export type PracticeMetadataQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type PracticeMetadataQuery = { __typename?: "Query" } & {
	officeMetadata: { __typename?: "OfficeMetadata" } & PracticeMetadataFragment;
};

export type SiteMetadataQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type SiteMetadataQuery = { __typename?: "Query" } & {
	officeMetadata: { __typename?: "OfficeMetadata" } & SiteMetadataFragment;
};

export type TeamMembersQueryVariables = Exact<{
	portalId: Scalars["ID"];
}>;

export type TeamMembersQuery = { __typename?: "Query" } & {
	teamMembers: Array<{ __typename?: "TeamMember" } & TeamMemberFragment>;
};

export const BaseAboutOptionsWidgetFragmentDoc = gql`
	fragment BaseAboutOptionsWidget on AboutOptionsWidget {
		enabled
		moveable
		contentItemId
		variationName
		isShared
		overriddenBy
		name
	}
`;
export const AboutWelcomeBannerWidgetFragmentDoc = gql`
	fragment AboutWelcomeBannerWidget on AboutWelcomeBannerWidget {
		enabled
		moveable
		contentItemId
		variationName
		isShared
		overriddenBy
	}
`;
export const AboutMissionStatementWidgetFragmentDoc = gql`
	fragment AboutMissionStatementWidget on AboutMissionStatementWidget {
		text
	}
`;
export const AboutInvisalignWidgetFragmentDoc = gql`
	fragment AboutInvisalignWidget on AboutInvisalignWidget {
		display
	}
`;
export const AboutEsteemWidgetFragmentDoc = gql`
	fragment AboutEsteemWidget on AboutEsteemWidget {
		imageId
		videoPath
	}
`;
export const AboutOptionsWidgetFragmentDoc = gql`
	fragment AboutOptionsWidget on AboutOptionsWidget {
		...BaseAboutOptionsWidget
		... on AboutWelcomeBannerWidget {
			...AboutWelcomeBannerWidget
		}
		... on AboutMissionStatementWidget {
			...AboutMissionStatementWidget
		}
		... on AboutInvisalignWidget {
			...AboutInvisalignWidget
		}
		... on AboutEsteemWidget {
			...AboutEsteemWidget
		}
	}
	${BaseAboutOptionsWidgetFragmentDoc}
	${AboutWelcomeBannerWidgetFragmentDoc}
	${AboutMissionStatementWidgetFragmentDoc}
	${AboutInvisalignWidgetFragmentDoc}
	${AboutEsteemWidgetFragmentDoc}
`;
export const AboutOptionsFragmentDoc = gql`
	fragment AboutOptions on AboutOptions {
		widgets {
			...AboutOptionsWidget
		}
	}
	${AboutOptionsWidgetFragmentDoc}
`;
export const AuthUserFragmentDoc = gql`
	fragment AuthUser on AuthUser {
		userId
		displayName
		tokenExpires
		roles
	}
`;
export const FacesFragmentDoc = gql`
	fragment Faces on FacialAttributes {
		smile
		gender
		age
		blur
		noise
		mouthOccluded
	}
`;
export const UploadImageFragmentDoc = gql`
	fragment UploadImage on CloudinaryResource {
		publicId
		version
		format
		width
		height
		type
		createdAt
		altText
		faces {
			...Faces
		}
	}
	${FacesFragmentDoc}
`;
export const DoctorsQueryDoctorFragmentDoc = gql`
	fragment DoctorsQueryDoctor on Doctor {
		npi
		firstName
		middleName
		lastName
		designation
		isDmp
	}
`;
export const DoctorsQueryDoctorHeadShotFragmentDoc = gql`
	fragment DoctorsQueryDoctorHeadShot on Doctor {
		headShot {
			publicId
			version
			format
			width
			height
			type
			createdAt
			altText
		}
	}
`;
export const DoctorsQueryDoctorBioFragmentDoc = gql`
	fragment DoctorsQueryDoctorBio on Doctor {
		bio {
			biography
			education
			professionalMemberships
			continuingEducation
			hobbiesAndInterests
			missionStatement
		}
	}
`;
export const DoctorsChairDoctorFragmentDoc = gql`
	fragment DoctorsChairDoctor on Doctor {
		npi
		firstName
		lastName
		designation
	}
`;
export const DoctorsChairServiceFragmentDoc = gql`
	fragment DoctorsChairService on DoctorsChairService {
		taxonomyId
		enabled
		text
		doctor {
			...DoctorsChairDoctor
		}
	}
	${DoctorsChairDoctorFragmentDoc}
`;
export const DoctorsChairFragmentDoc = gql`
	fragment DoctorsChair on DoctorsChair {
		services {
			...DoctorsChairService
		}
		doctors {
			...DoctorsChairDoctor
		}
	}
	${DoctorsChairServiceFragmentDoc}
	${DoctorsChairDoctorFragmentDoc}
`;
export const DoctorsChairOptionFragmentDoc = gql`
	fragment DoctorsChairOption on DoctorsChairOption {
		taxonomyId
		text
	}
`;
export const BaseHomeOptionsWidgetFragmentDoc = gql`
	fragment BaseHomeOptionsWidget on HomeOptionsWidget {
		name
		enabled
		moveable
		isPromo
	}
`;
export const HeroImageFragmentDoc = gql`
	fragment HeroImage on HeroImage {
		publicId
		altText
		text
		selectedDoctorNpi
	}
`;
export const PrelaunchHeroImageFragmentDoc = gql`
	fragment PrelaunchHeroImage on PrelaunchHeroImage {
		publicId
		altText
		text
		hiringUrl
		instagramPost
	}
`;
export const HeroImageOptionsFragmentDoc = gql`
	fragment HeroImageOptions on HeroImageOptions {
		type
		standard {
			...HeroImage
		}
		upload {
			...HeroImage
		}
		configured {
			...HeroImage
		}
		doctor {
			...HeroImage
		}
		prelaunch {
			...PrelaunchHeroImage
		}
	}
	${HeroImageFragmentDoc}
	${PrelaunchHeroImageFragmentDoc}
`;
export const WelcomeBannerWidgetFragmentDoc = gql`
	fragment WelcomeBannerWidget on WelcomeBannerWidget {
		heroImage {
			...HeroImageOptions
		}
	}
	${HeroImageOptionsFragmentDoc}
`;
export const MissionStatementWidgetFragmentDoc = gql`
	fragment MissionStatementWidget on MissionStatementWidget {
		text
	}
`;
export const GoogleWidgetFragmentDoc = gql`
	fragment GoogleWidget on GoogleWidget {
		option
	}
`;
export const SmileGalleryMemberFragmentDoc = gql`
	fragment SmileGalleryMember on SmileGalleryMember {
		guid
		patientName
		beforeImagePublicId
		afterImagePublicId
		approverId
		description
	}
`;
export const SmileGalleryWidgetFragmentDoc = gql`
	fragment SmileGalleryWidget on SmileGalleryWidget {
		members {
			...SmileGalleryMember
		}
	}
	${SmileGalleryMemberFragmentDoc}
`;
export const FeaturedServiceFragmentDoc = gql`
	fragment FeaturedService on FeaturedService {
		taxonomyId
		text
	}
`;
export const FeaturedServicesWidgetFragmentDoc = gql`
	fragment FeaturedServicesWidget on FeaturedServicesWidget {
		services {
			...FeaturedService
		}
	}
	${FeaturedServiceFragmentDoc}
`;
export const BrandBadgesFragmentDoc = gql`
	fragment BrandBadges on BrandBadge {
		type
		enabled
	}
`;
export const BrandBadgesWidgetFragmentDoc = gql`
	fragment BrandBadgesWidget on BrandBadgesWidget {
		brandBadges {
			...BrandBadges
		}
	}
	${BrandBadgesFragmentDoc}
`;
export const InvisalignWidgetFragmentDoc = gql`
	fragment InvisalignWidget on InvisalignWidget {
		display
	}
`;
export const EsteemWidgetFragmentDoc = gql`
	fragment EsteemWidget on EsteemWidget {
		videoPath
		imageId
	}
`;
export const HomeOptionsWidgetFragmentDoc = gql`
	fragment HomeOptionsWidget on HomeOptionsWidget {
		...BaseHomeOptionsWidget
		... on WelcomeBannerWidget {
			...WelcomeBannerWidget
		}
		... on MissionStatementWidget {
			...MissionStatementWidget
		}
		... on GoogleWidget {
			...GoogleWidget
		}
		... on SmileGalleryWidget {
			...SmileGalleryWidget
		}
		... on FeaturedServicesWidget {
			...FeaturedServicesWidget
		}
		... on BrandBadgesWidget {
			...BrandBadgesWidget
		}
		... on InvisalignWidget {
			...InvisalignWidget
		}
		... on EsteemWidget {
			...EsteemWidget
		}
	}
	${BaseHomeOptionsWidgetFragmentDoc}
	${WelcomeBannerWidgetFragmentDoc}
	${MissionStatementWidgetFragmentDoc}
	${GoogleWidgetFragmentDoc}
	${SmileGalleryWidgetFragmentDoc}
	${FeaturedServicesWidgetFragmentDoc}
	${BrandBadgesWidgetFragmentDoc}
	${InvisalignWidgetFragmentDoc}
	${EsteemWidgetFragmentDoc}
`;
export const HomeOptionsFragmentDoc = gql`
	fragment HomeOptions on HomeOptions {
		template
		accentColor
		buttonStyle
		aboutSection
		header
		footer
		widgets {
			...HomeOptionsWidget
		}
	}
	${HomeOptionsWidgetFragmentDoc}
`;
export const OfficeSearchFragmentDoc = gql`
	fragment OfficeSearch on OfficeSearchResult {
		portalId
		officeName
		contentUrl
		tld
		street1
		city
		province
		provinceAbbr
		postalCode
	}
`;
export const DayHoursFragmentDoc = gql`
	fragment DayHours on DayHours {
		open
		close
	}
`;
export const OfficeHoursFragmentDoc = gql`
	fragment OfficeHours on OfficeHours {
		monday {
			...DayHours
		}
		tuesday {
			...DayHours
		}
		wednesday {
			...DayHours
		}
		thursday {
			...DayHours
		}
		friday {
			...DayHours
		}
		saturday {
			...DayHours
		}
		sunday {
			...DayHours
		}
	}
	${DayHoursFragmentDoc}
`;
export const OfficeHoursMetadataFragmentDoc = gql`
	fragment OfficeHoursMetadata on OfficeMetadata {
		officeHours {
			...OfficeHours
		}
	}
	${OfficeHoursFragmentDoc}
`;
export const PracticeMetadataFragmentDoc = gql`
	fragment PracticeMetadata on OfficeMetadata {
		officeName
		epicorId
		carecreditId
		street1
		postalCode
		city
		provinceAbbr
		hdcid
		latitude
		longitude
		phone
		speeddial
		alertType
		alertMessage
		launchDate
		hasMedicaidStatement
		medicaidStatementType
	}
`;
export const ServiceWithoutChildrenFragmentDoc = gql`
	fragment ServiceWithoutChildren on Service {
		taxonomyId
		text
		enabled
		doctorChairId
		doctorName
		hasFeaturedServiceContent
	}
`;
export const ServiceWithChildrenFragmentDoc = gql`
	fragment ServiceWithChildren on Service {
		...ServiceWithoutChildren
		children {
			...ServiceWithoutChildren
		}
	}
	${ServiceWithoutChildrenFragmentDoc}
`;
export const SiteMetadataFragmentDoc = gql`
	fragment SiteMetadata on OfficeMetadata {
		facebookUrl
		twitterUrl
		instagramUrl
		youtubeUrl
		snapchatUrl
		linkedinUrl
	}
`;
export const TeamMemberHeadshotFragmentDoc = gql`
	fragment TeamMemberHeadshot on CloudinaryResource {
		publicId
		version
		format
		width
		height
		type
		createdAt
	}
`;
export const TeamMemberFragmentDoc = gql`
	fragment TeamMember on TeamMember {
		teamMemberId
		firstName
		middleName
		lastName
		designation
		biography
		headShot {
			...TeamMemberHeadshot
		}
	}
	${TeamMemberHeadshotFragmentDoc}
`;
export const DoctorSearchFragmentDoc = gql`
	fragment DoctorSearch on DoctorSearchResult {
		npi
		firstName
		middleName
		lastName
		designation
	}
`;
export const GetImageByPublicIdFragmentDoc = gql`
	fragment GetImageByPublicId on CloudinaryResource {
		publicId
		version
		format
		width
		height
		type
		createdAt
		altText
	}
`;
export const GetImagesByTagFragmentDoc = gql`
	fragment GetImagesByTag on CloudinaryResource {
		publicId
		version
		format
		width
		height
		type
		createdAt
		altText
	}
`;
export const AddSmileGalleryMemberDocument = gql`
	mutation AddSmileGalleryMember(
		$portalId: ID!
		$smileGalleryMember: SmileGalleryMemberInput!
	) {
		addSmileGalleryMember(
			portalId: $portalId
			smileGalleryMember: $smileGalleryMember
		) {
			...HomeOptions
		}
	}
	${HomeOptionsFragmentDoc}
`;
export type AddSmileGalleryMemberMutationFn = Apollo.MutationFunction<
	AddSmileGalleryMemberMutation,
	AddSmileGalleryMemberMutationVariables
>;

/**
 * __useAddSmileGalleryMemberMutation__
 *
 * To run a mutation, you first call `useAddSmileGalleryMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSmileGalleryMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSmileGalleryMemberMutation, { data, loading, error }] = useAddSmileGalleryMemberMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      smileGalleryMember: // value for 'smileGalleryMember'
 *   },
 * });
 */
export function useAddSmileGalleryMemberMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		AddSmileGalleryMemberMutation,
		AddSmileGalleryMemberMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		AddSmileGalleryMemberMutation,
		AddSmileGalleryMemberMutationVariables
	>(AddSmileGalleryMemberDocument, baseOptions);
}
export type AddSmileGalleryMemberMutationHookResult = ReturnType<
	typeof useAddSmileGalleryMemberMutation
>;
export type AddSmileGalleryMemberMutationResult = Apollo.MutationResult<AddSmileGalleryMemberMutation>;
export type AddSmileGalleryMemberMutationOptions = Apollo.BaseMutationOptions<
	AddSmileGalleryMemberMutation,
	AddSmileGalleryMemberMutationVariables
>;
export const AddTeamMemberDocument = gql`
	mutation AddTeamMember($portalId: ID!, $teamMember: TeamMemberInput!) {
		addTeamMember(portalId: $portalId, teamMember: $teamMember) {
			...TeamMember
		}
	}
	${TeamMemberFragmentDoc}
`;
export type AddTeamMemberMutationFn = Apollo.MutationFunction<
	AddTeamMemberMutation,
	AddTeamMemberMutationVariables
>;

/**
 * __useAddTeamMemberMutation__
 *
 * To run a mutation, you first call `useAddTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTeamMemberMutation, { data, loading, error }] = useAddTeamMemberMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      teamMember: // value for 'teamMember'
 *   },
 * });
 */
export function useAddTeamMemberMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		AddTeamMemberMutation,
		AddTeamMemberMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		AddTeamMemberMutation,
		AddTeamMemberMutationVariables
	>(AddTeamMemberDocument, baseOptions);
}
export type AddTeamMemberMutationHookResult = ReturnType<
	typeof useAddTeamMemberMutation
>;
export type AddTeamMemberMutationResult = Apollo.MutationResult<AddTeamMemberMutation>;
export type AddTeamMemberMutationOptions = Apollo.BaseMutationOptions<
	AddTeamMemberMutation,
	AddTeamMemberMutationVariables
>;
export const ArrangeDoctorsDocument = gql`
	mutation ArrangeDoctors($portalId: ID!, $order: [ID!]!) {
		arrangeDoctors(portalId: $portalId, order: $order)
	}
`;
export type ArrangeDoctorsMutationFn = Apollo.MutationFunction<
	ArrangeDoctorsMutation,
	ArrangeDoctorsMutationVariables
>;

/**
 * __useArrangeDoctorsMutation__
 *
 * To run a mutation, you first call `useArrangeDoctorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeDoctorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeDoctorsMutation, { data, loading, error }] = useArrangeDoctorsMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useArrangeDoctorsMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		ArrangeDoctorsMutation,
		ArrangeDoctorsMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		ArrangeDoctorsMutation,
		ArrangeDoctorsMutationVariables
	>(ArrangeDoctorsDocument, baseOptions);
}
export type ArrangeDoctorsMutationHookResult = ReturnType<
	typeof useArrangeDoctorsMutation
>;
export type ArrangeDoctorsMutationResult = Apollo.MutationResult<ArrangeDoctorsMutation>;
export type ArrangeDoctorsMutationOptions = Apollo.BaseMutationOptions<
	ArrangeDoctorsMutation,
	ArrangeDoctorsMutationVariables
>;
export const ArrangeTeamMembersDocument = gql`
	mutation arrangeTeamMembers($portalId: ID!, $order: [ID!]!) {
		arrangeTeamMembers(portalId: $portalId, order: $order)
	}
`;
export type ArrangeTeamMembersMutationFn = Apollo.MutationFunction<
	ArrangeTeamMembersMutation,
	ArrangeTeamMembersMutationVariables
>;

/**
 * __useArrangeTeamMembersMutation__
 *
 * To run a mutation, you first call `useArrangeTeamMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArrangeTeamMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [arrangeTeamMembersMutation, { data, loading, error }] = useArrangeTeamMembersMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useArrangeTeamMembersMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		ArrangeTeamMembersMutation,
		ArrangeTeamMembersMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		ArrangeTeamMembersMutation,
		ArrangeTeamMembersMutationVariables
	>(ArrangeTeamMembersDocument, baseOptions);
}
export type ArrangeTeamMembersMutationHookResult = ReturnType<
	typeof useArrangeTeamMembersMutation
>;
export type ArrangeTeamMembersMutationResult = Apollo.MutationResult<ArrangeTeamMembersMutation>;
export type ArrangeTeamMembersMutationOptions = Apollo.BaseMutationOptions<
	ArrangeTeamMembersMutation,
	ArrangeTeamMembersMutationVariables
>;
export const AssignDoctorDocument = gql`
	mutation AssignDoctor($portalId: ID!, $npi: ID!) {
		assignDoctor(portalId: $portalId, npi: $npi)
	}
`;
export type AssignDoctorMutationFn = Apollo.MutationFunction<
	AssignDoctorMutation,
	AssignDoctorMutationVariables
>;

/**
 * __useAssignDoctorMutation__
 *
 * To run a mutation, you first call `useAssignDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDoctorMutation, { data, loading, error }] = useAssignDoctorMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function useAssignDoctorMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		AssignDoctorMutation,
		AssignDoctorMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		AssignDoctorMutation,
		AssignDoctorMutationVariables
	>(AssignDoctorDocument, baseOptions);
}
export type AssignDoctorMutationHookResult = ReturnType<
	typeof useAssignDoctorMutation
>;
export type AssignDoctorMutationResult = Apollo.MutationResult<AssignDoctorMutation>;
export type AssignDoctorMutationOptions = Apollo.BaseMutationOptions<
	AssignDoctorMutation,
	AssignDoctorMutationVariables
>;
export const BuildOfficeDocument = gql`
	mutation BuildOffice($portalId: ID!) {
		buildOffice(portalId: $portalId)
	}
`;
export type BuildOfficeMutationFn = Apollo.MutationFunction<
	BuildOfficeMutation,
	BuildOfficeMutationVariables
>;

/**
 * __useBuildOfficeMutation__
 *
 * To run a mutation, you first call `useBuildOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBuildOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [buildOfficeMutation, { data, loading, error }] = useBuildOfficeMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useBuildOfficeMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		BuildOfficeMutation,
		BuildOfficeMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		BuildOfficeMutation,
		BuildOfficeMutationVariables
	>(BuildOfficeDocument, baseOptions);
}
export type BuildOfficeMutationHookResult = ReturnType<
	typeof useBuildOfficeMutation
>;
export type BuildOfficeMutationResult = Apollo.MutationResult<BuildOfficeMutation>;
export type BuildOfficeMutationOptions = Apollo.BaseMutationOptions<
	BuildOfficeMutation,
	BuildOfficeMutationVariables
>;
export const DeleteDoctorAssignmentDocument = gql`
	mutation DeleteDoctorAssignment($portalId: ID!, $npi: ID!) {
		deleteDoctorAssignment(portalId: $portalId, npi: $npi)
	}
`;
export type DeleteDoctorAssignmentMutationFn = Apollo.MutationFunction<
	DeleteDoctorAssignmentMutation,
	DeleteDoctorAssignmentMutationVariables
>;

/**
 * __useDeleteDoctorAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteDoctorAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoctorAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoctorAssignmentMutation, { data, loading, error }] = useDeleteDoctorAssignmentMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function useDeleteDoctorAssignmentMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DeleteDoctorAssignmentMutation,
		DeleteDoctorAssignmentMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		DeleteDoctorAssignmentMutation,
		DeleteDoctorAssignmentMutationVariables
	>(DeleteDoctorAssignmentDocument, baseOptions);
}
export type DeleteDoctorAssignmentMutationHookResult = ReturnType<
	typeof useDeleteDoctorAssignmentMutation
>;
export type DeleteDoctorAssignmentMutationResult = Apollo.MutationResult<DeleteDoctorAssignmentMutation>;
export type DeleteDoctorAssignmentMutationOptions = Apollo.BaseMutationOptions<
	DeleteDoctorAssignmentMutation,
	DeleteDoctorAssignmentMutationVariables
>;
export const DeleteSmileGalleryMemberDocument = gql`
	mutation DeleteSmileGalleryMember($portalId: ID!, $guid: ID!) {
		deleteSmileGalleryMember(portalId: $portalId, guid: $guid) {
			...HomeOptions
		}
	}
	${HomeOptionsFragmentDoc}
`;
export type DeleteSmileGalleryMemberMutationFn = Apollo.MutationFunction<
	DeleteSmileGalleryMemberMutation,
	DeleteSmileGalleryMemberMutationVariables
>;

/**
 * __useDeleteSmileGalleryMemberMutation__
 *
 * To run a mutation, you first call `useDeleteSmileGalleryMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSmileGalleryMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSmileGalleryMemberMutation, { data, loading, error }] = useDeleteSmileGalleryMemberMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      guid: // value for 'guid'
 *   },
 * });
 */
export function useDeleteSmileGalleryMemberMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DeleteSmileGalleryMemberMutation,
		DeleteSmileGalleryMemberMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		DeleteSmileGalleryMemberMutation,
		DeleteSmileGalleryMemberMutationVariables
	>(DeleteSmileGalleryMemberDocument, baseOptions);
}
export type DeleteSmileGalleryMemberMutationHookResult = ReturnType<
	typeof useDeleteSmileGalleryMemberMutation
>;
export type DeleteSmileGalleryMemberMutationResult = Apollo.MutationResult<DeleteSmileGalleryMemberMutation>;
export type DeleteSmileGalleryMemberMutationOptions = Apollo.BaseMutationOptions<
	DeleteSmileGalleryMemberMutation,
	DeleteSmileGalleryMemberMutationVariables
>;
export const DeleteTeamMemberDocument = gql`
	mutation DeleteTeamMember($portalId: ID!, $id: ID!) {
		deleteTeamMember(portalId: $portalId, id: $id)
	}
`;
export type DeleteTeamMemberMutationFn = Apollo.MutationFunction<
	DeleteTeamMemberMutation,
	DeleteTeamMemberMutationVariables
>;

/**
 * __useDeleteTeamMemberMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMemberMutation, { data, loading, error }] = useDeleteTeamMemberMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTeamMemberMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DeleteTeamMemberMutation,
		DeleteTeamMemberMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		DeleteTeamMemberMutation,
		DeleteTeamMemberMutationVariables
	>(DeleteTeamMemberDocument, baseOptions);
}
export type DeleteTeamMemberMutationHookResult = ReturnType<
	typeof useDeleteTeamMemberMutation
>;
export type DeleteTeamMemberMutationResult = Apollo.MutationResult<DeleteTeamMemberMutation>;
export type DeleteTeamMemberMutationOptions = Apollo.BaseMutationOptions<
	DeleteTeamMemberMutation,
	DeleteTeamMemberMutationVariables
>;
export const DeployOfficeDocument = gql`
	mutation DeployOffice($portalId: ID!) {
		deployOffice(portalId: $portalId)
	}
`;
export type DeployOfficeMutationFn = Apollo.MutationFunction<
	DeployOfficeMutation,
	DeployOfficeMutationVariables
>;

/**
 * __useDeployOfficeMutation__
 *
 * To run a mutation, you first call `useDeployOfficeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployOfficeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployOfficeMutation, { data, loading, error }] = useDeployOfficeMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useDeployOfficeMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DeployOfficeMutation,
		DeployOfficeMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		DeployOfficeMutation,
		DeployOfficeMutationVariables
	>(DeployOfficeDocument, baseOptions);
}
export type DeployOfficeMutationHookResult = ReturnType<
	typeof useDeployOfficeMutation
>;
export type DeployOfficeMutationResult = Apollo.MutationResult<DeployOfficeMutation>;
export type DeployOfficeMutationOptions = Apollo.BaseMutationOptions<
	DeployOfficeMutation,
	DeployOfficeMutationVariables
>;
export const EditDoctorDocument = gql`
	mutation EditDoctor($npi: ID!, $doctor: DoctorInput!) {
		editDoctor(npi: $npi, doctor: $doctor) {
			...DoctorsQueryDoctorHeadShot
			...DoctorsQueryDoctorBio
			...DoctorsQueryDoctor
		}
	}
	${DoctorsQueryDoctorHeadShotFragmentDoc}
	${DoctorsQueryDoctorBioFragmentDoc}
	${DoctorsQueryDoctorFragmentDoc}
`;
export type EditDoctorMutationFn = Apollo.MutationFunction<
	EditDoctorMutation,
	EditDoctorMutationVariables
>;

/**
 * __useEditDoctorMutation__
 *
 * To run a mutation, you first call `useEditDoctorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDoctorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDoctorMutation, { data, loading, error }] = useEditDoctorMutation({
 *   variables: {
 *      npi: // value for 'npi'
 *      doctor: // value for 'doctor'
 *   },
 * });
 */
export function useEditDoctorMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		EditDoctorMutation,
		EditDoctorMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		EditDoctorMutation,
		EditDoctorMutationVariables
	>(EditDoctorDocument, baseOptions);
}
export type EditDoctorMutationHookResult = ReturnType<
	typeof useEditDoctorMutation
>;
export type EditDoctorMutationResult = Apollo.MutationResult<EditDoctorMutation>;
export type EditDoctorMutationOptions = Apollo.BaseMutationOptions<
	EditDoctorMutation,
	EditDoctorMutationVariables
>;
export const EditDoctorsChairDocument = gql`
	mutation EditDoctorsChair(
		$portalId: ID!
		$taxonomyId: ID!
		$enabled: Boolean!
		$text: String!
		$npi: ID!
	) {
		editDoctorsChair(
			portalId: $portalId
			taxonomyId: $taxonomyId
			enabled: $enabled
			text: $text
			npi: $npi
		) {
			...DoctorsChairService
		}
	}
	${DoctorsChairServiceFragmentDoc}
`;
export type EditDoctorsChairMutationFn = Apollo.MutationFunction<
	EditDoctorsChairMutation,
	EditDoctorsChairMutationVariables
>;

/**
 * __useEditDoctorsChairMutation__
 *
 * To run a mutation, you first call `useEditDoctorsChairMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditDoctorsChairMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editDoctorsChairMutation, { data, loading, error }] = useEditDoctorsChairMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      taxonomyId: // value for 'taxonomyId'
 *      enabled: // value for 'enabled'
 *      text: // value for 'text'
 *      npi: // value for 'npi'
 *   },
 * });
 */
export function useEditDoctorsChairMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		EditDoctorsChairMutation,
		EditDoctorsChairMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		EditDoctorsChairMutation,
		EditDoctorsChairMutationVariables
	>(EditDoctorsChairDocument, baseOptions);
}
export type EditDoctorsChairMutationHookResult = ReturnType<
	typeof useEditDoctorsChairMutation
>;
export type EditDoctorsChairMutationResult = Apollo.MutationResult<EditDoctorsChairMutation>;
export type EditDoctorsChairMutationOptions = Apollo.BaseMutationOptions<
	EditDoctorsChairMutation,
	EditDoctorsChairMutationVariables
>;
export const EditOfficeMetadataDocument = gql`
	mutation EditOfficeMetadata(
		$portalId: ID!
		$officeMetadata: OfficeMetadataInput!
	) {
		editOfficeMetadata(portalId: $portalId, officeMetadata: $officeMetadata) {
			...OfficeHoursMetadata
			...PracticeMetadata
			...SiteMetadata
		}
	}
	${OfficeHoursMetadataFragmentDoc}
	${PracticeMetadataFragmentDoc}
	${SiteMetadataFragmentDoc}
`;
export type EditOfficeMetadataMutationFn = Apollo.MutationFunction<
	EditOfficeMetadataMutation,
	EditOfficeMetadataMutationVariables
>;

/**
 * __useEditOfficeMetadataMutation__
 *
 * To run a mutation, you first call `useEditOfficeMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOfficeMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOfficeMetadataMutation, { data, loading, error }] = useEditOfficeMetadataMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      officeMetadata: // value for 'officeMetadata'
 *   },
 * });
 */
export function useEditOfficeMetadataMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		EditOfficeMetadataMutation,
		EditOfficeMetadataMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		EditOfficeMetadataMutation,
		EditOfficeMetadataMutationVariables
	>(EditOfficeMetadataDocument, baseOptions);
}
export type EditOfficeMetadataMutationHookResult = ReturnType<
	typeof useEditOfficeMetadataMutation
>;
export type EditOfficeMetadataMutationResult = Apollo.MutationResult<EditOfficeMetadataMutation>;
export type EditOfficeMetadataMutationOptions = Apollo.BaseMutationOptions<
	EditOfficeMetadataMutation,
	EditOfficeMetadataMutationVariables
>;
export const EditTeamMemberDocument = gql`
	mutation EditTeamMember(
		$portalId: ID!
		$id: ID!
		$teamMember: TeamMemberInput!
	) {
		editTeamMember(portalId: $portalId, id: $id, teamMember: $teamMember) {
			...TeamMember
		}
	}
	${TeamMemberFragmentDoc}
`;
export type EditTeamMemberMutationFn = Apollo.MutationFunction<
	EditTeamMemberMutation,
	EditTeamMemberMutationVariables
>;

/**
 * __useEditTeamMemberMutation__
 *
 * To run a mutation, you first call `useEditTeamMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTeamMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTeamMemberMutation, { data, loading, error }] = useEditTeamMemberMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      id: // value for 'id'
 *      teamMember: // value for 'teamMember'
 *   },
 * });
 */
export function useEditTeamMemberMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		EditTeamMemberMutation,
		EditTeamMemberMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		EditTeamMemberMutation,
		EditTeamMemberMutationVariables
	>(EditTeamMemberDocument, baseOptions);
}
export type EditTeamMemberMutationHookResult = ReturnType<
	typeof useEditTeamMemberMutation
>;
export type EditTeamMemberMutationResult = Apollo.MutationResult<EditTeamMemberMutation>;
export type EditTeamMemberMutationOptions = Apollo.BaseMutationOptions<
	EditTeamMemberMutation,
	EditTeamMemberMutationVariables
>;
export const LogInDocument = gql`
	mutation LogIn($username: String!, $password: String!) {
		logIn(username: $username, password: $password) {
			...AuthUser
		}
	}
	${AuthUserFragmentDoc}
`;
export type LogInMutationFn = Apollo.MutationFunction<
	LogInMutation,
	LogInMutationVariables
>;

/**
 * __useLogInMutation__
 *
 * To run a mutation, you first call `useLogInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logInMutation, { data, loading, error }] = useLogInMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		LogInMutation,
		LogInMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<LogInMutation, LogInMutationVariables>(
		LogInDocument,
		baseOptions
	);
}
export type LogInMutationHookResult = ReturnType<typeof useLogInMutation>;
export type LogInMutationResult = Apollo.MutationResult<LogInMutation>;
export type LogInMutationOptions = Apollo.BaseMutationOptions<
	LogInMutation,
	LogInMutationVariables
>;
export const LogOutDocument = gql`
	mutation LogOut {
		logOut
	}
`;
export type LogOutMutationFn = Apollo.MutationFunction<
	LogOutMutation,
	LogOutMutationVariables
>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogOutMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		LogOutMutation,
		LogOutMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<LogOutMutation, LogOutMutationVariables>(
		LogOutDocument,
		baseOptions
	);
}
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<
	LogOutMutation,
	LogOutMutationVariables
>;
export const RenewSessionDocument = gql`
	mutation RenewSession {
		renewSession {
			...AuthUser
		}
	}
	${AuthUserFragmentDoc}
`;
export type RenewSessionMutationFn = Apollo.MutationFunction<
	RenewSessionMutation,
	RenewSessionMutationVariables
>;

/**
 * __useRenewSessionMutation__
 *
 * To run a mutation, you first call `useRenewSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenewSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renewSessionMutation, { data, loading, error }] = useRenewSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useRenewSessionMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		RenewSessionMutation,
		RenewSessionMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		RenewSessionMutation,
		RenewSessionMutationVariables
	>(RenewSessionDocument, baseOptions);
}
export type RenewSessionMutationHookResult = ReturnType<
	typeof useRenewSessionMutation
>;
export type RenewSessionMutationResult = Apollo.MutationResult<RenewSessionMutation>;
export type RenewSessionMutationOptions = Apollo.BaseMutationOptions<
	RenewSessionMutation,
	RenewSessionMutationVariables
>;
export const SendFeedbackDocument = gql`
	mutation SendFeedback(
		$comments: String!
		$appState: String!
		$browserInfo: String!
		$stackTrace: String
	) {
		sendFeedback(
			comments: $comments
			appState: $appState
			browserInfo: $browserInfo
			stackTrace: $stackTrace
		)
	}
`;
export type SendFeedbackMutationFn = Apollo.MutationFunction<
	SendFeedbackMutation,
	SendFeedbackMutationVariables
>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      comments: // value for 'comments'
 *      appState: // value for 'appState'
 *      browserInfo: // value for 'browserInfo'
 *      stackTrace: // value for 'stackTrace'
 *   },
 * });
 */
export function useSendFeedbackMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SendFeedbackMutation,
		SendFeedbackMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		SendFeedbackMutation,
		SendFeedbackMutationVariables
	>(SendFeedbackDocument, baseOptions);
}
export type SendFeedbackMutationHookResult = ReturnType<
	typeof useSendFeedbackMutation
>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<
	SendFeedbackMutation,
	SendFeedbackMutationVariables
>;
export const SendTelemetryMessageDocument = gql`
	mutation SendTelemetryMessage(
		$service: String!
		$message: String!
		$level: TelemetryLevels
	) {
		sendTelemetryMessage(service: $service, message: $message, level: $level)
	}
`;
export type SendTelemetryMessageMutationFn = Apollo.MutationFunction<
	SendTelemetryMessageMutation,
	SendTelemetryMessageMutationVariables
>;

/**
 * __useSendTelemetryMessageMutation__
 *
 * To run a mutation, you first call `useSendTelemetryMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTelemetryMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTelemetryMessageMutation, { data, loading, error }] = useSendTelemetryMessageMutation({
 *   variables: {
 *      service: // value for 'service'
 *      message: // value for 'message'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useSendTelemetryMessageMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SendTelemetryMessageMutation,
		SendTelemetryMessageMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		SendTelemetryMessageMutation,
		SendTelemetryMessageMutationVariables
	>(SendTelemetryMessageDocument, baseOptions);
}
export type SendTelemetryMessageMutationHookResult = ReturnType<
	typeof useSendTelemetryMessageMutation
>;
export type SendTelemetryMessageMutationResult = Apollo.MutationResult<SendTelemetryMessageMutation>;
export type SendTelemetryMessageMutationOptions = Apollo.BaseMutationOptions<
	SendTelemetryMessageMutation,
	SendTelemetryMessageMutationVariables
>;
export const SetAboutOptionsDocument = gql`
	mutation SetAboutOptions($portalId: ID!, $aboutOptions: AboutOptionsInput!) {
		setAboutOptions(portalId: $portalId, aboutOptions: $aboutOptions) {
			...AboutOptions
		}
	}
	${AboutOptionsFragmentDoc}
`;
export type SetAboutOptionsMutationFn = Apollo.MutationFunction<
	SetAboutOptionsMutation,
	SetAboutOptionsMutationVariables
>;

/**
 * __useSetAboutOptionsMutation__
 *
 * To run a mutation, you first call `useSetAboutOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAboutOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAboutOptionsMutation, { data, loading, error }] = useSetAboutOptionsMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      aboutOptions: // value for 'aboutOptions'
 *   },
 * });
 */
export function useSetAboutOptionsMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SetAboutOptionsMutation,
		SetAboutOptionsMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		SetAboutOptionsMutation,
		SetAboutOptionsMutationVariables
	>(SetAboutOptionsDocument, baseOptions);
}
export type SetAboutOptionsMutationHookResult = ReturnType<
	typeof useSetAboutOptionsMutation
>;
export type SetAboutOptionsMutationResult = Apollo.MutationResult<SetAboutOptionsMutation>;
export type SetAboutOptionsMutationOptions = Apollo.BaseMutationOptions<
	SetAboutOptionsMutation,
	SetAboutOptionsMutationVariables
>;
export const SetFeaturedServicesDocument = gql`
	mutation SetFeaturedServices(
		$portalId: ID!
		$featuredServices: FeaturedServicesInput!
	) {
		setFeaturedServices(
			portalId: $portalId
			featuredServices: $featuredServices
		) {
			...FeaturedService
		}
	}
	${FeaturedServiceFragmentDoc}
`;
export type SetFeaturedServicesMutationFn = Apollo.MutationFunction<
	SetFeaturedServicesMutation,
	SetFeaturedServicesMutationVariables
>;

/**
 * __useSetFeaturedServicesMutation__
 *
 * To run a mutation, you first call `useSetFeaturedServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetFeaturedServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeaturedServicesMutation, { data, loading, error }] = useSetFeaturedServicesMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      featuredServices: // value for 'featuredServices'
 *   },
 * });
 */
export function useSetFeaturedServicesMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SetFeaturedServicesMutation,
		SetFeaturedServicesMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		SetFeaturedServicesMutation,
		SetFeaturedServicesMutationVariables
	>(SetFeaturedServicesDocument, baseOptions);
}
export type SetFeaturedServicesMutationHookResult = ReturnType<
	typeof useSetFeaturedServicesMutation
>;
export type SetFeaturedServicesMutationResult = Apollo.MutationResult<SetFeaturedServicesMutation>;
export type SetFeaturedServicesMutationOptions = Apollo.BaseMutationOptions<
	SetFeaturedServicesMutation,
	SetFeaturedServicesMutationVariables
>;
export const SetHomeOptionsDocument = gql`
	mutation SetHomeOptions($portalId: ID!, $homeOptions: HomeOptionsInput!) {
		setHomeOptions(portalId: $portalId, homeOptions: $homeOptions) {
			...HomeOptions
		}
	}
	${HomeOptionsFragmentDoc}
`;
export type SetHomeOptionsMutationFn = Apollo.MutationFunction<
	SetHomeOptionsMutation,
	SetHomeOptionsMutationVariables
>;

/**
 * __useSetHomeOptionsMutation__
 *
 * To run a mutation, you first call `useSetHomeOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetHomeOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setHomeOptionsMutation, { data, loading, error }] = useSetHomeOptionsMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      homeOptions: // value for 'homeOptions'
 *   },
 * });
 */
export function useSetHomeOptionsMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SetHomeOptionsMutation,
		SetHomeOptionsMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		SetHomeOptionsMutation,
		SetHomeOptionsMutationVariables
	>(SetHomeOptionsDocument, baseOptions);
}
export type SetHomeOptionsMutationHookResult = ReturnType<
	typeof useSetHomeOptionsMutation
>;
export type SetHomeOptionsMutationResult = Apollo.MutationResult<SetHomeOptionsMutation>;
export type SetHomeOptionsMutationOptions = Apollo.BaseMutationOptions<
	SetHomeOptionsMutation,
	SetHomeOptionsMutationVariables
>;
export const SetServicesDocument = gql`
	mutation SetServices($portalId: ID!, $taxonomyIds: [ID!]!) {
		setServices(portalId: $portalId, taxonomyIds: $taxonomyIds) {
			...ServiceWithChildren
		}
	}
	${ServiceWithChildrenFragmentDoc}
`;
export type SetServicesMutationFn = Apollo.MutationFunction<
	SetServicesMutation,
	SetServicesMutationVariables
>;

/**
 * __useSetServicesMutation__
 *
 * To run a mutation, you first call `useSetServicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetServicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setServicesMutation, { data, loading, error }] = useSetServicesMutation({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      taxonomyIds: // value for 'taxonomyIds'
 *   },
 * });
 */
export function useSetServicesMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		SetServicesMutation,
		SetServicesMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		SetServicesMutation,
		SetServicesMutationVariables
	>(SetServicesDocument, baseOptions);
}
export type SetServicesMutationHookResult = ReturnType<
	typeof useSetServicesMutation
>;
export type SetServicesMutationResult = Apollo.MutationResult<SetServicesMutation>;
export type SetServicesMutationOptions = Apollo.BaseMutationOptions<
	SetServicesMutation,
	SetServicesMutationVariables
>;
export const UpdateAltTextDocument = gql`
	mutation UpdateAltText($publicId: ID!, $altText: String!) {
		updateAltText(publicId: $publicId, altText: $altText)
	}
`;
export type UpdateAltTextMutationFn = Apollo.MutationFunction<
	UpdateAltTextMutation,
	UpdateAltTextMutationVariables
>;

/**
 * __useUpdateAltTextMutation__
 *
 * To run a mutation, you first call `useUpdateAltTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAltTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAltTextMutation, { data, loading, error }] = useUpdateAltTextMutation({
 *   variables: {
 *      publicId: // value for 'publicId'
 *      altText: // value for 'altText'
 *   },
 * });
 */
export function useUpdateAltTextMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		UpdateAltTextMutation,
		UpdateAltTextMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		UpdateAltTextMutation,
		UpdateAltTextMutationVariables
	>(UpdateAltTextDocument, baseOptions);
}
export type UpdateAltTextMutationHookResult = ReturnType<
	typeof useUpdateAltTextMutation
>;
export type UpdateAltTextMutationResult = Apollo.MutationResult<UpdateAltTextMutation>;
export type UpdateAltTextMutationOptions = Apollo.BaseMutationOptions<
	UpdateAltTextMutation,
	UpdateAltTextMutationVariables
>;
export const UploadImageDocument = gql`
	mutation UploadImage(
		$file: Upload!
		$publicId: ID!
		$tags: [String!]
		$altText: String
		$requestAdvFace: Boolean
	) {
		uploadImage(
			file: $file
			publicId: $publicId
			tags: $tags
			altText: $altText
			requestAdvFace: $requestAdvFace
		) {
			...UploadImage
		}
	}
	${UploadImageFragmentDoc}
`;
export type UploadImageMutationFn = Apollo.MutationFunction<
	UploadImageMutation,
	UploadImageMutationVariables
>;

/**
 * __useUploadImageMutation__
 *
 * To run a mutation, you first call `useUploadImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageMutation, { data, loading, error }] = useUploadImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *      publicId: // value for 'publicId'
 *      tags: // value for 'tags'
 *      altText: // value for 'altText'
 *      requestAdvFace: // value for 'requestAdvFace'
 *   },
 * });
 */
export function useUploadImageMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		UploadImageMutation,
		UploadImageMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		UploadImageMutation,
		UploadImageMutationVariables
	>(UploadImageDocument, baseOptions);
}
export type UploadImageMutationHookResult = ReturnType<
	typeof useUploadImageMutation
>;
export type UploadImageMutationResult = Apollo.MutationResult<UploadImageMutation>;
export type UploadImageMutationOptions = Apollo.BaseMutationOptions<
	UploadImageMutation,
	UploadImageMutationVariables
>;
export const DeleteImagesDocument = gql`
	mutation DeleteImages($publicIds: [String!]) {
		deleteImages(publicIds: $publicIds)
	}
`;
export type DeleteImagesMutationFn = Apollo.MutationFunction<
	DeleteImagesMutation,
	DeleteImagesMutationVariables
>;

/**
 * __useDeleteImagesMutation__
 *
 * To run a mutation, you first call `useDeleteImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImagesMutation, { data, loading, error }] = useDeleteImagesMutation({
 *   variables: {
 *      publicIds: // value for 'publicIds'
 *   },
 * });
 */
export function useDeleteImagesMutation(
	baseOptions?: ApolloReactHooks.MutationHookOptions<
		DeleteImagesMutation,
		DeleteImagesMutationVariables
	>
) {
	return ApolloReactHooks.useMutation<
		DeleteImagesMutation,
		DeleteImagesMutationVariables
	>(DeleteImagesDocument, baseOptions);
}
export type DeleteImagesMutationHookResult = ReturnType<
	typeof useDeleteImagesMutation
>;
export type DeleteImagesMutationResult = Apollo.MutationResult<DeleteImagesMutation>;
export type DeleteImagesMutationOptions = Apollo.BaseMutationOptions<
	DeleteImagesMutation,
	DeleteImagesMutationVariables
>;
export const CurrentUserDocument = gql`
	query CurrentUser {
		currentUser {
			...AuthUser
		}
	}
	${AuthUserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		CurrentUserQuery,
		CurrentUserQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
		CurrentUserDocument,
		baseOptions
	);
}
export function useCurrentUserLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		CurrentUserQuery,
		CurrentUserQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		CurrentUserQuery,
		CurrentUserQueryVariables
	>(CurrentUserDocument, baseOptions);
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
	typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
	CurrentUserQuery,
	CurrentUserQueryVariables
>;
export const DoctorSearchDocument = gql`
	query DoctorSearch($query: String!) {
		doctorSearch(query: $query) {
			...DoctorSearch
		}
	}
	${DoctorSearchFragmentDoc}
`;

/**
 * __useDoctorSearchQuery__
 *
 * To run a query within a React component, call `useDoctorSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useDoctorSearchQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		DoctorSearchQuery,
		DoctorSearchQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		DoctorSearchQuery,
		DoctorSearchQueryVariables
	>(DoctorSearchDocument, baseOptions);
}
export function useDoctorSearchLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		DoctorSearchQuery,
		DoctorSearchQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		DoctorSearchQuery,
		DoctorSearchQueryVariables
	>(DoctorSearchDocument, baseOptions);
}
export type DoctorSearchQueryHookResult = ReturnType<
	typeof useDoctorSearchQuery
>;
export type DoctorSearchLazyQueryHookResult = ReturnType<
	typeof useDoctorSearchLazyQuery
>;
export type DoctorSearchQueryResult = Apollo.QueryResult<
	DoctorSearchQuery,
	DoctorSearchQueryVariables
>;
export const DoctorsDocument = gql`
	query Doctors(
		$portalId: ID!
		$includeHeadShot: Boolean = false
		$includeBio: Boolean = false
	) {
		doctors(portalId: $portalId) {
			...DoctorsQueryDoctor
			...DoctorsQueryDoctorHeadShot @include(if: $includeHeadShot)
			...DoctorsQueryDoctorBio @include(if: $includeBio)
		}
	}
	${DoctorsQueryDoctorFragmentDoc}
	${DoctorsQueryDoctorHeadShotFragmentDoc}
	${DoctorsQueryDoctorBioFragmentDoc}
`;

/**
 * __useDoctorsQuery__
 *
 * To run a query within a React component, call `useDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      includeHeadShot: // value for 'includeHeadShot'
 *      includeBio: // value for 'includeBio'
 *   },
 * });
 */
export function useDoctorsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		DoctorsQuery,
		DoctorsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<DoctorsQuery, DoctorsQueryVariables>(
		DoctorsDocument,
		baseOptions
	);
}
export function useDoctorsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		DoctorsQuery,
		DoctorsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<DoctorsQuery, DoctorsQueryVariables>(
		DoctorsDocument,
		baseOptions
	);
}
export type DoctorsQueryHookResult = ReturnType<typeof useDoctorsQuery>;
export type DoctorsLazyQueryHookResult = ReturnType<typeof useDoctorsLazyQuery>;
export type DoctorsQueryResult = Apollo.QueryResult<
	DoctorsQuery,
	DoctorsQueryVariables
>;
export const DoctorsChairDocument = gql`
	query DoctorsChair($portalId: ID!, $taxonomyId: ID!) {
		doctorsChair(portalId: $portalId, taxonomyId: $taxonomyId) {
			...DoctorsChair
		}
	}
	${DoctorsChairFragmentDoc}
`;

/**
 * __useDoctorsChairQuery__
 *
 * To run a query within a React component, call `useDoctorsChairQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsChairQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsChairQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *      taxonomyId: // value for 'taxonomyId'
 *   },
 * });
 */
export function useDoctorsChairQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		DoctorsChairQuery,
		DoctorsChairQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		DoctorsChairQuery,
		DoctorsChairQueryVariables
	>(DoctorsChairDocument, baseOptions);
}
export function useDoctorsChairLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		DoctorsChairQuery,
		DoctorsChairQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		DoctorsChairQuery,
		DoctorsChairQueryVariables
	>(DoctorsChairDocument, baseOptions);
}
export type DoctorsChairQueryHookResult = ReturnType<
	typeof useDoctorsChairQuery
>;
export type DoctorsChairLazyQueryHookResult = ReturnType<
	typeof useDoctorsChairLazyQuery
>;
export type DoctorsChairQueryResult = Apollo.QueryResult<
	DoctorsChairQuery,
	DoctorsChairQueryVariables
>;
export const DoctorsChairOptionDocument = gql`
	query DoctorsChairOption($npi: ID!, $taxonomyId: ID!) {
		doctorsChairOption(npi: $npi, taxonomyId: $taxonomyId) {
			...DoctorsChairOption
		}
	}
	${DoctorsChairOptionFragmentDoc}
`;

/**
 * __useDoctorsChairOptionQuery__
 *
 * To run a query within a React component, call `useDoctorsChairOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDoctorsChairOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDoctorsChairOptionQuery({
 *   variables: {
 *      npi: // value for 'npi'
 *      taxonomyId: // value for 'taxonomyId'
 *   },
 * });
 */
export function useDoctorsChairOptionQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		DoctorsChairOptionQuery,
		DoctorsChairOptionQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		DoctorsChairOptionQuery,
		DoctorsChairOptionQueryVariables
	>(DoctorsChairOptionDocument, baseOptions);
}
export function useDoctorsChairOptionLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		DoctorsChairOptionQuery,
		DoctorsChairOptionQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		DoctorsChairOptionQuery,
		DoctorsChairOptionQueryVariables
	>(DoctorsChairOptionDocument, baseOptions);
}
export type DoctorsChairOptionQueryHookResult = ReturnType<
	typeof useDoctorsChairOptionQuery
>;
export type DoctorsChairOptionLazyQueryHookResult = ReturnType<
	typeof useDoctorsChairOptionLazyQuery
>;
export type DoctorsChairOptionQueryResult = Apollo.QueryResult<
	DoctorsChairOptionQuery,
	DoctorsChairOptionQueryVariables
>;
export const GetAboutOptionsDocument = gql`
	query GetAboutOptions($portalId: String!) {
		getAboutOptions(portalId: $portalId) {
			...AboutOptions
		}
	}
	${AboutOptionsFragmentDoc}
`;

/**
 * __useGetAboutOptionsQuery__
 *
 * To run a query within a React component, call `useGetAboutOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAboutOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAboutOptionsQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useGetAboutOptionsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		GetAboutOptionsQuery,
		GetAboutOptionsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		GetAboutOptionsQuery,
		GetAboutOptionsQueryVariables
	>(GetAboutOptionsDocument, baseOptions);
}
export function useGetAboutOptionsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GetAboutOptionsQuery,
		GetAboutOptionsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		GetAboutOptionsQuery,
		GetAboutOptionsQueryVariables
	>(GetAboutOptionsDocument, baseOptions);
}
export type GetAboutOptionsQueryHookResult = ReturnType<
	typeof useGetAboutOptionsQuery
>;
export type GetAboutOptionsLazyQueryHookResult = ReturnType<
	typeof useGetAboutOptionsLazyQuery
>;
export type GetAboutOptionsQueryResult = Apollo.QueryResult<
	GetAboutOptionsQuery,
	GetAboutOptionsQueryVariables
>;
export const GetFeaturedServicesDocument = gql`
	query GetFeaturedServices($portalId: ID!) {
		getFeaturedServices(portalId: $portalId) {
			...FeaturedService
		}
	}
	${FeaturedServiceFragmentDoc}
`;

/**
 * __useGetFeaturedServicesQuery__
 *
 * To run a query within a React component, call `useGetFeaturedServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedServicesQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useGetFeaturedServicesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		GetFeaturedServicesQuery,
		GetFeaturedServicesQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		GetFeaturedServicesQuery,
		GetFeaturedServicesQueryVariables
	>(GetFeaturedServicesDocument, baseOptions);
}
export function useGetFeaturedServicesLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GetFeaturedServicesQuery,
		GetFeaturedServicesQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		GetFeaturedServicesQuery,
		GetFeaturedServicesQueryVariables
	>(GetFeaturedServicesDocument, baseOptions);
}
export type GetFeaturedServicesQueryHookResult = ReturnType<
	typeof useGetFeaturedServicesQuery
>;
export type GetFeaturedServicesLazyQueryHookResult = ReturnType<
	typeof useGetFeaturedServicesLazyQuery
>;
export type GetFeaturedServicesQueryResult = Apollo.QueryResult<
	GetFeaturedServicesQuery,
	GetFeaturedServicesQueryVariables
>;
export const GetImageByPublicIdDocument = gql`
	query GetImageByPublicId($publicId: String!) {
		getImageByPublicId(publicId: $publicId) {
			...GetImageByPublicId
		}
	}
	${GetImageByPublicIdFragmentDoc}
`;

/**
 * __useGetImageByPublicIdQuery__
 *
 * To run a query within a React component, call `useGetImageByPublicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImageByPublicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImageByPublicIdQuery({
 *   variables: {
 *      publicId: // value for 'publicId'
 *   },
 * });
 */
export function useGetImageByPublicIdQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		GetImageByPublicIdQuery,
		GetImageByPublicIdQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		GetImageByPublicIdQuery,
		GetImageByPublicIdQueryVariables
	>(GetImageByPublicIdDocument, baseOptions);
}
export function useGetImageByPublicIdLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GetImageByPublicIdQuery,
		GetImageByPublicIdQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		GetImageByPublicIdQuery,
		GetImageByPublicIdQueryVariables
	>(GetImageByPublicIdDocument, baseOptions);
}
export type GetImageByPublicIdQueryHookResult = ReturnType<
	typeof useGetImageByPublicIdQuery
>;
export type GetImageByPublicIdLazyQueryHookResult = ReturnType<
	typeof useGetImageByPublicIdLazyQuery
>;
export type GetImageByPublicIdQueryResult = Apollo.QueryResult<
	GetImageByPublicIdQuery,
	GetImageByPublicIdQueryVariables
>;
export const GetImagesByTagDocument = gql`
	query GetImagesByTag($tag: String!) {
		getImagesByTag(tag: $tag) {
			...GetImagesByTag
		}
	}
	${GetImagesByTagFragmentDoc}
`;

/**
 * __useGetImagesByTagQuery__
 *
 * To run a query within a React component, call `useGetImagesByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetImagesByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetImagesByTagQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGetImagesByTagQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		GetImagesByTagQuery,
		GetImagesByTagQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		GetImagesByTagQuery,
		GetImagesByTagQueryVariables
	>(GetImagesByTagDocument, baseOptions);
}
export function useGetImagesByTagLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GetImagesByTagQuery,
		GetImagesByTagQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		GetImagesByTagQuery,
		GetImagesByTagQueryVariables
	>(GetImagesByTagDocument, baseOptions);
}
export type GetImagesByTagQueryHookResult = ReturnType<
	typeof useGetImagesByTagQuery
>;
export type GetImagesByTagLazyQueryHookResult = ReturnType<
	typeof useGetImagesByTagLazyQuery
>;
export type GetImagesByTagQueryResult = Apollo.QueryResult<
	GetImagesByTagQuery,
	GetImagesByTagQueryVariables
>;
export const GetServicesDocument = gql`
	query GetServices($portalId: ID!) {
		getServices(portalId: $portalId) {
			...ServiceWithChildren
		}
	}
	${ServiceWithChildrenFragmentDoc}
`;

/**
 * __useGetServicesQuery__
 *
 * To run a query within a React component, call `useGetServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServicesQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useGetServicesQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		GetServicesQuery,
		GetServicesQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<GetServicesQuery, GetServicesQueryVariables>(
		GetServicesDocument,
		baseOptions
	);
}
export function useGetServicesLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		GetServicesQuery,
		GetServicesQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		GetServicesQuery,
		GetServicesQueryVariables
	>(GetServicesDocument, baseOptions);
}
export type GetServicesQueryHookResult = ReturnType<typeof useGetServicesQuery>;
export type GetServicesLazyQueryHookResult = ReturnType<
	typeof useGetServicesLazyQuery
>;
export type GetServicesQueryResult = Apollo.QueryResult<
	GetServicesQuery,
	GetServicesQueryVariables
>;
export const HomeOptionsDocument = gql`
	query HomeOptions($portalId: ID!) {
		homeOptions(portalId: $portalId) {
			...HomeOptions
		}
	}
	${HomeOptionsFragmentDoc}
`;

/**
 * __useHomeOptionsQuery__
 *
 * To run a query within a React component, call `useHomeOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeOptionsQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useHomeOptionsQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		HomeOptionsQuery,
		HomeOptionsQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<HomeOptionsQuery, HomeOptionsQueryVariables>(
		HomeOptionsDocument,
		baseOptions
	);
}
export function useHomeOptionsLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		HomeOptionsQuery,
		HomeOptionsQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		HomeOptionsQuery,
		HomeOptionsQueryVariables
	>(HomeOptionsDocument, baseOptions);
}
export type HomeOptionsQueryHookResult = ReturnType<typeof useHomeOptionsQuery>;
export type HomeOptionsLazyQueryHookResult = ReturnType<
	typeof useHomeOptionsLazyQuery
>;
export type HomeOptionsQueryResult = Apollo.QueryResult<
	HomeOptionsQuery,
	HomeOptionsQueryVariables
>;
export const OfficeDocument = gql`
	query Office($portalId: ID!) {
		office(portalId: $portalId) {
			...OfficeSearch
		}
	}
	${OfficeSearchFragmentDoc}
`;

/**
 * __useOfficeQuery__
 *
 * To run a query within a React component, call `useOfficeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useOfficeQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		OfficeQuery,
		OfficeQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<OfficeQuery, OfficeQueryVariables>(
		OfficeDocument,
		baseOptions
	);
}
export function useOfficeLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		OfficeQuery,
		OfficeQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<OfficeQuery, OfficeQueryVariables>(
		OfficeDocument,
		baseOptions
	);
}
export type OfficeQueryHookResult = ReturnType<typeof useOfficeQuery>;
export type OfficeLazyQueryHookResult = ReturnType<typeof useOfficeLazyQuery>;
export type OfficeQueryResult = Apollo.QueryResult<
	OfficeQuery,
	OfficeQueryVariables
>;
export const OfficeHoursMetadataDocument = gql`
	query OfficeHoursMetadata($portalId: ID!) {
		officeMetadata(portalId: $portalId) {
			...OfficeHoursMetadata
		}
	}
	${OfficeHoursMetadataFragmentDoc}
`;

/**
 * __useOfficeHoursMetadataQuery__
 *
 * To run a query within a React component, call `useOfficeHoursMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeHoursMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeHoursMetadataQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useOfficeHoursMetadataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		OfficeHoursMetadataQuery,
		OfficeHoursMetadataQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		OfficeHoursMetadataQuery,
		OfficeHoursMetadataQueryVariables
	>(OfficeHoursMetadataDocument, baseOptions);
}
export function useOfficeHoursMetadataLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		OfficeHoursMetadataQuery,
		OfficeHoursMetadataQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		OfficeHoursMetadataQuery,
		OfficeHoursMetadataQueryVariables
	>(OfficeHoursMetadataDocument, baseOptions);
}
export type OfficeHoursMetadataQueryHookResult = ReturnType<
	typeof useOfficeHoursMetadataQuery
>;
export type OfficeHoursMetadataLazyQueryHookResult = ReturnType<
	typeof useOfficeHoursMetadataLazyQuery
>;
export type OfficeHoursMetadataQueryResult = Apollo.QueryResult<
	OfficeHoursMetadataQuery,
	OfficeHoursMetadataQueryVariables
>;
export const OfficeSearchDocument = gql`
	query OfficeSearch($query: String!) {
		officeSearch(query: $query) {
			...OfficeSearch
		}
	}
	${OfficeSearchFragmentDoc}
`;

/**
 * __useOfficeSearchQuery__
 *
 * To run a query within a React component, call `useOfficeSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useOfficeSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOfficeSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOfficeSearchQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		OfficeSearchQuery,
		OfficeSearchQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		OfficeSearchQuery,
		OfficeSearchQueryVariables
	>(OfficeSearchDocument, baseOptions);
}
export function useOfficeSearchLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		OfficeSearchQuery,
		OfficeSearchQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		OfficeSearchQuery,
		OfficeSearchQueryVariables
	>(OfficeSearchDocument, baseOptions);
}
export type OfficeSearchQueryHookResult = ReturnType<
	typeof useOfficeSearchQuery
>;
export type OfficeSearchLazyQueryHookResult = ReturnType<
	typeof useOfficeSearchLazyQuery
>;
export type OfficeSearchQueryResult = Apollo.QueryResult<
	OfficeSearchQuery,
	OfficeSearchQueryVariables
>;
export const PracticeMetadataDocument = gql`
	query PracticeMetadata($portalId: ID!) {
		officeMetadata(portalId: $portalId) {
			...PracticeMetadata
		}
	}
	${PracticeMetadataFragmentDoc}
`;

/**
 * __usePracticeMetadataQuery__
 *
 * To run a query within a React component, call `usePracticeMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePracticeMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePracticeMetadataQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function usePracticeMetadataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		PracticeMetadataQuery,
		PracticeMetadataQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		PracticeMetadataQuery,
		PracticeMetadataQueryVariables
	>(PracticeMetadataDocument, baseOptions);
}
export function usePracticeMetadataLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		PracticeMetadataQuery,
		PracticeMetadataQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		PracticeMetadataQuery,
		PracticeMetadataQueryVariables
	>(PracticeMetadataDocument, baseOptions);
}
export type PracticeMetadataQueryHookResult = ReturnType<
	typeof usePracticeMetadataQuery
>;
export type PracticeMetadataLazyQueryHookResult = ReturnType<
	typeof usePracticeMetadataLazyQuery
>;
export type PracticeMetadataQueryResult = Apollo.QueryResult<
	PracticeMetadataQuery,
	PracticeMetadataQueryVariables
>;
export const SiteMetadataDocument = gql`
	query SiteMetadata($portalId: ID!) {
		officeMetadata(portalId: $portalId) {
			...SiteMetadata
		}
	}
	${SiteMetadataFragmentDoc}
`;

/**
 * __useSiteMetadataQuery__
 *
 * To run a query within a React component, call `useSiteMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteMetadataQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useSiteMetadataQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		SiteMetadataQuery,
		SiteMetadataQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<
		SiteMetadataQuery,
		SiteMetadataQueryVariables
	>(SiteMetadataDocument, baseOptions);
}
export function useSiteMetadataLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		SiteMetadataQuery,
		SiteMetadataQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		SiteMetadataQuery,
		SiteMetadataQueryVariables
	>(SiteMetadataDocument, baseOptions);
}
export type SiteMetadataQueryHookResult = ReturnType<
	typeof useSiteMetadataQuery
>;
export type SiteMetadataLazyQueryHookResult = ReturnType<
	typeof useSiteMetadataLazyQuery
>;
export type SiteMetadataQueryResult = Apollo.QueryResult<
	SiteMetadataQuery,
	SiteMetadataQueryVariables
>;
export const TeamMembersDocument = gql`
	query TeamMembers($portalId: ID!) {
		teamMembers(portalId: $portalId) {
			...TeamMember
		}
	}
	${TeamMemberFragmentDoc}
`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      portalId: // value for 'portalId'
 *   },
 * });
 */
export function useTeamMembersQuery(
	baseOptions?: ApolloReactHooks.QueryHookOptions<
		TeamMembersQuery,
		TeamMembersQueryVariables
	>
) {
	return ApolloReactHooks.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(
		TeamMembersDocument,
		baseOptions
	);
}
export function useTeamMembersLazyQuery(
	baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
		TeamMembersQuery,
		TeamMembersQueryVariables
	>
) {
	return ApolloReactHooks.useLazyQuery<
		TeamMembersQuery,
		TeamMembersQueryVariables
	>(TeamMembersDocument, baseOptions);
}
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<
	typeof useTeamMembersLazyQuery
>;
export type TeamMembersQueryResult = Apollo.QueryResult<
	TeamMembersQuery,
	TeamMembersQueryVariables
>;
