import React from "react";
import clsx from "clsx";

import { makeStyles } from "@mui/styles";
import { Typography, Theme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutline";
import WarningIcon from "@mui/icons-material/ReportProblemOutlined";

import { TooltipTypes, TooltipProps } from "./utils";
import { UseGlobalDarkModeContext } from "../GlobalDarkMode";

interface StyleProps {
	type: TooltipTypes;
	darkMode: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	tip: (props: StyleProps) => ({
		backgroundColor:
			props.type === TooltipTypes.Info
				? theme.color.blue.light
				: props.type === TooltipTypes.Warning
				? theme.color.yellow.light
				: theme.color.red.light,
		border: `1px solid ${
			props.type === TooltipTypes.Info
				? theme.color.blue.dark
				: props.type === TooltipTypes.Warning
				? theme.color.yellow.dark
				: theme.color.red.dark
		}`,
		borderRadius: 4,
		color:
			props.type === TooltipTypes.Info
				? theme.color.blue.dark
				: props.type === TooltipTypes.Warning
				? props.darkMode
					? theme.color.grey.light
					: theme.color.grey.dark
				: theme.color.red.dark,
		minHeight: 48,
		padding: "12px 8px 0px 8px",
		textAlign: "right",
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			marginTop: 12,
		},
	}),
	tip_box: {
		display: "flex",
	},
	tip_icon: (props: StyleProps) => ({
		fill:
			props.type === TooltipTypes.Info
				? theme.color.blue.dark
				: props.darkMode
				? theme.color.grey.light
				: theme.color.grey.dark,
	}),
	tip_content: {
		display: "inline-block",
		margin: "0px 0px 16px 12px !important",
		textAlign: "left",
		verticalAlign: "top",
	},
	tip_more_container: (props: StyleProps) => ({
		display: "block",
		fontSize: "0.75rem",
		padding: "0px 8px 4px 0px",
		color:
			props.type === TooltipTypes.Info
				? theme.color.blue.dark
				: theme.color.grey.dark,
		cursor: "pointer",
		textDecoration: "underline",
	}),
}));

export const Tooltip: React.FC<TooltipProps> = ({
	type,
	text,
	actionText,
	action,
	className,
}) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ type, darkMode: isDarkMode });

	return (
		<div className={clsx(classes.tip, className)}>
			<div className={classes.tip_box}>
				{type === TooltipTypes.Info ? (
					<InfoOutlinedIcon className={classes.tip_icon} />
				) : type === TooltipTypes.Warning ? (
					<WarningIcon className={classes.tip_icon} />
				) : type === TooltipTypes.Critical ? (
					<ErrorOutlinedIcon className={classes.error_icon} />
				) : null}

				<Typography className={classes.tip_content} component="div">
					{text}
				</Typography>
			</div>
			{actionText && (
				<div className={classes.tip_more_container} onClick={action}>
					{actionText}
				</div>
			)}
		</div>
	);
};
