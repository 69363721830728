import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme, Grid } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Tooltip, TooltipTypes } from "../Tooltip";
import AnimateHeight from "../AnimateHeight";

const useStyles = makeStyles((theme: Theme) => ({
	tooltip_header_container: {
		display: "flex",
		alignItems: "flex-start",
	},
	toggle_link: {
		background: "none",
		border: "none",
		padding: 0,
		textDecoration: "underline",
		cursor: "pointer",
		fontFamily: "inherit",
		fontSize: "inherit",
		fontStyle: "inherit",
		fontWeight: "inherit",
		color: "inherit",
		"&:focus": {
			outline: "none",
		},
	},
	toggle_button: {
		background: "none",
		border: "none",
		padding: 0,
		cursor: "pointer",
		fontFamily: "inherit",
		fontSize: "inherit",
		fontStyle: "inherit",
		fontWeight: "inherit",
		color: "inherit",
		"&:focus": {
			outline: "none",
		},
	},
}));

const ImageGuidelines: React.FC<{}> = () => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState(false);

	const toggleExpanded = React.useCallback(() => {
		setExpanded(!expanded);
	}, [expanded, setExpanded]);

	return (
		<Grid item container justifyContent="space-between" spacing={3}>
			<Grid item xs={12}>
				<Tooltip
					type={TooltipTypes.Info}
					text={
						<>
							<div className={classes.tooltip_header_container}>
								<span>
									Please make sure that both the Before and After photo meet the{" "}
									<button
										className={classes.toggle_link}
										onClick={toggleExpanded}
										aria-label={`${
											expanded ? "collapse" : "expand"
										} image guidelines`}
									>
										established guidelines
									</button>
									. Patients are skeptical and will not trust us if the images
									do not adhere to these guidelines.
								</span>
								<button
									className={classes.toggle_button}
									onClick={toggleExpanded}
									aria-label={`${
										expanded ? "collapse" : "expand"
									} image guidelines`}
								>
									{expanded ? <ExpandLess /> : <ExpandMore />}
								</button>
							</div>

							<AnimateHeight height={expanded ? "auto" : 0} animateOpacity>
								<>
									<h4>
										The Smile Gallery shows off your office’s amazing work. To
										add an entry please follow these rules:
									</h4>
									<ul>
										<li>Each photo must be of the same person.</li>
										<li>
											Each photo must be front-on, of the face, and with minimal
											head tilt.
										</li>
										<li>
											Each photo must be smiling, with teeth clearly visible.
										</li>
										<li>
											Each photo must NOT contain personally-identifiable
											information (e.g. a name tag).
										</li>
									</ul>
									<h4>
										To get the most out of the Smile Gallery we recommend:
									</h4>
									<ul>
										<li>
											Use the same backdrop for both the “before” and “after”
											photo.
										</li>
										<li>
											The backdrop should be as plain as possible, such as a
											wall without decoration.
										</li>
										<li>
											“Before” photos shouldn’t be overly explicit. Your
											visitors prefer not to see anything out of a horror movie.
										</li>
										<li>
											“After” photos should be objectively more appealing than
											the “before” photo. Would this comparison make you want to
											visit this office?
										</li>
									</ul>
								</>
							</AnimateHeight>
						</>
					}
				/>
			</Grid>
		</Grid>
	);
};

export default ImageGuidelines;
