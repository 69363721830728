import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import routes from "../../routes/Routes";

export const LoginRedirect: React.FC<{}> = () => {
	const location = useLocation();
	const redirect =
		location.pathname === routes.HOME_PAGE.path ? undefined : location.pathname;
	const url = redirect
		? `${routes.LOGIN.path}?redirect=${encodeURIComponent(redirect)}`
		: routes.LOGIN.path;
	return <Redirect to={url} />;
};
