import { CircularProgress, Grid } from "@mui/material";
import { FormikProps } from "formik";
import React, { useEffect } from "react";
import {
	useGetImagesByTagLazyQuery,
	HomeOptionsInput,
	HeroImageTypes,
} from "../../../types/graphql-types";
import { ImageGrid } from "./ImageGrid";
import HeroImageSidebar from "./HeroImageSidebar";

interface UploadedHeroGalleryProps {
	portalId: string;
	formikBag: FormikProps<HomeOptionsInput>;
	welcomeBannerWidgetIndex: number;
	altTextError: boolean;
	setAltTextError: React.Dispatch<React.SetStateAction<boolean>>;
	heroImageOption: "doctor" | "upload";
}
const UploadedHeroGallery: React.FC<UploadedHeroGalleryProps> = ({
	portalId,
	formikBag,
	welcomeBannerWidgetIndex,
	altTextError,
	setAltTextError,
	heroImageOption,
}) => {
	const [getImagesByTag, { data, loading, error }] = useGetImagesByTagLazyQuery(
		{
			ssr: false,
		}
	);
	const heroImageType =
		(formikBag.values.template === "everglades" ||
			formikBag.values.template === "boston") &&
		heroImageOption === "doctor"
			? HeroImageTypes.Doctor
			: HeroImageTypes.Upload;
	const tag =
		heroImageType === HeroImageTypes.Doctor
			? formikBag.values.template === "everglades"
				? "everglades"
				: "boston"
			: "hero";

	useEffect(() => {
		getImagesByTag({
			variables: {
				tag: `${portalId}_${tag}`,
			},
		});
	}, [getImagesByTag, portalId, tag]);

	if (loading) return <CircularProgress size={50} />;

	return (
		<Grid container>
			<HeroImageSidebar
				formikBag={formikBag}
				portalId={portalId}
				tag={tag}
				heroImageType={heroImageType}
				welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
				altTextError={altTextError}
				setAltTextError={setAltTextError}
				showAltTextField={true}
				heroImageOption={heroImageOption}
			/>
			{!(heroImageOption === "doctor") &&
				(error || !Array.isArray(data?.getImagesByTag)) && (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<span>No uploaded hero images found.</span>
					</div>
				)}
			{/* IMAGES */}
			{((Array.isArray(data?.getImagesByTag) && !error) ||
				heroImageOption === "doctor") && (
				<ImageGrid
					portalId={portalId}
					formikBag={formikBag}
					tag={tag}
					heroImageType={heroImageType}
					welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
					setAltTextError={setAltTextError}
					heroImageOption={heroImageOption}
				/>
			)}
		</Grid>
	);
};
export default UploadedHeroGallery;
