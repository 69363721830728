import React, { useCallback, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { Button, Grid, Theme } from "@mui/material";
import { Clear } from "@mui/icons-material";
import {
	CloudinaryResource,
	GetImagesByTagDocument,
	GetImagesByTagQuery,
	GetImagesByTagQueryVariables,
	Maybe,
	useDeleteImagesMutation,
} from "../../types/graphql-types";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import cloudinaryUtil from "../../utils/cloudinaryUtil";

const IMAGE_THUMBNAIL_WIDTH = 180;

const useStyles = makeStyles((theme: Theme) => ({
	imageContainer: {
		position: "relative",
		textAlign: "center",
		marginBottom: "16px !important",
		display: "flex",
		alignItems: "center",
	},
	selected: {
		border: `2px solid ${theme.palette.primary.light}`,
		borderRadius: 5,
	},
	image: {
		width: "100%",
		height: (IMAGE_THUMBNAIL_WIDTH * 9) / 16,
		objectFit: "cover",
		marginLeft: "auto",
		marginRight: "auto",
	},
	deleteIcon: {
		position: "absolute",
		top: 0,
		right: 0,
		transform: "translate(50%, -50%)",
		background: theme.color.red.main,
		borderRadius: "100%",
		height: theme.spacing(3.5),
		width: theme.spacing(3.5),
		color: "white",
		fontSize: theme.typography.pxToRem(10),
		"&:hover": {
			background: theme.color.red.dark,
		},
	},
	confirmDelete: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	deleteButton: {},
	altText: {
		marginLeft: "auto",
		marginRight: "auto",
		fontSize: theme.typography.pxToRem(12),
		lineHeight: theme.typography.pxToRem(14),
		width: IMAGE_THUMBNAIL_WIDTH,
		height: 28,
		// TODO: Test this ellipsis stuff in all browsers. Worst case, it should fallback to no ellipsis but hiding the overflow
		overflow: "hidden",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
		lineClamp: 2,
		textOverflow: "-o-ellipsis-lastline",
	},
	empty: {
		color: theme.palette.text.disabled,
	},
}));

interface ImageGridProps {
	images: CloudinaryResource[];
	selectedImage: string;
	onSelect: (publicId: string, altText?: Maybe<string>) => void;
	allowDelete?: boolean;
	portalId: string;
}

export const ImageGrid: React.FC<ImageGridProps> = ({
	images,
	selectedImage,
	onSelect,
	allowDelete,
	portalId,
}) => {
	const classes = useStyles();
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
	const templateTag = `${portalId}_hero`;

	const [
		deleteImagesMutation,
		deleteImagesResponse,
	] = useDeleteImagesMutation();

	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();

	const handleDeleteImage = useCallback(async () => {
		if (selectedImage) {
			await deleteImagesMutation({
				variables: {
					publicIds: [selectedImage],
				},
				update: (proxy, { data }) => {
					// Read the data from our cache for this query.
					const readQueryData = proxy.readQuery<
						GetImagesByTagQuery,
						GetImagesByTagQueryVariables
					>({
						query: GetImagesByTagDocument,
						variables: {
							tag: templateTag,
						},
					});
					// Write our data back to the cache with the new image in it
					if (readQueryData?.getImagesByTag && data) {
						proxy.writeQuery<GetImagesByTagQuery, GetImagesByTagQueryVariables>(
							{
								query: GetImagesByTagDocument,
								variables: {
									tag: templateTag,
								},
								data: {
									__typename: "Query",
									getImagesByTag: readQueryData.getImagesByTag.filter(
										(image) => image.publicId !== selectedImage
									),
								},
							}
						);
					}
				},
			});
		}
	}, [selectedImage, deleteImagesMutation, templateTag]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: deleteImagesResponse.loading,
		});
	}, [deleteImagesResponse.loading, setLoadingIndicatorProps]);

	return (
		<Grid container justifyContent="space-around">
			{images.map((image) => (
				<Grid
					item
					xs={12}
					sm={6}
					md={3}
					key={image.publicId}
					className={clsx(
						classes.imageContainer,
						image.publicId === selectedImage && classes.selected
					)}
					sx={{
						flexDirection: "column",
						flexBasis: `${IMAGE_THUMBNAIL_WIDTH}px !important`,
					}}
					onClick={() => {
						onSelect(image.publicId, image.altText || "");
						setShowDeleteConfirm(false);
					}}
				>
					<img
						className={classes.image}
						src={cloudinaryUtil.imageSrc({
							version: image.version,
							publicId: image.publicId,
							transforms: `ar_16:9,c_fill,g_auto/c_scale,w_${IMAGE_THUMBNAIL_WIDTH}`,
						})}
						alt={image.altText || ""}
					/>
					{/* TODO: Style all the delete stuff like in the welcome banner image grid */}
					{allowDelete && image.publicId === selectedImage && (
						<>
							{!showDeleteConfirm ? (
								<Clear
									className={classes.deleteIcon}
									onClick={(e) => {
										e.stopPropagation();
										setShowDeleteConfirm(true);
									}}
								/>
							) : (
								<div className={classes.confirmDelete}>
									<Button
										sx={{ textTransform: "none" }}
										color="primary"
										variant="contained"
										onClick={() => setShowDeleteConfirm(false)}
									>
										Cancel
									</Button>
									<Button
										sx={{ textTransform: "none" }}
										className={classes.deleteButton}
										color="secondary"
										variant="contained"
										onClick={handleDeleteImage}
									>
										Delete
									</Button>
								</div>
							)}
						</>
					)}
					<div
						className={clsx(classes.altText, !image.altText && classes.empty)}
					>
						{image.publicId === selectedImage
							? image.altText
								? image.altText
								: "No alt text!"
							: null}
					</div>
				</Grid>
			))}
			{/* Creates empty elements at the end so the last row always gets spaced as if it has 4 elements */}
			{[...new Array(Math.abs((images.length % 4) - 4))].map((_, i) => (
				<Grid
					className={classes.imageContainer}
					style={{ marginBottom: 0 }}
					key={i}
					item
					xs={12}
					sm={6}
					md={3}
				/>
			))}
		</Grid>
	);
};
