import React from "react";
import { makeStyles } from "@mui/styles";
import { Button, Theme, Typography } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { CloudinaryResource } from "../../types/graphql-types";
import DEFAULT_IMAGE from "../../assets/images/placeholder-image.png";
import { useGlobalSnackbar } from "../GlobalSnackbar";
import cloudinaryUtil from "../../utils/cloudinaryUtil";

type StyleProps = {
	width: number;
	height: number;
	imageSrc: string;
	error?: boolean;
};

export enum AspectRatios {
	LandscapeStandard = 4 / 3,
	PortraitStandard = 3 / 4,
	LandscapeWidescreen = 16 / 9,
	PortraitWidescreen = 9 / 16,
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
	input: {
		display: "none",
	},
	image_upload_container: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	dropzone: (props: StyleProps) => ({
		position: "relative",
		cursor: "pointer",
		height: props.height,
		width: props.width,
		border: props.error
			? `2px solid ${theme.palette.error.dark}`
			: `2px dashed ${theme.color.grey.dark}`,
		[theme.breakpoints.down("md")]: {
			margin: "0 auto",
		},
	}),
	image_preview: (props: StyleProps) => ({
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundImage: `url(${props.imageSrc})`,
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	}),
	upload_caption: {
		alignItems: "center",
		background: theme.color.transparentBlack.main,
		color: theme.color.white.main,
		display: "flex",
		fontWeight: "bold",
		position: "absolute",
		bottom: 0,
		left: 0,
		right: 0,
	},
	photo_icon: {
		padding: theme.spacing(2),
	},
	icon: {
		fill: theme.color.white.main,
	},
	caption: {
		flex: 1,
		marginRight: theme.spacing(2),
		textAlign: "center",
	},
	error_caption: (props: StyleProps) => ({
		color: theme.palette.error.dark,
		marginTop: "0.5rem",
		fontSize: "0.875rem",
		maxWidth: props.width,
	}),
}));

type ImageUploadProps = {
	id: string;
	width?: number;
	aspectRatio?: number;
	publicId?: string;
	image?: CloudinaryResource | null;
	onChange?: (newImageFile: File) => void;
	defaultImage?: string;
	isButton?: boolean;
	altText?: string | null;
	disabled?: boolean;
	error?: boolean;
};

export const ImageUpload: React.FC<ImageUploadProps> = ({
	id,
	width = 240,
	aspectRatio = AspectRatios.LandscapeWidescreen,
	publicId,
	image,
	onChange,
	defaultImage = DEFAULT_IMAGE,
	isButton = false,
	altText,
	disabled = false,
	error = false,
}) => {
	const { setSnackbarProps } = useGlobalSnackbar();

	const uploadButton = React.useRef<HTMLInputElement>();

	const [uploadPreview, setUploadPreview] = React.useState<string>();

	const imageSrc = React.useMemo(() => {
		if (uploadPreview) {
			return uploadPreview;
		} else if (publicId) {
			return cloudinaryUtil.imageSrc({
				version: image?.version,
				publicId,
			});
		} else {
			return defaultImage;
		}
	}, [uploadPreview, publicId, image, defaultImage]);

	const styleProps = React.useMemo(
		() => ({
			width,
			height: width / aspectRatio,
			imageSrc,
			error,
		}),
		[width, aspectRatio, imageSrc, error]
	);
	const classes = useStyles(styleProps);

	function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
		const { files } = e.target;
		if (files && files[0]) {
			// DON'T ALLOW FILE SIZES GREATER THAN 20MB
			if (files[0].size > 30000000) {
				setSnackbarProps({
					autoHideDuration: 5000,
					open: true,
					success: false,
					message: "Image must be smaller than 30MB!",
				});
			} else {
				if (onChange) {
					onChange(files[0]);
				}
				const reader = new FileReader();
				reader.onload = function (e: ProgressEvent<FileReader>) {
					const src = e?.target?.result?.toString();
					if (src) {
						const img = new Image();
						img.onload = function () {
							setUploadPreview(src);
						};
						img.src = src;
					}
				};
				reader.readAsDataURL(files[0]);
			}
		}
	}

	return (
		<React.Fragment>
			<input
				id={`image_input_${id}`}
				type="file"
				name="image"
				accept="image/*"
				className={classes.input}
				ref={(el) => el && (uploadButton.current = el)}
				onChange={handleImageChange}
			/>
			{isButton ? (
				<Button
					sx={{
						textTransform: "none",
						padding: "2px 24px",
						color: "white",
						backgroundColor: "#25B84A",
						"&:hover": {
							backgroundColor: "#94D264",
						},
					}}
					disabled={disabled}
					onClick={() => uploadButton.current?.click()}
				>
					upload
				</Button>
			) : (
				<div className={classes.image_upload_container}>
					<div
						id={`dropzone_${id}`}
						className={classes.dropzone}
						onClick={() => uploadButton.current?.click()}
						onDragOver={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
						onDrop={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (uploadButton.current) {
								const event = document.createEvent("UIEvents");
								event.initEvent("change", true, true);
								uploadButton.current.files = e.dataTransfer.files;
								uploadButton.current.dispatchEvent(event);
							}
						}}
					>
						<div
							className={classes.image_preview}
							role="img"
							aria-label={altText ?? "image upload preview"}
						/>
						<div className={classes.upload_caption}>
							<div className={classes.photo_icon}>
								<AddAPhotoIcon className={classes.icon} />
							</div>
							<div className={classes.caption}>Upload Photo</div>
						</div>
					</div>
					{error && (
						<Typography variant="caption" className={classes.error_caption}>
							The selected image is too small. Doctor headshots must be at least
							960px by 1280px.
						</Typography>
					)}
				</div>
			)}
		</React.Fragment>
	);
};
