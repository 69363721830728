import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import { Field } from "formik";
import * as Yup from "yup";
import React from "react";
import { TextFieldAdapter } from "../../TextField";
import { ModalProps } from "../utils";

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		marginBottom: "32px !important",
	},
	adornment: {
		marginRight: 2,
	},
	https: {
		color: `${theme.color.grey.main} !important`,
		marginLeft: 8,
	},
}));

const parseUrlForField = (url?: string | null) => {
	if (url && url !== "") {
		let revisedUrl = url;

		revisedUrl = revisedUrl.replace("https://", "");
		revisedUrl = revisedUrl.replace("http://", "");

		return revisedUrl;
	}

	return "";
};

const addHttpsToUrl = (url?: string | null) => {
	if (url && url !== "") {
		return `https://${url}`;
	}
	return null;
};

const validateUrl = (url?: string | null): string | undefined => {
	try {
		// Parse out any potential https from the user, then add the standard https
		const parsedUrl = addHttpsToUrl(parseUrlForField(url));
		if (parsedUrl) {
			Yup.string().url().validateSync(parsedUrl);
		}
	} catch (error) {
		return "Invalid URL";
	}
	return undefined;
};

export const EsteemModal: React.FC<ModalProps> = ({ widget, index }) => {
	const classes = useStyles();
	return (
		<div>
			<Typography variant="h5" className={classes.header}>
				Esteem Video URL
			</Typography>
			<Field
				name={`widgets.${index}.videoPath`}
				component={TextFieldAdapter}
				fullWidth={true}
				validate={validateUrl}
			/>
		</div>
	);
};
