import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { uniq } from "lodash";
import * as yup from "yup";
import { customBlockRenderMap } from "./renderUtils";

/**
 * Serialize the editor state to HTML.
 */
export const serialize = (editorState: EditorState) => {
	if (editorState.getCurrentContent().hasText())
		return stateToHTML(editorState.getCurrentContent(), {});
	else return "";
};

/**
 * Parse an HTML value into an initial editor state
 */
export const deserialize = (html: string | null | undefined) => {
	const blocksFromHTML = convertFromHTML(
		html || "",
		undefined,
		customBlockRenderMap
	);
	const contentState = ContentState.createFromBlockArray(
		blocksFromHTML.contentBlocks,
		blocksFromHTML.entityMap
	);

	return EditorState.createWithContent(contentState);
};

/**
 * Get the block type of the current selection.
 *
 * If the current selection spans multiple blocks, and:
 *   * the blocks are of the same type, that type is returned.
 *   * the blocks are of different types, null is returned.
 * @param editorState The current editor state
 */
export const getCurrentBlockType = (editorState: EditorState) => {
	const selection = editorState.getSelection();
	const contentState = editorState.getCurrentContent();

	// Get the start/end of our current selection
	const startKey = selection.getStartKey();
	const endKey = contentState.getKeyAfter(selection.getEndKey());

	// Take only the content state within our current selection
	const selectedBlocks = contentState
		.getBlockMap()
		.skipUntil((_, k) => k === startKey)
		.takeUntil((_, k) => k === endKey)
		.toList();

	// Get the type of each block within the current selection as a unique list
	const blockTypes = uniq(
		selectedBlocks
			.map((block) => block?.getType())
			.filter(Boolean)
			.toArray()
	);

	// If exactly one block type, we've selected one or more blocks of the same
	// type, which can all have their type displayed/edited together. If > 1,
	// we've selected multiple blocks with conflicting types; i.e., we can't
	// display a unified block type for the current selection, and return null.
	return blockTypes.length === 1 ? blockTypes[0] : null;
};

/**
 * Determines whether the keyboard event is (Shift || Alt || Ctrl) + Return.
 */
export const isSoftNewlineEvent = (e: React.KeyboardEvent<{}>) =>
	e.which === 13 /* Return */ &&
	(e.getModifierState("Shift") ||
		e.getModifierState("Alt") ||
		e.getModifierState("Control"));

export const getCharCount = (contentState: ContentState) => {
	const plainText = contentState.getPlainText("");
	const regex = /(?:\r\n|\r|\n)/g;
	const cleanString = plainText.replace(regex, "").trim();
	return cleanString.length;
};

export const getWordCount = (contentState: ContentState) => {
	const plainText = contentState.getPlainText("");
	const regex = /(?:\r\n|\r|\n)/g;
	const cleanString = plainText.replace(regex, "").trim();
	const wordArray = cleanString.match(/\S+/g);
	return wordArray ? wordArray.length : 0;
};

export const getLineCount = (contentState: ContentState) => {
	const blockArray = contentState.getBlocksAsArray();
	return blockArray ? blockArray.length : 0;
};

export const charCountValidator = (limit: number) =>
	yup
		.object<EditorState>()
		.test(
			"charCount",
			`Must not exceed ${limit} character${limit === 1 ? "" : "s"}`,
			(value?: object) => {
				if (!(value instanceof EditorState)) {
					return false;
				}
				const count = getCharCount(value.getCurrentContent());
				return count <= limit;
			}
		);

export const wordCountValidator = (limit: number) =>
	yup
		.object<EditorState>()
		.test(
			"charCount",
			`Must not exceed ${limit} word${limit === 1 ? "" : "s"}`,
			(value?: object) => {
				if (!(value instanceof EditorState)) {
					return false;
				}
				const count = getWordCount(value.getCurrentContent());
				return count <= limit;
			}
		);

export const lineCountValidator = (limit: number) =>
	yup
		.object()
		.test(
			"charCount",
			`Must not exceed ${limit} line${limit === 1 ? "" : "s"}`,
			(value?: object) => {
				if (!(value instanceof EditorState)) {
					return false;
				}
				const count = getLineCount(value.getCurrentContent());
				return count <= limit;
			}
		);
