import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
	content_footer: {
		textAlign: "right",
		margin: theme.spacing(4, 0),
	},
}));
interface ContentFooterProps {
	children: JSX.Element;
}
const ContentFooter: React.FC<ContentFooterProps> = ({ children }) => {
	const classes = useStyles();
	return <div className={classes.content_footer}>{children}</div>;
};

export default ContentFooter;
