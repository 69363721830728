import React from "react";
import { FieldProps, getIn } from "formik";
import { EditorState } from "draft-js";
import { RichTextEditor } from "./RichTextEditor";

/**
 * This component can be passed to a Formik `Field`'s `component` prop.
 *
 * It will parse out the `error` and `touched` values of the field, and use that
 * to determine whether to add the `error` class to the rich text component.
 */
export const RichTextEditorAdapter: React.FC<
	FieldProps<EditorState> & {
		formattingOptions?: {
			header?: boolean;
			bulleted?: boolean;
			bold?: boolean;
			Italic?: boolean;
		};
	}
> = ({ field, form, ...props }) => {
	const error: string = getIn(form.errors, field.name);
	const touched: boolean = getIn(form.touched, field.name);
	const loading: boolean = getIn(form.status, `loading.${field.name}`) || false;
	const hasError = !!error && touched;

	return (
		<RichTextEditor
			value={field.value}
			onChange={(editorState: EditorState) => {
				form.setFieldValue(field.name, editorState);
			}}
			onBlur={() => {
				form.setFieldTouched(field.name);
			}}
			error={hasError}
			loading={loading}
			{...props}
		/>
	);
};
