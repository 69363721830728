import React, {
	FC,
	createContext,
	useReducer,
	useContext,
	useMemo,
} from "react";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../../themes/DarkModeTheme";
import { lightTheme } from "../../themes/LightModeTheme";

interface GlobalDarkModeContextValues {
	isDarkMode: boolean;
	setDarkMode: (isDarkMode: boolean) => void;
}

export const GlobalDarkModeContext = createContext<GlobalDarkModeContextValues>(
	{
		isDarkMode: JSON.parse(sessionStorage.getItem("dark-mode") ?? "false"),
		setDarkMode: () => {
			// eslint-disable-next-line no-console
			console.error("Called setDarkModeProps outside of context provider.");
		},
	}
);

export const GlobalDarkModeProvider: FC<{}> = ({ children }) => {
	const defaultDarkModeState: Required<boolean> = JSON.parse(
		sessionStorage.getItem("dark-mode") ?? "false"
	);

	function reducer(state, action) {
		switch (action) {
			case true:
				sessionStorage.setItem("dark-mode", JSON.stringify(action));
				return JSON.parse(sessionStorage.getItem("dark-mode") ?? "false");
			case false:
				sessionStorage.setItem("dark-mode", JSON.stringify(action));
				return JSON.parse(sessionStorage.getItem("dark-mode") ?? "false");
			default:
				return action;
		}
	}
	const [isDarkMode, setDarkMode] = useReducer(reducer, defaultDarkModeState);

	const value: GlobalDarkModeContextValues = useMemo(
		() => ({
			isDarkMode,
			setDarkMode,
		}),
		[isDarkMode, setDarkMode]
	);

	return (
		<GlobalDarkModeContext.Provider value={value}>
			<ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
				{children}
			</ThemeProvider>
		</GlobalDarkModeContext.Provider>
	);
};

export const UseGlobalDarkModeContext = () => useContext(GlobalDarkModeContext);
