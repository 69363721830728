import React from "react";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import defaultHeaderImage from "../../assets/images/header-default.png";
import ctaHeaderImage from "../../assets/images/header-cta.png";
import { makeStyles } from "@mui/styles";
import {
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	Theme,
} from "@mui/material";

import routes, { ProtectedRouteParams } from "../Routes";
import {
	useHomeOptionsQuery,
	useSetHomeOptionsMutation,
	HomeOptionsInput,
	HomeOptionsDocument,
	HeaderTypes,
} from "../../types/graphql-types";
import { Formik, Form, Field, FieldProps } from "formik";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { mapGraphQlError } from "../../utils/errorHandler";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";
import removeTypename from "../../utils/removeTypename";

const useStyles = makeStyles((theme: Theme) => ({
	group: {
		borderBottom: `1px solid ${theme.color.grey.main}`,
		paddingLeft: theme.spacing(2),
		marginBottom: theme.spacing(4),
		justifyContent: "space-around",
	},
	radio: {
		marginBottom: theme.spacing(3),
	},
	radioLabel: {
		display: "flex",
		flexDirection: "column",
	},
	radioImage: {
		width: 150,
	},
	radioTextArea: {
		marginLeft: theme.spacing(2),
	},
	radioTextHead: {
		marginBottom: theme.spacing(0.5),
		marginTop: theme.spacing(2),
	},
	description: {
		fontSize: "0.75rem",
	},
	imageStandardsLink: {
		marginLeft: theme.spacing(0.5),
	},
	standardHeader: {},
	ctaHeader: {},
	headerImg: {},
	subheader: {
		marginBottom: "32px !important",
	},
}));

export const Header: React.FC<RouteComponentProps<ProtectedRouteParams>> = ({
	match,
}) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Header Options!",
			});
		}
	}, [error, setSnackbarProps]);

	const [
		setHomeOptionsMutation,
		setHomeOptionsMutationResponse,
	] = useSetHomeOptionsMutation();

	const homeOptionsResult = data?.homeOptions;

	const initialValues = removeTypename(homeOptionsResult);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || setHomeOptionsMutationResponse.loading,
		});
	}, [loading, setHomeOptionsMutationResponse, setLoadingIndicatorProps]);

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title={routes.HEADER.title}
				button={<SaveButton form={"header-form"} />}
			/>
			{!initialValues ? null : (
				<Formik<HomeOptionsInput>
					initialValues={initialValues}
					onSubmit={async (homeOptions: HomeOptionsInput) => {
						const result = await setHomeOptionsMutation({
							variables: {
								portalId,
								homeOptions,
							},
							refetchQueries: [
								{
									query: HomeOptionsDocument,
									variables: {
										portalId: portalId,
									},
								},
							],
							awaitRefetchQueries: true,
						});
						if (result?.data) {
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: true,
								message: "Header Options Saved!",
							});
						} else {
							const error =
								result?.errors?.[0] && mapGraphQlError(result.errors[0]);
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: false,
								message: error?.displayableError || "Header Options Not Saved!",
							});
						}
					}}
					enableReinitialize={true}
				>
					<Form id="header-form">
						<Typography variant="h6" className={classes.subheader}>
							Select Header Style
						</Typography>
						<Field name="header">
							{({ field }: FieldProps) => (
								<RadioGroup
									sx={{
										display: "flex",
										flexDirection: "row",
									}}
									className={classes.group}
									{...field}
								>
									{Object.keys(HeaderTypes).map((headerType: HeaderTypes) => (
										<FormControlLabel
											key={headerType}
											value={HeaderTypes[headerType]}
											control={<Radio color="secondary" />}
											className={classes.radio}
											labelPlacement="top"
											label={
												<div className={classes.radioLabel}>
													<div className={classes.radioTextArea}>
														<h5 className={classes.radioTextHead}>
															{HeaderTypes[headerType] === HeaderTypes.Cta
																? "Call to Action"
																: headerType}
														</h5>
													</div>
													<div
														className={
															HeaderTypes[headerType] === HeaderTypes.Cta
																? classes.ctaHeader
																: classes.standardHeader
														}
													>
														<img
															src={
																HeaderTypes[headerType] === HeaderTypes.Cta
																	? ctaHeaderImage
																	: defaultHeaderImage
															}
															alt={
																HeaderTypes[headerType] === HeaderTypes.Cta
																	? "map header stand in"
																	: "default header stand in"
															}
														/>
													</div>
												</div>
											}
										/>
									))}
								</RadioGroup>
							)}
						</Field>
					</Form>
				</Formik>
			)}
			<ContentFooter>
				<SaveButton form={"header-form"} />
			</ContentFooter>
		</Layout>
	);
};
