import {
	Doctor,
	TeamMemberFragment,
	TeamMemberInput,
} from "../types/graphql-types";

export const formatDoctorName = (
	doctor: Doctor,
	includeMiddleName = true,
	includeDesignation = false
) => {
	let retVal = "";

	if (doctor.firstName) {
		retVal += doctor.firstName;
		retVal += " ";
	}

	if (includeMiddleName && doctor.middleName) {
		retVal += doctor.middleName;
		retVal += " ";
	}

	if (doctor.lastName) {
		retVal += doctor.lastName;
	}

	if (includeDesignation) {
		retVal += ", ";
		retVal += doctor.designation;
	}

	retVal = retVal.trim();

	return retVal;
};

export const formatTeamMemberName = (
	member: TeamMemberFragment | TeamMemberInput,
	includeMiddleName = true
) => {
	let retVal = "";

	if (member.firstName) {
		retVal += member.firstName;
		retVal += " ";
	}

	if (includeMiddleName && member.middleName) {
		retVal += member.middleName;
		retVal += " ";
	}

	if (member.lastName) {
		retVal += member.lastName;
	}

	retVal = retVal.trim();

	return retVal;
};
