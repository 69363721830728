import React from "react";
import { Map } from "immutable";

export enum Style {
	Bold = "BOLD",
	Italic = "ITALIC",
}

export enum BlockType {
	Paragraph = "unstyled",
	Header = "header-three",
	Bulleted = "unordered-list-item",
}

/**
 * Effects how the HTML content gets rendered in the RTE editor view, including
 * deserialization AND copy/pasting from HTML; does NOT effect how the editor
 * state gets serialized to HTML
 */
export const customBlockRenderMap = Map({
	[BlockType.Paragraph]: {
		element: "div",
		aliasedElements: ["p", "div", "br"],
	},
	[BlockType.Header]: {
		element: "h3",
		aliasedElements: ["h1", "h2", "h3", "h4", "h5", "h6"],
	},
	[BlockType.Bulleted]: {
		element: "li",
		wrapper: <ul />,
		aliasedElements: ["li"],
	},
});
