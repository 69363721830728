import React from "react";
import { useRouteMatch } from "react-router-dom";
import { EditorState } from "draft-js";
import { Field, FieldInputProps, FieldProps, FormikProps, getIn } from "formik";
import { makeStyles } from "@mui/styles";
import {
	Button,
	Checkbox,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControlLabel,
	Grid,
	Theme,
	Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { deserialize } from "../../components/RichTextEditor";
import {
	Maybe,
	ServiceWithChildrenFragment,
	DoctorsChairServiceFragment,
	useDoctorsChairQuery,
	Service as ServiceType,
} from "../../types/graphql-types";
import { DoctorsChairDialog } from "./DoctorsChairDialog";
import { ApolloError } from "@apollo/client";

const useStyles = makeStyles((theme: Theme) => ({
	head: {
		backgroundColor: "#f5f5f5 !important",
	},
	panelDetails: {
		border: `1px solid ${theme.color.grey.light}`,
		display: "block",
	},
	child: {
		width: "100%",
	},
	childItemContainer: {
		alignItems: "baseline",
		borderBottom: `1px dotted ${theme.color.grey.light}`,
	},
	doctorsChairLink: {
		textAlign: "right",
	},
	checkIcon: {
		paddingRight: theme.spacing(0.5),
	},
	featuredIcon: {
		color: theme.color.orange.main,
		paddingRight: theme.spacing(0.5),
	},
	featuredServiceLink: {
		display: "flex",
	},
}));

interface ServiceProps {
	selectedFeatured: string[];
	setSelectedFeatured: React.Dispatch<React.SetStateAction<string[]>>;
	field: FieldInputProps<ServiceWithChildrenFragment>;
	form: FormikProps<ServiceWithChildrenFragment>;
	featuredServicesLoading: boolean;
	featuredServicesError: ApolloError | undefined;
	handleFeaturedServicesClick: (taxonomyId: string) => void;
	parentIndex: number;
	showFeaturedServices: boolean;
}

interface DoctorsChairFormValues {
	enabled: boolean;
	doctor: string;
	text: EditorState;
}

export const Service: React.FC<ServiceProps> = ({
	field: { name, value: service },
	form,
	selectedFeatured,
	setSelectedFeatured,
	featuredServicesLoading,
	featuredServicesError,
	handleFeaturedServicesClick,
	parentIndex,
	showFeaturedServices,
}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = React.useState<boolean>(service.enabled);
	const [doctorsChairEditIndex, setDoctorsChairEditIndex] = React.useState<
		Maybe<number>
	>(null);
	const hasChildren = !!service.children?.length;
	const parentName = `${name}.enabled`;
	const parentValue: boolean = React.useMemo(
		() => getIn(form.values, parentName),
		[form.values, parentName]
	);
	const children: Maybe<ServiceWithChildrenFragment[]> = React.useMemo(
		() => getIn(form.values, `${name}.children`),
		[form.values, name]
	);

	const parentSelectable = React.useMemo(
		() =>
			selectedFeatured.includes(service.taxonomyId) ||
			selectedFeatured.length < 3,
		[selectedFeatured, service.taxonomyId]
	);

	const match = useRouteMatch<{ portalId?: string }>();
	const portalId = match?.params.portalId || "";
	const {
		data: doctorsChairData,
		loading: doctorsChairLoading,
		error: doctorsChairError,
	} = useDoctorsChairQuery({
		variables: {
			portalId,
			taxonomyId: service.taxonomyId,
		},
	});

	const doctorsChairInitialValues: DoctorsChairFormValues = React.useMemo(() => {
		let doctorsChairService: Maybe<DoctorsChairServiceFragment> = null;

		if (doctorsChairEditIndex !== null && doctorsChairData) {
			doctorsChairService =
				doctorsChairData.doctorsChair.services.find(
					({ taxonomyId }) =>
						taxonomyId === children?.[doctorsChairEditIndex]?.taxonomyId
				) || null;
		}

		return {
			enabled: doctorsChairService?.enabled || false,
			doctor: doctorsChairService?.doctor?.npi || "",
			text: deserialize(doctorsChairService?.text || ""),
		};
	}, [doctorsChairEditIndex, doctorsChairData, children]);

	return (
		<>
			<Accordion
				defaultExpanded={service.enabled}
				expanded={expanded || !hasChildren}
				onChange={(_event, exp: boolean) => setExpanded(exp)}
			>
				<AccordionSummary
					expandIcon={hasChildren && <ExpandMoreIcon />}
					aria-label="Expand"
					aria-controls="additional-actions1-content"
					id="additional-actions1-header"
					className={classes.head}
				>
					<Grid container>
						<Grid item xs={9}>
							<Field name={parentName}>
								{({ field }: FieldProps) => (
									<FormControlLabel
										aria-label={service.text}
										onClick={(
											event: React.MouseEvent<HTMLLabelElement, MouseEvent>
										) => event.stopPropagation()}
										onFocus={(event: React.FocusEvent<HTMLLabelElement>) =>
											event.stopPropagation()
										}
										control={
											<Checkbox
												color="secondary"
												{...field}
												checked={field.value}
												onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
													field.onChange(e);
													if (e.target.checked) {
														setExpanded(true);
													} else {
														setExpanded(false);
														const parentChildTaxonomyIds = [service.taxonomyId];
														service.children?.forEach(
															(_child: ServiceType, index: number) => {
																parentChildTaxonomyIds.push(_child.taxonomyId);
																form.setFieldValue(
																	`[${parentIndex}].children[${index}].enabled`,
																	false
																);
															}
														);
														if (
															parentChildTaxonomyIds.some((taxonomyId) =>
																selectedFeatured.includes(taxonomyId)
															)
														) {
															const filteredSf = selectedFeatured.filter(
																(taxonomyId) =>
																	!parentChildTaxonomyIds.includes(taxonomyId)
															);
															setSelectedFeatured(filteredSf);
														}
													}
												}}
											/>
										}
										label={service.text}
									/>
								)}
							</Field>
						</Grid>
						{showFeaturedServices && (
							<Grid
								item
								xs={3}
								className={classes.doctorsChairLink}
								style={{ display: "flex" }}
							>
								{service.taxonomyId && service.enabled && (
									<Tooltip
										title={
											featuredServicesLoading
												? "Loading..."
												: featuredServicesError
												? "Error Loading Featured Service Info"
												: "Toggle Featured Service"
										}
										placement="left"
									>
										<FormControlLabel
											style={{ marginLeft: "5%" }}
											aria-label={service.text}
											onClick={(
												event: React.MouseEvent<HTMLLabelElement, MouseEvent>
											) => {
												event.preventDefault();
												event.stopPropagation();
											}}
											onFocus={(event: React.FocusEvent<HTMLLabelElement>) => {
												event.preventDefault();
												event.stopPropagation();
											}}
											control={
												<Button
													color="primary"
													onClick={() =>
														handleFeaturedServicesClick(service.taxonomyId)
													}
													disabled={
														featuredServicesLoading ||
														!!featuredServicesError ||
														!parentSelectable
													}
												>
													{selectedFeatured.find(
														(taxonomyId) => taxonomyId === service.taxonomyId
													) ? (
														<StarIcon className={classes.featuredIcon} />
													) : (
														<StarBorderIcon className={classes.featuredIcon} />
													)}
													Featured
												</Button>
											}
											label={""}
										/>
									</Tooltip>
								)}
							</Grid>
						)}
					</Grid>
				</AccordionSummary>
				{hasChildren && (
					<AccordionDetails className={classes.panelDetails}>
						{service.children?.map((child: ServiceType, index: number) => {
							const childSelectable =
								selectedFeatured.includes(child.taxonomyId) ||
								selectedFeatured.length < 3;
							return (
								<Grid
									key={child.taxonomyId}
									container
									className={classes.childItemContainer}
								>
									<Grid item xs={8}>
										<FormControlLabel
											aria-label={child.text}
											onClick={(
												event: React.MouseEvent<HTMLLabelElement, MouseEvent>
											) => event.stopPropagation()}
											onFocus={(event: React.FocusEvent<HTMLLabelElement>) =>
												event.stopPropagation()
											}
											control={
												<Field name={`${name}.children[${index}].enabled`}>
													{({ field }: FieldProps) => (
														<Checkbox
															color="secondary"
															{...field}
															checked={field.value}
															onChange={(
																e: React.ChangeEvent<HTMLInputElement>
															) => {
																field.onChange(e);
																if (!parentValue && e.target.checked) {
																	form.setFieldValue(parentName, true);
																} else if (
																	!e.target.checked &&
																	selectedFeatured.includes(child.taxonomyId)
																) {
																	const filteredSf = selectedFeatured.filter(
																		(taxonomyId) =>
																			taxonomyId !== child.taxonomyId
																	);
																	setSelectedFeatured(filteredSf);
																}
															}}
														/>
													)}
												</Field>
											}
											label={child.text}
											className={classes.child}
										/>
									</Grid>
									<Grid item xs={2} className={classes.doctorsChairLink}>
										{children?.[index]?.enabled &&
											children?.[index]?.hasFeaturedServiceContent &&
											showFeaturedServices && (
												<Tooltip
													title={
														featuredServicesLoading
															? "Loading..."
															: featuredServicesError
															? "Error Loading Featured Service Info"
															: "Toggle Featured Service"
													}
													placement="left"
												>
													<span>
														<Button
															color="primary"
															onClick={() => {
																handleFeaturedServicesClick(
																	children?.[index].taxonomyId
																);
															}}
															disabled={
																featuredServicesLoading ||
																!!featuredServicesError ||
																!childSelectable
															}
														>
															{selectedFeatured.find(
																(taxonomyId) =>
																	taxonomyId === children?.[index].taxonomyId
															) ? (
																<StarIcon className={classes.featuredIcon} />
															) : (
																<StarBorderIcon
																	className={classes.featuredIcon}
																/>
															)}
															Featured
														</Button>
													</span>
												</Tooltip>
											)}
									</Grid>
									<Grid item xs={2} className={classes.doctorsChairLink}>
										{children?.[index]?.enabled && (
											<Tooltip
												title={
													doctorsChairLoading
														? "Loading..."
														: doctorsChairError
														? "Error Loading Doctor's Chair Info"
														: "Edit Doctor's Chair Info"
												}
												placement="right"
											>
												<span>
													<Button
														sx={{ textTransform: "none" }}
														color="primary"
														onClick={() => setDoctorsChairEditIndex(index)}
														disabled={
															doctorsChairLoading || !!doctorsChairError
														}
													>
														{doctorsChairData?.doctorsChair.services.find(
															({ taxonomyId }) =>
																taxonomyId === children?.[index].taxonomyId
														)?.enabled && (
															<CheckCircleIcon className={classes.checkIcon} />
														)}
														Doctor's Chair
													</Button>
												</span>
											</Tooltip>
										)}
									</Grid>
								</Grid>
							);
						})}
					</AccordionDetails>
				)}
			</Accordion>
			{children &&
				doctorsChairEditIndex !== null &&
				children?.[doctorsChairEditIndex] &&
				doctorsChairData?.doctorsChair.doctors && (
					<DoctorsChairDialog
						parent={service}
						child={children[doctorsChairEditIndex]}
						doctors={doctorsChairData.doctorsChair.doctors}
						initialValues={doctorsChairInitialValues}
						handleClose={() => setDoctorsChairEditIndex(null)}
					/>
				)}
		</>
	);
};
