/**
 * THIS IS A GENERATED FILE, DO NOT MODIFY DIRECTLY
 */

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		AboutOptionsWidget: [
			"AboutEsteemWidget",
			"AboutInvisalignWidget",
			"AboutMissionStatementWidget",
			"AboutWelcomeBannerWidget",
			"StandardAboutOptionsWidget",
		],
		HomeOptionsWidget: [
			"BrandBadgesWidget",
			"EsteemWidget",
			"FeaturedServicesWidget",
			"GoogleWidget",
			"InvisalignWidget",
			"MissionStatementWidget",
			"ProvidersWidget",
			"SmileGalleryWidget",
			"StandardWidget",
			"WelcomeBannerWidget",
		],
	},
};
export default result;
