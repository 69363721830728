import React from "react";
import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";

import routes, { ProtectedRouteParams } from "../Routes";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import { TemplateForm } from "./TemplateForm";

export const WelcomeBanner: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	// const [file, setFile] = React.useState<File | null>(null);
	const portalId = match?.params?.portalId?.toString() || "";

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title={routes.WELCOME_BANNER.title}
				button={<SaveButton form="homepage-template-form" />}
				tooltipText="All templates include primary call to action (CTA), phone
                number and practice name."
			/>
			<TemplateForm portalId={portalId} />
		</Layout>
	);
};
