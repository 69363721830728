import { useRef, useEffect, useMemo, useReducer, useCallback } from "react";
import { debounce, throttle, Cancelable } from "lodash";
import { Maybe } from "../types/graphql-types";

const DEFAULT_DELAY = 1000 * 60 * 20;
const DEFAULT_EVENTS = [
	"mousemove",
	"keydown",
	"wheel",
	"DOMMouseScroll",
	"mouseWheel",
	"mousedown",
	"touchstart",
	"touchmove",
	"MSPointerDown",
	"MSPointerMove",
	"visibilitychange",
];

export interface IdleTimerOptions {
	/** The time, in ms, before the user is considered idle */
	delay?: number;
	/** The document DOM events to watch for to reset the idle timer */
	events?: string[];
	/** Function to call when user goes idle */
	onIdle?: () => void;
	/** Function to call when user becomes active (from the idle state) */
	onActive?: () => void;
	/** Function to call when user performs any action */
	onAction?: () => void;
	/** Throttle for `onAction` in ms */
	throttle?: number;
}

export default function useIdleTimer(userOptions: IdleTimerOptions) {
	/**
	 * These callbacks need to be built as refs so they don't cause re-renders.
	 * The only time we want to update the ref is when the user provides a new
	 * callback value, and then we want to debounce/throttle that callback exactly
	 * once, and never touch it again until the user provides a new callback.
	 */
	const onIdleCallback = useRef<(() => void) & Cancelable>(
		debounce(() => void 0, 0)
	);
	useEffect(() => {
		onIdleCallback.current.cancel();
		// Debounce to avoid duplicate calls from multiple event handlers
		onIdleCallback.current = debounce(
			userOptions.onIdle || (() => void 0),
			100
		);
	}, [userOptions.onIdle]);

	const onActiveCallback = useRef<(() => void) & Cancelable>(
		debounce(() => void 0, 0)
	);
	useEffect(() => {
		onActiveCallback.current.cancel();
		// Debounce to avoid duplicate calls from multiple event handlers
		onActiveCallback.current = debounce(
			userOptions.onActive || (() => void 0),
			100
		);
	}, [userOptions.onActive]);

	const onActionCallback = useRef<(() => void) & Cancelable>(
		debounce(() => void 0, 0)
	);
	useEffect(() => {
		onActionCallback.current.cancel();
		// Throttle this according to user settings
		onActionCallback.current = throttle(
			userOptions.onAction || (() => void 0),
			userOptions.throttle,
			{
				leading: false,
				trailing: true,
			}
		);
	}, [userOptions.onAction, userOptions.throttle]);

	// Pull these options from userOptions with defaults
	const delay = useMemo(() => userOptions.delay || DEFAULT_DELAY, [
		userOptions.delay,
	]);
	const events = useMemo(() => userOptions.events || DEFAULT_EVENTS, [
		userOptions.events,
	]);

	/**
	 * Using a reducer here so that every time we set a new idle state, the
	 * onActive and onIdle callbacks get called automatically.
	 */
	const [idle, setIdle] = useReducer((oldIdle: boolean, newIdle: boolean) => {
		if (!oldIdle && newIdle) {
			onIdleCallback.current();
		} else if (oldIdle && !newIdle) {
			onActiveCallback.current();
		}
		return newIdle;
	}, false);

	/**
	 * Manage the timeout ID with refs. When the resetTimeout callback is called,
	 * the current timeout is cleared and a new timeout is set.
	 */
	const timeoutId = useRef<Maybe<number>>(null);
	const resetTimeout = useCallback(() => {
		if (timeoutId.current !== null) {
			window.clearTimeout(timeoutId.current);
		}
		const newTimeout = window.setTimeout(() => {
			setIdle(true);
		}, delay);

		timeoutId.current = newTimeout;
	}, [delay]);

	const handleEvent = useRef<() => void>();
	// Wire up the event handlers
	useEffect(() => {
		const bindEvents = () => {
			for (const event of events) {
				if (handleEvent.current) {
					document.addEventListener(event, handleEvent.current);
				}
			}
		};

		const unbindEvents = () => {
			for (const event of events) {
				if (handleEvent.current) {
					document.removeEventListener(event, handleEvent.current);
				}
			}
		};

		/**
		 * If the events list or delay option has changed, unbind all the old events
		 * before binding the new events
		 */
		if (handleEvent.current) {
			unbindEvents();
		}

		handleEvent.current = () => {
			resetTimeout();

			setIdle(false);
			onActionCallback.current();
		};

		bindEvents();

		return unbindEvents;
	}, [events, resetTimeout]);

	return [idle];
}
