import React from "react";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";

import { ProtectedRouteParams } from "../Routes";
import {
	useDoctorsQuery,
	useAssignDoctorMutation,
	useDeleteDoctorAssignmentMutation,
	useArrangeDoctorsMutation,
	Doctor,
	DoctorsDocument,
} from "../../types/graphql-types";

import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { Formik } from "formik";
import { DoctorAssignmentBody } from "./DoctorAssignmentBody";
import { mapGraphQlError } from "../../utils/errorHandler";

export type DoctorAssignmentInput = {
	doctors: Array<Doctor>;
};

export const DoctorAssignment: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const doctorAssignment = useDoctorsQuery({
		variables: {
			portalId: portalId,
			includeHeadShot: false,
			includeBio: false,
		},
	});

	const [assignDoctor, assignDoctorResult] = useAssignDoctorMutation({
		variables: {
			portalId,
			npi: "",
		},
	});

	const [deleteDoctor, deleteDoctorResult] = useDeleteDoctorAssignmentMutation({
		variables: {
			portalId,
			npi: "",
		},
	});

	const [arrangeDoctors, arrangeDoctorsResult] = useArrangeDoctorsMutation({
		variables: {
			portalId,
			order: [],
		},
	});

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading:
				doctorAssignment.loading ||
				assignDoctorResult.loading ||
				deleteDoctorResult.loading ||
				arrangeDoctorsResult.loading,
		});
	}, [
		doctorAssignment.loading,
		assignDoctorResult.loading,
		deleteDoctorResult.loading,
		arrangeDoctorsResult.loading,
		setLoadingIndicatorProps,
	]);

	const doctorAssignmentResult = doctorAssignment.data?.doctors;

	const initialValues: DoctorAssignmentInput = React.useMemo(() => {
		return {
			doctors: doctorAssignmentResult || [],
		};
	}, [doctorAssignmentResult]);

	return (
		<Layout portalId={match.params.portalId}>
			<Formik
				initialValues={initialValues}
				onSubmit={async (doctorAssignmentInput: DoctorAssignmentInput) => {
					const result = await arrangeDoctors({
						variables: {
							portalId,
							order: doctorAssignmentInput.doctors.map(({ npi }) => npi),
						},
						refetchQueries: [
							{
								query: DoctorsDocument,
								variables: {
									portalId: portalId,
								},
							},
						],
						awaitRefetchQueries: true,
					});
					if (result?.data) {
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: true,
							message: "Doctor Assignment Order Saved!",
						});
					} else {
						const error =
							result?.errors?.[0] && mapGraphQlError(result.errors[0]);
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: false,
							message:
								error?.displayableError || "Doctor Assignment Order Not Saved!",
						});
					}
				}}
				enableReinitialize={true}
			>
				{(formik) => (
					<DoctorAssignmentBody
						{...formik}
						portalId={portalId}
						doctorAssignment={doctorAssignment}
						assignDoctor={assignDoctor}
						deleteDoctor={deleteDoctor}
						arrangeDoctors={arrangeDoctors}
					/>
				)}
			</Formik>
		</Layout>
	);
};
