import React from "react";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import { FormikProps } from "formik";
import {
	Templates,
	BaseHomeOptionsWidgetFragment,
	HomeOptionsWidget,
	GetServicesQueryResult,
	AboutOptionsWidget,
	AboutOptionsWidgetInput,
	HomeOptionsWidgetInput,
	HomeOptionsInput,
} from "../../types/graphql-types";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";
import { DraggableList } from "../../components/DraggableList";
import { WidgetNames } from "./WidgetNames";
import { Widget } from "./Widget";
import { SortableWidget } from "./SortableWidget";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";
import routes from "../../routes/Routes";

const optionalWidgets = [
	WidgetNames.doctor_bio,
	WidgetNames.featured_services,
	WidgetNames.meet_the_team,
	WidgetNames.google_block,
	WidgetNames.smile_gallery,
];

const additionalWidgets = [
	WidgetNames.invisalign,
	WidgetNames.mission_statement,
	WidgetNames.esteem,
];

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		display: "flex",
	},
	content_main: {
		flex: 1,
	},
	content_sidebar: {
		padding: theme.spacing(2.5),
		height: "fit-content",
		width: 180,
		[theme.breakpoints.up("md")]: {
			marginLeft: theme.spacing(2),
		},
	},
	content_sidebar_dragging: {
		background: theme.color.grey.light,
		border: `2px dashed ${theme.color.grey.main}`,
	},
	subheader: {
		marginBottom: "8px !important",
	},
	tooltip: {
		marginBottom: theme.spacing(2),
	},
	cards: {
		display: "flex",
		flexWrap: "wrap",
	},
	card: {
		marginBottom: theme.spacing(2),
		marginRight: "2%",
		width: "48%",
		"&:nth-child(2n)": {
			marginRight: "0 !important",
		},
		[theme.breakpoints.up("md")]: {
			width: "32%",
			"&:nth-child(3n)": {
				marginRight: "0 !important",
			},
			"&:nth-child(2n)": {
				marginRight: "2% !important",
			},
		},
	},
}));

interface WidgetManagerProps
	extends FormikProps<{
		widgets: Array<HomeOptionsWidgetInput | AboutOptionsWidgetInput>;
	}> {
	servicesQueryResponse?: GetServicesQueryResult;
	pageTitle: string;
}

const sortWidgets = (
	widgets: Array<HomeOptionsWidgetInput | AboutOptionsWidgetInput>
): Array<HomeOptionsWidgetInput | AboutOptionsWidgetInput> => {
	const welcomeBannerIndex = widgets.findIndex(
		({ name }) => name === WidgetNames.welcome_banner
	);
	const globalPromoIndex = widgets.findIndex(
		({ name }) => name === WidgetNames.global_promo
	);
	const homePromoIndex = widgets.findIndex(
		({ name }) => name === WidgetNames.home_promo
	);

	// If we don't have a welcome banner and global promo, it's an error
	if (welcomeBannerIndex >= 0 && globalPromoIndex >= 0) {
		const welcomeBannerTarget = 0;
		const homePromoTarget = 1;
		let globalPromoTarget = homePromoIndex > 0 ? 4 : 3;
		if (widgets.length <= 4) {
			// If we only have 4 widgets, including the two promos, then there only
			// needs to be one widget before the global promo.
			globalPromoTarget = homePromoIndex > 0 ? 3 : 2;
		}
		if (welcomeBannerIndex !== welcomeBannerTarget) {
			const [welcomeBanner] = widgets.splice(welcomeBannerIndex, 1);
			widgets.splice(welcomeBannerTarget, 0, welcomeBanner);
		}
		if (globalPromoIndex !== globalPromoTarget) {
			const [globalPromo] = widgets.splice(globalPromoIndex, 1);
			widgets.splice(globalPromoTarget, 0, globalPromo);
		}
		if (homePromoIndex > 0 && homePromoIndex !== homePromoTarget) {
			const [homePromo] = widgets.splice(homePromoIndex, 1);
			widgets.splice(homePromoTarget, 0, homePromo);
		}
	} else {
		// TODO: Telemetry error
	}
	return widgets;
};

export const WidgetManager: React.FC<WidgetManagerProps> = ({
	servicesQueryResponse,
	pageTitle,
	...formik
}) => {
	const classes = useStyles();

	const disableExtraOptionalWidgets = React.useMemo(() => {
		return (
			formik.values.widgets.filter(
				({ name, enabled }) =>
					enabled && optionalWidgets.includes(name as WidgetNames)
			).length >= 3
		);
	}, [formik]);

	const sortedWidgets = React.useMemo(() => {
		return sortWidgets(
			formik.values.widgets.filter(
				(widget) => widget.enabled || (widget as HomeOptionsWidgetInput).isPromo
			)
		);
	}, [formik]);

	const handleSort = (
		sortedList: Array<HomeOptionsWidget | AboutOptionsWidget>
	) => {
		formik.setFieldValue("widgets", [
			...sortWidgets(sortedList),
			...formik.values.widgets.filter(
				({ name }) =>
					name &&
					!sortedList
						.map((item: HomeOptionsWidget | AboutOptionsWidget) => item.name)
						.includes(name)
			),
		]);
	};

	return (
		<>
			<ContentHeader
				title={pageTitle}
				button={
					<SaveButton form="widget-manager-form" disabled={!formik.isValid} />
				}
			/>
			<div className={classes.content}>
				<div className={classes.content_main}>
					<form id="widget-manager-form" onSubmit={formik.handleSubmit}>
						{pageTitle === routes.HOME_PAGE_WIDGETS.title && (
							<>
								<Typography variant="h6" className={classes.subheader}>
									Optional Widgets
								</Typography>
								<div className={classes.tooltip}>
									<Tooltip
										type={TooltipTypes.Info}
										text={
											<>You may select up to THREE of the following widgets.</>
										}
									/>
								</div>
							</>
						)}
						<div className={classes.cards}>
							{formik.values.widgets &&
								formik.values.widgets.length > 0 &&
								formik.values.widgets.map(
									(widget: BaseHomeOptionsWidgetFragment, index: number) => {
										if (
											pageTitle === routes.ABOUT_PAGE_WIDGETS.title ||
											optionalWidgets.includes(widget.name as WidgetNames)
										) {
											return (
												<div key={index} className={classes.card}>
													{widget.name === WidgetNames.featured_services ? (
														<Widget
															disabled={disableExtraOptionalWidgets}
															widget={widget}
															index={index}
															services={
																servicesQueryResponse?.data?.getServices
															}
														/>
													) : (
														<Widget
															disabled={disableExtraOptionalWidgets}
															widget={widget}
															index={index}
														/>
													)}
												</div>
											);
										} else {
											return null;
										}
									}
								)}
						</div>

						{pageTitle === routes.HOME_PAGE_WIDGETS.title && (
							<>
								<Typography variant="h6" className={classes.subheader}>
									Additional Widgets
								</Typography>
								<div className={classes.tooltip}>
									<Tooltip
										type={TooltipTypes.Info}
										text={<>You may select any of the following widgets.</>}
									/>
								</div>
								<div className={classes.cards}>
									{formik.values.widgets &&
										formik.values.widgets.length > 0 &&
										formik.values.widgets.map(
											(
												widget: BaseHomeOptionsWidgetFragment,
												index: number
											) => {
												if (
													additionalWidgets.includes(widget.name as WidgetNames)
												) {
													return (
														<div key={index} className={classes.card}>
															<Widget widget={widget} index={index} />
														</div>
													);
												} else {
													return null;
												}
											}
										)}
								</div>
							</>
						)}
					</form>
				</div>
				<DraggableList<
					{ template: Templates },
					HomeOptionsWidgetInput | AboutOptionsWidgetInput
				>
					droppableId="widget-manager"
					listItems={sortedWidgets.map((widget) => ({
						draggable: widget.moveable,
						item: widget,
					}))}
					setListItems={handleSort}
					component={SortableWidget}
					template={
						(formik.values as HomeOptionsInput).template || Templates.Acadia
					}
					className={classes.content_sidebar}
					draggingClassName={classes.content_sidebar_dragging}
				/>
			</div>
			<ContentFooter>
				<SaveButton form="widget-manager-form" disabled={!formik.isValid} />
			</ContentFooter>
		</>
	);
};
