/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { ErrorResponse } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { ApolloError } from "@apollo/client";
import { SourceLocation, GraphQLError } from "graphql";
import { STATUS_CODES } from "http";
import LogRocket from "logrocket";

const DEFAULT_DISPLAYABLE_ERROR = "An unknown error has occurred.";

type OriginalError = ErrorResponse | ApolloError;

interface ErrorDetail {
	originalError?: OriginalError;
	code: string;
	errorMessage: string;
	path?: readonly (string | number)[];
	displayPath?: string;
	displayableError?: string;
	exceptionErrorMessage?: string;
	invalidArgs?: { [index: string]: string };
	invalidArgsDisplay?: string;
	misc?: { [index: string]: any };
	stacktrace?: string;
	locations?: readonly SourceLocation[];
	httpResponse?: any;
	httpResponseBody?: any;
	httpResponseCode?: number;
	httpResponseName?: string;
	httpRequest?: any;
}

export interface ExtractedError {
	displayableError: string;
	errors: ErrorDetail[];
}

/**
 * Receives an error from Apollo, extracts the important pieces from it, and
 * returns it in a more ergonomic structure.
 */
export function extractData(error: OriginalError): ExtractedError {
	const { graphQLErrors, networkError } = error;

	if (graphQLErrors && graphQLErrors.length > 0) {
		const errors = graphQLErrors.map((graphQlError) =>
			mapGraphQlError(graphQlError, error)
		);

		return {
			displayableError: buildDisplayableError(errors),
			errors: errors,
		};
	}

	if (networkError) {
		return {
			displayableError: DEFAULT_DISPLAYABLE_ERROR,
			errors: [
				{
					code: "NETWORK_ERROR",
					errorMessage: "Network error",
					originalError: error,
				},
			],
		};
	}

	return {
		displayableError: DEFAULT_DISPLAYABLE_ERROR,
		errors: [
			{
				code: "HTTP_ERROR",
				errorMessage: "HTTP Request Error",
				originalError: error,
			},
		],
	};
}

/**
 * When running the app in development, log all GraphQL errors to the console
 * in a compact but detailed manner.
 */
export function logErrorsInDev(error: OriginalError): void {
	if (process.env.NODE_ENV !== "development") return;
	const extractedError = extractData(error);
	extractedError.errors.forEach(
		({
			code,
			errorMessage,
			displayableError,
			displayPath,
			exceptionErrorMessage,
			invalidArgsDisplay,
			misc,
			stacktrace,
			locations,
			httpResponse,
			httpResponseCode,
			httpResponseName,
			httpResponseBody,
			httpRequest,
		}) => {
			console.groupCollapsed("%cGraphQL Error", "color:#A62700;");
			logItem("Code", code);
			logItem("Message", errorMessage);
			logItem("Displayable", displayableError);
			if (httpRequest && httpRequest.method) {
				logItem(
					"Request",
					`${httpRequest.method.toUpperCase()} ${httpRequest.path}`
				);
			}
			if (httpResponseCode && httpResponseName) {
				logItem("Response", `${httpResponseCode} ${httpResponseName}`);
			}
			logItem("Response", httpResponseBody);
			logItem("Path", displayPath);

			// exception details (collapsed)
			console.groupCollapsed("Exception Detail");
			logItem("Message", exceptionErrorMessage);
			logItem("Invalid Args", invalidArgsDisplay);
			logItem("Request", httpRequest);
			logItem("Response", httpResponse);
			logItem("Misc", misc);
			logItem("Stack", stacktrace);
			console.groupEnd();

			// location details (collapsed)
			if (locations && locations.length > 0) {
				console.groupCollapsed("Locations");
				locations.forEach((location) => {
					console.log(location);
				});
				console.groupEnd();
			}

			console.groupEnd();
		}
	);
}

/**
 * Proof of concept stub method for notifying an external bug tracking service
 * about any GraphQL errors.
 */
export function notifyBugTracker(error: OriginalError): void {
	const extractedError = extractData(error);

	extractedError.errors.forEach((error) => {
		if (!(error.displayPath === "logIn" && error.code === "UNAUTHENTICATED")) {
			LogRocket.captureException(
				{
					name: "GraphQL Error",
					message: error.errorMessage,
				},
				{
					extra: {
						displayableError: error.displayableError || "",
						path: error.displayPath || "",
						exceptionErrorMessage: error.exceptionErrorMessage || "",
						invalidArgs: error.invalidArgsDisplay || "",
						code: error.code,
						httpResponseCode: error.httpResponseCode || "",
						stacktrace: error.stacktrace || "",
					},
				}
			);
		}
	});
}

/**
 * Helper method to create a single displayable string from the list of errors
 */
function buildDisplayableError(errors: ErrorDetail[]): string {
	const errorsWithDisplayableError = errors.filter(
		(error) => !!error.displayableError
	);
	return errorsWithDisplayableError.length > 0
		? errorsWithDisplayableError
				.map((error) => addTrailingPunctuation(error.displayableError))
				.join(" ")
		: DEFAULT_DISPLAYABLE_ERROR;
}

/**
 * Helper method for building displayable messages
 */
function addTrailingPunctuation(error?: string): string {
	if (!error) return "";

	const lastChar = error.trim().substr(-1);
	return lastChar.match(/[.?!]/) ? error : `${error}.`;
}

/**
 * Helper method to pretty-print an item detail
 */
function logItem(label: string, value?: any): void {
	if (!value) return;

	console.log(`%c[${label}]`, "color: gray", value);
}

/**
 * Helper method to extract the important pieces from a GraphQL error
 */
export function mapGraphQlError(
	{ extensions, message, path, locations }: GraphQLError,
	originalError?: OriginalError
): ErrorDetail {
	// path
	const displayPath = path ? path.join(" > ") : "";

	// error message
	const errorMessages = [message];

	const mappedError = {
		code: "UNKNOWN_ERROR",
		errorMessage: message,
		path,
		displayPath,
		originalError,
		locations,
	};

	// if there are no extensions, don't process any further
	if (!extensions || !extensions.exception) return mappedError;

	// extract data from exception
	const {
		invalidArgs,
		errorMessage: exceptionErrorMessage,
		displayableError,
		stacktrace,
		request,
		response,
		...rest
	} = extensions.exception;

	// add exception message to top-level error message
	if (exceptionErrorMessage) {
		errorMessages.push(exceptionErrorMessage);
	}
	const errorMessage = errorMessages.map(addTrailingPunctuation).join(" ");

	// error code
	const code = (extensions.code as string) || "UNKNOWN_ERROR";

	// special handling of axios errors
	let httpRequest;
	let httpResponse;
	let httpResponseBody;
	let httpResponseCode;
	let httpResponseName;
	if (response && request) {
		// request info
		httpRequest = request;

		// response info
		httpResponse = response;
		httpResponseCode = response.status;
		httpResponseName = (STATUS_CODES[response.status] || "").toUpperCase();
		httpResponseBody = response.data;
	}

	// invalid args
	const invalidArgsDisplay = invalidArgs
		? Object.keys(invalidArgs)
				.map((key) => `${key}: ${invalidArgs[key]}`)
				.join(". ")
		: undefined;

	// misc other values in the exception
	const misc = rest && Object.keys(rest).length > 0 ? rest : undefined;

	return {
		...mappedError,
		code,
		errorMessage,
		displayableError,
		exceptionErrorMessage,
		invalidArgs,
		invalidArgsDisplay,
		misc,
		httpResponse,
		httpResponseBody,
		httpResponseCode,
		httpResponseName,
		httpRequest,
		stacktrace: stacktrace && stacktrace.join("\n"),
	};
}

// Helper to detect session expiry.
export function hasSessionExpired(error: ErrorResponse): boolean {
	return (error.networkError as ServerError | undefined)?.statusCode === 401;
}
