import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import { SpotifyIcon, AppleMusicIcon } from "../../icons";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Layout } from "../../components/Layout";
import { ProtectedRouteParams } from "../Routes";

const useStyles = makeStyles((theme: Theme) => ({
	h1: {
		marginTop: theme.spacing(4),
	},
	h2: {},
	message: {},
	icon: {
		height: theme.spacing(6.25),
		width: theme.spacing(6.25),
		margin: theme.spacing(0, 2, 0, 2),
		color: theme.palette.text.primary,
	},
}));

type ErrorProps = RouteComponentProps<
	Partial<ProtectedRouteParams<{ code: string }>>
>;
export const Error: React.FC<ErrorProps> = ({ match }) => {
	const classes = useStyles();

	const getErrorText = () => {
		switch (parseInt(match.params.code || "404")) {
			case 400:
				return "Bad Request";
			case 401:
				return "Unauthorized";
			case 403:
				return "Forbidden";
			case 404:
				return "Not Found";
			case 409:
				return "Conflict";
			case 500:
				return "Internal Server Error";
			default:
				return "";
		}
	};

	return (
		<Layout portalId={match.params.portalId ?? ""}>
			<Typography variant="h1" gutterBottom={true} className={classes.h1}>
				{match.params.code || "404"} {getErrorText()}
			</Typography>
			<Typography variant="h2" gutterBottom={true} className={classes.h2}>
				Looks like something has gone wrong.
			</Typography>
			<div className={classes.message}>
				<p>Please try to log out and log in again.</p>

				<p>If your problem continues, please contact the digital team.</p>
			</div>

			<hr />

			<Typography variant="h3" gutterBottom={true} className={classes.h2}>
				Trivia
			</Typography>
			<div className={classes.message}>
				<p>
					Lakestreet was given its name by the design team when originally
					planning the project.
				</p>

				<p>
					During the initial concept stage, the team listened to a LOT of a band
					they found on YouTube called Lake Street Dive.
				</p>

				<p>You can hear them here:</p>

				<a href="https://open.spotify.com/artist/3nuc29fYGlQbIrwh4yrNWd?si=dRxuhkwuSQKKQKTTh4R7uw">
					<SpotifyIcon className={classes.icon} />
				</a>
				<a href="https://music.apple.com/us/artist/lake-street-dive/212608674">
					<AppleMusicIcon className={classes.icon} />
				</a>
				<a href="https://www.youtube.com/user/LakeStreetDive">
					<YouTubeIcon className={classes.icon} />
				</a>
			</div>
		</Layout>
	);
};
