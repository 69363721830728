import React from "react";

import { makeStyles } from "@mui/styles";
import { Dialog, DialogContent, CircularProgress, Theme } from "@mui/material";

type ProgressProps = {
	open: boolean;
	message: string;
};

const useStyles = makeStyles((theme: Theme) => ({
	progress: {
		marginRight: 8,
	},
	content: {
		display: "flex",
		alignItems: "center",
		marginBottom: theme.spacing(1),
	},
}));

export const ProgressDialog: React.FC<ProgressProps> = ({ open, message }) => {
	const classes = useStyles();
	return (
		<Dialog open={open} disableEscapeKeyDown={true}>
			<DialogContent className={classes.content}>
				<CircularProgress className={classes.progress} />
				{message}
			</DialogContent>
		</Dialog>
	);
};
