import React from "react";
import { Formik, Form, Field } from "formik";

import { Layout } from "../../components/Layout";
import {
	useOfficeHoursMetadataQuery,
	useEditOfficeMetadataMutation,
	OfficeHoursInput,
	DayHoursInput,
	DayHoursFragment,
	OfficeHoursMetadataDocument,
} from "../../types/graphql-types";
import routes, { ProtectedRouteParams } from "../Routes";

import { makeStyles } from "@mui/styles";
import { Grid, Theme } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { DayOfWeek } from "./DayOfWeek";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { mapGraphQlError } from "../../utils/errorHandler";
// import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
// import ContentFooter from "../../components/ContentFooter";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
		borderBottom: `2px solid ${theme.color.grey.light}`,
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	row: {
		paddingLeft: "0.25rem",
		paddingRight: "0.25rem",
	},
	select: {
		width: "100%",
		paddingBottom: "0.25rem",
	},
	dayTitle: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	// this is to disable FMS editing per LS #567
	fieldset: {
		border: "none",
		pointerEvents: "none",
		opacity: 0.5,
	},
}));

// An ordered list of the day names; we can't use Object.keys since that's not guaranteed to preserve key ordering.
const DAYS: Array<keyof OfficeHoursInput> = [
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
];

export const OfficeHours: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useOfficeHoursMetadataQuery({
		variables: {
			portalId: portalId,
		},
	});
	const [
		editOfficeMetadataMutation,
		editOfficeMutationResponse,
	] = useEditOfficeMetadataMutation();

	const officeHoursResult = data?.officeMetadata?.officeHours;

	// Strip out __typename fields from the response for initial values
	// Memoize the initial value to optimize re-renders
	const initialValues: OfficeHoursInput = React.useMemo(() => {
		const stripDayTypename = (
			day?: DayHoursFragment | null
		): DayHoursInput | null =>
			day
				? {
						open: day.open,
						close: day.close,
				  }
				: null;

		return {
			monday: stripDayTypename(officeHoursResult?.monday),
			tuesday: stripDayTypename(officeHoursResult?.tuesday),
			wednesday: stripDayTypename(officeHoursResult?.wednesday),
			thursday: stripDayTypename(officeHoursResult?.thursday),
			friday: stripDayTypename(officeHoursResult?.friday),
			saturday: stripDayTypename(officeHoursResult?.saturday),
			sunday: stripDayTypename(officeHoursResult?.sunday),
		};
	}, [officeHoursResult]);

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Office Hours!",
			});
		}
	}, [error, setSnackbarProps]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || editOfficeMutationResponse.loading,
		});
	}, [loading, editOfficeMutationResponse.loading, setLoadingIndicatorProps]);

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title={routes.OFFICE_HOURS.title}
				// button={<SaveButton form="office-hours-form" />}
				tooltipText="Office hours are synced from GEMS and cannot be edited here."
			/>
			<Formik<OfficeHoursInput>
				initialValues={initialValues}
				onSubmit={async (officeHours: OfficeHoursInput) => {
					const result = await editOfficeMetadataMutation({
						variables: {
							portalId,
							officeMetadata: {
								officeHours,
							},
						},
						refetchQueries: [
							{
								query: OfficeHoursMetadataDocument,
								variables: {
									portalId: portalId,
								},
							},
						],
						awaitRefetchQueries: true,
					});
					if (result?.data) {
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: true,
							message: "Office Hours Saved!",
						});
					} else {
						const error =
							result?.errors?.[0] && mapGraphQlError(result.errors[0]);
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: false,
							message: error?.displayableError || "Office Hours Not Saved!",
						});
					}
				}}
				enableReinitialize={true}
			>
				<Form id="office-hours-form">
					<fieldset disabled className={classes.fieldset}>
						<div className={classes.root}>
							<Grid container={true}>
								{DAYS.map((dayName: string) => (
									<Field key={dayName} name={dayName} component={DayOfWeek} />
								))}
							</Grid>
						</div>
					</fieldset>
				</Form>
			</Formik>
			{/* <ContentFooter>
				<SaveButton form="office-hours-form" />
			</ContentFooter> */}
		</Layout>
	);
};
