import { makeStyles } from "@mui/styles";
import {
	Dialog,
	DialogContent,
	Theme,
	TextField,
	Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import {
	createFeedbackPayload,
	postFeedbackPayload,
	Feedback,
} from "./feedbackUtils";
import SaveButton from "../SaveButton";
import { useGlobalSnackbar } from "../GlobalSnackbar";

type FeedbackModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	closeMenu: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
	input_group: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		margin: "48px 16px",
	},
	input: {
		margin: "32px 0",
	},
}));

export const FeedbackModal = React.forwardRef<
	HTMLDivElement,
	FeedbackModalProps
>(({ open, setOpen, closeMenu }, ref) => {
	const classes = useStyles();
	const { setSnackbarProps } = useGlobalSnackbar();
	closeMenu();

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			maxWidth="lg"
			fullWidth
			ref={ref}
		>
			<DialogContent>
				<Formik<Feedback>
					initialValues={{
						feedback_title: "",
						feedback_description: "",
					}}
					validationSchema={Yup.object().shape<Feedback>({
						feedback_title: Yup.string().required(),
						feedback_description: Yup.string().required(),
					})}
					onSubmit={({ feedback_title, feedback_description }) => {
						postFeedbackPayload(
							createFeedbackPayload({
								feedback_title,
								feedback_description,
							})
						);
						setOpen(!open);
						setSnackbarProps({
							autoHideDuration: 3000,
							open: true,
							success: true,
							message:
								"Your feedback has been sent to the Lakestreet development team!",
						});
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form id="feedback-form">
							<Typography variant="h6">
								Send feedback to the development team
							</Typography>
							<div className={classes.input_group}>
								<Field name="feedback_title">
									{({ field }: FieldProps) => (
										<TextField
											label="Subject"
											className={classes.input}
											error={!!errors.feedback_title}
											{...field}
										/>
									)}
								</Field>
								<Field name="feedback_description">
									{({ field }: FieldProps) => (
										<TextField
											placeholder="Describe your feedback in detail"
											multiline
											rows={4}
											maxRows={8}
											variant="outlined"
											className={classes.input}
											error={!!errors.feedback_description}
											{...field}
										/>
									)}
								</Field>
							</div>
							<SaveButton
								form="feedback-form"
								disabled={isSubmitting}
								label="submit feedback"
							/>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	);
});
