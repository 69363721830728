import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormHelperText,
	InputLabel,
	Tab,
	Theme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ExpandLess, Add } from "@mui/icons-material";
import { FieldProps, getIn } from "formik";
import clsx from "clsx";
import cloudinaryUtil from "../../utils/cloudinaryUtil";
import DEFAULT_IMAGE from "../../assets/images/placeholder-image.png";
import {
	GetImagesByTagDocument,
	GetImagesByTagQuery,
	GetImagesByTagQueryVariables,
	useGetImagesByTagQuery,
	useUploadImageMutation,
} from "../../types/graphql-types";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";
import { ImageGrid } from "./ImageGrid";
import { Maybe } from "graphql/jsutils/Maybe";
import AnimateHeight from "../../components/AnimateHeight";
import { ImageUpload } from "../../components/ImageUpload";
import { mapGraphQlError } from "../../utils/errorHandler";
import { TextField } from "../../components/TextField";

const useStyles = makeStyles((theme: Theme) => ({
	formControlRoot: {
		marginBottom: "32px !important",
	},
	uploadButtonContainer: {
		display: "flex",
		justifyContent: "flex-end",
		marginBottom: theme.spacing(2),
	},
	imageUploadContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		maxWidth: 240,
		margin: theme.spacing(0, "auto", 2, "auto"),
	},
	altText: {
		margin: "16px 0px !important",
		height: "60px",
	},
	label: {
		display: "inline-block",
		marginBottom: theme.spacing(8),
	},
	inputRoot: {
		maxWidth: 500,
		margin: theme.spacing(2, "auto"),
	},
	inputDisabled: {},
	inputError: {},
	image: {
		width: "100%",
		height: "auto",
	},
	imageDisabled: {
		filter: "grayscale(100%) opacity(50%)",
	},
	imageFallback: {
		width: "100%",
		height: "auto",
	},
	buttonRoot: {},
	buttonDisabled: {},
	buttonError: {},
}));

interface HeroImageFieldProps extends FieldProps<string> {
	portalId: string;
	label?: string;
	helperText?: string;
	disabled?: boolean;
}

enum TabValues {
	Gallery = "gallery",
	Upload = "upload",
}

export const HeroImageField: React.FC<HeroImageFieldProps> = ({
	portalId,
	form,
	field,
	label,
	helperText,
	disabled,
}) => {
	const classes = useStyles();
	const touched = getIn(form.touched, field.name);
	const dirty = getIn(form.initialValues, field.name) !== field.value;
	const error = getIn(form.errors, field.name);
	const [selectedImage, setSelectedImage] = useState({
		publicId: field.value,
		altText: form.values.altText,
	});
	const [open, setOpen] = useState(false);
	const [expanded, setExpanded] = React.useState(false);
	const [selectedTab, setSelectedTab] = useState(TabValues.Gallery);
	const [file, setFile] = useState<File | null>(null);
	const [altText, setAltText] = useState("");
	const [altTextMeta, setAltTextMeta] = React.useState({
		touched: false,
		error: "",
	});

	const {
		data: galleryData,
		loading: galleryLoading,
		error: galleryError,
	} = useGetImagesByTagQuery({
		variables: {
			tag: "hero",
		},
	});
	const [uploadImageMutation] = useUploadImageMutation();

	const handleUploadImage = async () => {
		if (!altText) {
			setAltTextMeta({ error: "Required", touched: true });
			return;
		} else {
			setAltTextMeta({ error: "", touched: false });
		}
		if (portalId && file) {
			const tag = "hero";
			const heroId =
				`${tag}_` +
				Math.random().toString(36).substring(2, 15) +
				Math.random().toString(36).substring(2, 15);
			const { publicId, tags } = cloudinaryUtil.customHeroImage(
				portalId,
				heroId,
				tag
			);
			const result = await uploadImageMutation({
				variables: {
					file,
					publicId,
					tags,
					altText,
				},
				update: (proxy, { data }) => {
					// Read the data from our cache for this query.
					const readQueryData = proxy.readQuery<
						GetImagesByTagQuery,
						GetImagesByTagQueryVariables
					>({
						query: GetImagesByTagDocument,
						variables: {
							tag: `${portalId}_${tag}`,
						},
					});
					// Write our data back to the cache with the new image in it
					if (readQueryData?.getImagesByTag && data) {
						proxy.writeQuery<GetImagesByTagQuery, GetImagesByTagQueryVariables>(
							{
								query: GetImagesByTagDocument,
								variables: {
									tag: `${portalId}_${tag}`,
								},
								data: {
									__typename: "Query",
									getImagesByTag: [
										data.uploadImage,
										...readQueryData.getImagesByTag,
									],
								},
							}
						);
					}
				},
			});
			if (result) {
				setFile(null);
				setAltText("");
				setExpanded(false);
			}
			if (result?.errors) {
				result.errors[0] && mapGraphQlError(result.errors[0]);
			}
		}
	};

	const {
		data: customData,
		loading: customLoading,
		error: customError,
	} = useGetImagesByTagQuery({
		variables: {
			tag: `${portalId}_hero`,
		},
	});

	const defaultTab = useMemo(() => {
		if (
			customData?.getImagesByTag?.some(
				({ publicId }) => publicId === field.value
			)
		) {
			return TabValues.Upload;
		} else {
			return TabValues.Gallery;
		}
	}, [field.value, customData]);

	useEffect(() => {
		setSelectedTab(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		setSelectedImage({ publicId: field.value, altText: form.values.altText });
	}, [field.value, form.values.altText]);

	const toggleExpanded = React.useCallback(() => {
		setExpanded(!expanded);
	}, [expanded, setExpanded]);

	return (
		<>
			<FormControl
				className={classes.formControlRoot}
				error={(touched || dirty) && !!error}
				fullWidth
			>
				{label && (
					<InputLabel className={classes.label} shrink error={error}>
						{label}
					</InputLabel>
				)}
				<div
					className={clsx({
						[classes.inputRoot]: true,
						[classes.inputDisabled]: disabled,
						[classes.inputError]: !!error,
					})}
				>
					<object
						key={field.value}
						className={clsx({
							[classes.image]: true,
							[classes.imageDisabled]: disabled,
						})}
						type="image/png"
						data={cloudinaryUtil.imageSrc({ publicId: field.value })}
					>
						<img
							className={classes.imageFallback}
							src={DEFAULT_IMAGE}
							alt={form.values.altText}
						/>
					</object>
					<Button
						className={clsx({
							[classes.buttonRoot]: true,
							[classes.buttonDisabled]: disabled,
							[classes.buttonError]: !!error,
						})}
						sx={{ textTransform: "none" }}
						variant="contained"
						color="primary"
						disabled={disabled}
						fullWidth
						onClick={() => setOpen(true)}
					>
						Set Hero Image
					</Button>
				</div>
				{error ||
					(helperText && (
						<FormHelperText>{error || helperText}</FormHelperText>
					))}
			</FormControl>
			<Dialog
				open={open}
				maxWidth="md"
				onClose={(e, reason) => reason !== "backdropClick" && setOpen(false)}
				fullWidth
			>
				<DialogTitle>Select or Upload a Hero Image</DialogTitle>
				<DialogContent>
					<TabContext value={selectedTab}>
						<TabList
							indicatorColor="secondary"
							textColor="secondary"
							onChange={(e, newTab) => setSelectedTab(newTab)}
						>
							<Tab value={TabValues.Gallery} label="Gallery" />
							<Tab value={TabValues.Upload} label="Upload" />
						</TabList>
						<TabPanel value={TabValues.Gallery}>
							{galleryLoading ? (
								<CircularProgress />
							) : galleryError ? (
								<Tooltip
									type={TooltipTypes.Critical}
									text="An error occurred retrieving the gallery of hero images. Please try again later."
								/>
							) : galleryData?.getImagesByTag ? (
								<ImageGrid
									images={galleryData.getImagesByTag}
									selectedImage={selectedImage.publicId}
									onSelect={(publicId: string, altText?: Maybe<string>) =>
										setSelectedImage({ publicId, altText })
									}
									portalId={portalId}
								/>
							) : null}
						</TabPanel>
						<TabPanel value={TabValues.Upload}>
							{customLoading ? (
								<CircularProgress />
							) : customError ? (
								<Tooltip
									type={TooltipTypes.Critical}
									text="An error occurred retrieving your custom hero images. Please try again later."
								/>
							) : customData?.getImagesByTag ? (
								<>
									<div className={classes.uploadButtonContainer}>
										<Button
											sx={{ textTransform: "none", color: "black" }}
											onClick={toggleExpanded}
											startIcon={expanded ? <ExpandLess /> : <Add />}
										>
											<span>{expanded ? "Cancel" : "Upload image"}</span>
										</Button>
									</div>
									<AnimateHeight height={expanded ? "auto" : 0} animateOpacity>
										<div className={classes.imageUploadContainer}>
											<ImageUpload
												id="upload"
												onChange={(file) => setFile(file)}
												altText={""}
											/>
											<TextField
												className={classes.altText}
												label="Default Alt Text"
												value={altText}
												onChange={(e) => setAltText(e.target.value)}
												onBlur={() => {
													setAltTextMeta({
														error: !altText ? "Required" : "",
														touched: true,
													});
												}}
												helperText={
													altTextMeta.touched ? altTextMeta.error : undefined
												}
												helperTextAlignment="left"
												error={altTextMeta.error}
											/>
											<Button
												sx={{
													backgroundColor: "#25B84A",
													color: "white",
													position: "unset",
													textTransform: "lowercase",
													"&:hover": {
														backgroundColor: "#258E4A",
													},
												}}
												onClick={handleUploadImage}
												fullWidth
											>
												Upload
											</Button>
										</div>
									</AnimateHeight>
									<ImageGrid
										images={customData.getImagesByTag}
										selectedImage={selectedImage.publicId}
										onSelect={(publicId: string, altText?: Maybe<string>) =>
											setSelectedImage({ publicId, altText })
										}
										allowDelete
										portalId={portalId}
									/>
								</>
							) : null}
						</TabPanel>
					</TabContext>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							form.setFieldValue(field.name, selectedImage.publicId);
							form.setFieldValue("altText", selectedImage.altText);
							setOpen(false);
						}}
						sx={{
							textTransform: "none",
						}}
					>
						okay
					</Button>
					<Button
						variant="contained"
						onClick={() => {
							setSelectedImage({
								publicId: field.value,
								altText: form.values.altText,
							});
							setSelectedTab(defaultTab);
							setOpen(false);
						}}
						sx={{
							textTransform: "none",
							backgroundColor: "#d5d5d5",
							color: "black",
							"&:hover": {
								backgroundColor: "#c5c5c5",
							},
						}}
					>
						cancel
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
