import { makeStyles } from "@mui/styles";
import { Theme, Grid } from "@mui/material";
import React from "react";
import { Tooltip, TooltipTypes } from "../Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
	content_title: {
		fontSize: theme.typography.fontSizeContentTitle,
		fontWeight: theme.typography.fontWeightBold,
	},
	subtext: {
		marginBottom: "24px !important",
		fontSize: "0.875rem",
	},
}));

interface ContentHeaderProps {
	button?: JSX.Element;
	title: string;
	tooltipText?: string;
	subtext?: string;
}
const ContentHeader: React.FC<ContentHeaderProps> = ({
	button,
	title,
	tooltipText,
	subtext,
}) => {
	const classes = useStyles();
	return (
		<Grid
			item
			container
			justifyContent="space-between"
			spacing={subtext ? 1 : 3}
			sx={{
				marginBottom: "32px",
			}}
		>
			<Grid item className={classes.content_title}>
				{title}
			</Grid>
			<Grid item>{button}</Grid>

			{subtext && (
				<Grid item xs={12} className={classes.subtext}>
					<em>{subtext}</em>
				</Grid>
			)}

			{tooltipText && (
				<Grid item xs={12}>
					<Tooltip
						type={TooltipTypes.Info}
						text={<React.Fragment>{tooltipText}</React.Fragment>}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default ContentHeader;
