import React from "react";
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	Tabs,
	Tab,
} from "@mui/material";
import {
	HomeOptionsInput,
	useHomeOptionsQuery,
	WelcomeBannerWidgetFragment,
	HeroImageTypes,
} from "../../../types/graphql-types";
import UploadedHeroGallery from "./UploadedHeroGallery";
import { TabPanel } from "./TabPanel";
import StockHeroGallery from "./StockHeroGallery";
import { FormikProps } from "formik";
import { useGlobalSnackbar } from "../../../components/GlobalSnackbar";

const STANDARD_IMAGE_ONLY_TEMPLATES = ["acadia", "denali"];
const UPLOAD_STANDARD_IMAGE_TEMPLATES = [
	"biscayne",
	"atlanta",
	"chicago",
	"dallas",
];
const DOCTOR_UPLOAD_IMAGE_TEMPLATES = ["boston", "everglades"];

interface ImageModalProps {
	open: boolean;
	portalId: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	formikBag: FormikProps<HomeOptionsInput>;
	welcomeBannerWidgetIndex: number;
}

export const ImageModal: React.FC<ImageModalProps> = React.forwardRef<
	HTMLDivElement,
	ImageModalProps
>(({ open, portalId, setOpen, formikBag, welcomeBannerWidgetIndex }, ref) => {
	const { data: homeOptions } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});
	const [heroImageOldValue] = React.useState(
		formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage
	);
	const [altTextError, setAltTextError] = React.useState(false);

	const { setSnackbarProps } = useGlobalSnackbar();

	React.useEffect(() => {
		if (altTextError) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Alt text is required.",
			});
		}
	}, [altTextError, setSnackbarProps]);

	const determineHeroImageType = (tabValue: number) => {
		if (DOCTOR_UPLOAD_IMAGE_TEMPLATES.includes(formikBag.values.template)) {
			return tabValue === 0 ? HeroImageTypes.Doctor : HeroImageTypes.Upload;
		} else if (
			UPLOAD_STANDARD_IMAGE_TEMPLATES.includes(formikBag.values.template)
		) {
			return tabValue === 0 ? HeroImageTypes.Upload : HeroImageTypes.Standard;
		} else {
			return HeroImageTypes.Standard;
		}
	};

	const handleChange = (_event: React.ChangeEvent, newValue: number) => {
		formikBag.setFieldValue(
			`widgets[${welcomeBannerWidgetIndex}].heroImage.type`,
			determineHeroImageType(newValue)
		);
	};
	const savedWelcomeBanner = homeOptions?.homeOptions?.widgets[
		welcomeBannerWidgetIndex
	] as WelcomeBannerWidgetFragment;

	const heroImageType =
		formikBag?.values?.widgets[welcomeBannerWidgetIndex]?.heroImage?.type;
	const savedHeroImage = savedWelcomeBanner.heroImage?.[`${heroImageType}`];
	const heroImageValue =
		formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage?.[
			`${heroImageType}`
		];

	const selectedTab = React.useMemo(() => {
		if (DOCTOR_UPLOAD_IMAGE_TEMPLATES.includes(formikBag.values.template)) {
			switch (
				formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage?.type
			) {
				case HeroImageTypes.Doctor:
					return 0;
				case HeroImageTypes.Upload:
					return 1;
				default:
					formikBag.setFieldValue(
						`widgets[${welcomeBannerWidgetIndex}].heroImage.type`,
						HeroImageTypes.Doctor
					);
					return 0;
			}
		} else if (
			UPLOAD_STANDARD_IMAGE_TEMPLATES.includes(formikBag.values.template)
		) {
			switch (
				formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage?.type
			) {
				case HeroImageTypes.Upload:
					return 0;
				case HeroImageTypes.Standard:
					return 1;
				default:
					formikBag.setFieldValue(
						`widgets[${welcomeBannerWidgetIndex}].heroImage.type`,
						HeroImageTypes.Upload
					);
					return 0;
			}
		} else if (
			formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage?.type !==
			HeroImageTypes.Standard
		) {
			formikBag.setFieldValue(
				`widgets[${welcomeBannerWidgetIndex}].heroImage.type`,
				HeroImageTypes.Standard
			);
			return 0;
		} else {
			return 0;
		}
	}, [formikBag, welcomeBannerWidgetIndex]);

	return (
		<Dialog
			open={open}
			maxWidth="lg"
			fullWidth
			ref={ref}
			onClose={(e, reason) => reason !== "backdropClick" && setOpen(false)}
		>
			<DialogContent>
				{DOCTOR_UPLOAD_IMAGE_TEMPLATES.includes(formikBag.values.template) ? (
					<>
						<Tabs
							value={selectedTab}
							onChange={handleChange}
							aria-label="image selection tabs"
						>
							<Tab value={0} label="Featured Doctor" />
							<Tab value={1} label="Custom Image" />
						</Tabs>
						<TabPanel value={selectedTab} index={0}>
							<UploadedHeroGallery
								portalId={portalId}
								formikBag={formikBag}
								welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
								altTextError={altTextError}
								setAltTextError={setAltTextError}
								heroImageOption="doctor"
							/>
						</TabPanel>
						<TabPanel value={selectedTab} index={1}>
							<UploadedHeroGallery
								portalId={portalId}
								formikBag={formikBag}
								welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
								altTextError={altTextError}
								setAltTextError={setAltTextError}
								heroImageOption="upload"
							/>
						</TabPanel>
					</>
				) : UPLOAD_STANDARD_IMAGE_TEMPLATES.includes(
						formikBag.values.template
				  ) ? (
					<>
						<Tabs
							value={selectedTab}
							onChange={handleChange}
							aria-label="image selection tabs"
						>
							<Tab value={0} label="Upload" />
							<Tab value={1} label="Gallery" />
						</Tabs>
						<TabPanel value={selectedTab} index={0}>
							<UploadedHeroGallery
								portalId={portalId}
								formikBag={formikBag}
								welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
								altTextError={altTextError}
								setAltTextError={setAltTextError}
								heroImageOption="upload"
							/>
						</TabPanel>
						<TabPanel value={selectedTab} index={1}>
							<StockHeroGallery
								formikBag={formikBag}
								welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
							/>
						</TabPanel>
					</>
				) : STANDARD_IMAGE_ONLY_TEMPLATES.includes(
						formikBag.values.template
				  ) ? (
					<StockHeroGallery
						formikBag={formikBag}
						welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
					/>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button
					type="submit"
					disabled={
						savedHeroImage?.altText === heroImageValue?.altText &&
						savedHeroImage?.publicId === heroImageValue?.publicId &&
						savedHeroImage?.text === heroImageValue?.text &&
						savedWelcomeBanner.heroImage?.type ===
							formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage?.type
					}
					sx={{
						textTransform: "none",
						padding: "2px 24px",
						color: "white",
						backgroundColor: "#25B84A",
						"&:hover": {
							backgroundColor: "#94D264",
						},
					}}
					onClick={() => {
						if (
							!heroImageValue?.altText &&
							(heroImageType === HeroImageTypes.Upload ||
								heroImageType === HeroImageTypes.Doctor)
						) {
							setAltTextError(true);
						} else {
							formikBag.submitForm();
							setOpen(false);
						}
					}}
				>
					save
				</Button>
				<Button
					sx={{
						textTransform: "none",
						padding: "2px 24px",
						color: "white",
						backgroundColor: "#EF4A25",
						"&:hover": {
							backgroundColor: "#FFDBCC",
						},
					}}
					onClick={() => {
						formikBag.setFieldValue(
							`widgets[${welcomeBannerWidgetIndex}].heroImage`,
							heroImageOldValue
						);
						setOpen(false);
					}}
				>
					cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
});
