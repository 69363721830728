import React from "react";
import { FieldProps, getIn } from "formik";
import { DateField, DateFieldProps } from "./DateField";

type DateFieldAdapterProps = FieldProps<string> & DateFieldProps;

export const DateFieldAdapter: React.FC<DateFieldAdapterProps> = ({
	form,
	field,
	...DateFieldProps
}) => {
	const touched = getIn(form.touched, field.name);
	const dirty = getIn(form.initialValues, field.name) !== field.value;
	const error = getIn(form.errors, field.name);

	return (
		<DateField
			{...DateFieldProps}
			{...field}
			value={field.value || null}
			onChange={(value: string) => form.setFieldValue(field.name, value)}
			error={touched || dirty ? error : undefined}
		/>
	);
};
