import { Grid } from "@mui/material";
import { FormikProps } from "formik";
import React from "react";
import { HomeOptionsInput, HeroImageTypes } from "../../../types/graphql-types";
import HeroImageSidebar from "./HeroImageSidebar";
import { ImageGrid } from "./ImageGrid";

interface StockHeroGalleryProps {
	formikBag: FormikProps<HomeOptionsInput>;
	welcomeBannerWidgetIndex: number;
}

const StockHeroGallery: React.FC<StockHeroGalleryProps> = ({
	formikBag,
	welcomeBannerWidgetIndex,
}) => {
	return (
		<Grid container>
			<HeroImageSidebar
				formikBag={formikBag}
				tag="hero"
				heroImageType={HeroImageTypes.Standard}
				welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
			/>
			<ImageGrid
				formikBag={formikBag}
				tag="hero"
				heroImageType={HeroImageTypes.Standard}
				welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
			/>
		</Grid>
	);
};

export default StockHeroGallery;
