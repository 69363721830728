import { OfficeSearchResult } from "../types/graphql-types";

export type RecentOffice = {
	officeName: string;
	portalId: string;
	timestamp: number;
};

/** Retrieves the list of recent offices from localStorage */
export const getOffices = (): RecentOffice[] => {
	let storageArray: RecentOffice[] = [];
	const recentOfficesString = localStorage.getItem("recent-offices");

	if (recentOfficesString === null) {
		return storageArray;
	}

	storageArray = JSON.parse(recentOfficesString);

	return storageArray.sort((a: RecentOffice, b: RecentOffice) => {
		return b.timestamp - a.timestamp;
	});
};

/** Adds an office to the list of recent offices */
export const setOffices = (newOffice: OfficeSearchResult) => {
	const currentStorage = localStorage.getItem("recent-offices");
	let officeArray: RecentOffice[] = [];

	if (currentStorage !== null) {
		officeArray = JSON.parse(currentStorage);
	}

	let exists = false;

	officeArray.map((office: RecentOffice) => {
		if (office.portalId === newOffice.portalId) {
			exists = true;
			office.timestamp = new Date().getTime();
		}

		return office;
	});

	if (!exists) {
		while (officeArray.length >= 5) {
			// Need to splice off the office visited longest ago

			officeArray = officeArray.sort((a: RecentOffice, b: RecentOffice) => {
				return b.timestamp - a.timestamp;
			});

			officeArray.splice(officeArray.length - 1, 1);
		}

		officeArray.push({
			officeName: newOffice.officeName,
			portalId: newOffice.portalId,
			timestamp: new Date().getTime(),
		});
	}

	localStorage.setItem("recent-offices", JSON.stringify(officeArray));
};
