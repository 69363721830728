import React, { ChangeEvent } from "react";
import { FormikProps } from "formik";
import clsx from "clsx";
import { useDebounce } from "use-debounce";
import { DoctorAssignmentInput } from "./DoctorAssignment";
import {
	Doctor,
	DoctorsDocument,
	DoctorsQueryHookResult,
	useDoctorSearchQuery,
	DeleteDoctorAssignmentMutationFn,
	AssignDoctorMutationFn,
	ArrangeDoctorsMutationFn,
	DoctorSearchFragment,
	useHomeOptionsQuery,
	useSetHomeOptionsMutation,
	HomeOptionsDocument,
	HomeOptionsInput,
	HeroImageTypes,
	HomeOptionsWidgetInput,
} from "../../types/graphql-types";
import { makeStyles } from "@mui/styles";
import { Theme, TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import routes from "../Routes";
import { formatDoctorName } from "../../utils/formatName";
import removeTypename from "../../utils/removeTypename";
import { DraggableList } from "../../components/DraggableList";
import { SortableDoctor } from "./SortableDoctor";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { mapGraphQlError } from "../../utils/errorHandler";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";

const useStyles = makeStyles((theme: Theme) => ({
	content_title: {
		display: "inline-block",
		flex: 1,
		fontSize: theme.typography.fontSizeContentTitle,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: theme.spacing(5),
	},
	search: {
		alignItems: "center",
		backgroundColor: theme.color.grey.light,
		boxShadow: theme.shadows[1],
		borderRadius: 4,
		display: "flex",
		padding: theme.spacing(0),
		marginBottom: theme.spacing(4),
		position: "relative",
	},
	search_input: {
		width: "100%",
	},
	adornment: {
		margin: theme.spacing(0, 1),
	},
	search_icon: {
		color: theme.color.grey.main,
		cursor: "pointer",
	},
	dropDown: {
		borderRadius: theme.shape.borderRadius,
		position: "absolute",
		top: 41,
		zIndex: 200,
		boxSizing: "border-box",
		maxWidth: theme.centralBlockMaxWidth,
		width: "100%",
		minWidth: "100%",
		backgroundColor: theme.palette.grey[100],
		boxShadow: theme.shadows[1],
		listStyleType: "none",
		padding: theme.spacing(0, 3),
		maxHeight: 0,
		overflow: "hidden",
		transition: `max-height 0.5s ${theme.transitions.easing.easeInOut}`,
		fontSize: "0.875rem",
	},
	dropDownOpen: {
		// 1-row height: 41; max # results: 10; max height = row height * results + some padding for safety
		maxHeight: 420,
	},
	row: {
		alignItems: "center",
		borderBottom: `1px solid ${theme.color.grey.dark}`,
		cursor: "pointer",
		display: "flex",
		padding: theme.spacing(1),

		"&:last-child": {
			border: "none",
		},

		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	doctor_name: {
		flex: 1,
	},
	doctor_npi: {
		flex: 1,
	},
	doctor_add: {
		lineHeight: 0,
	},

	flex_col: {
		flex: 1,
	},
	drag_icon: {
		color: theme.color.grey.dark,
		cursor: "move",
		marginRight: theme.spacing(2),
	},
	remove_icon: {
		color: theme.color.grey.dark,
		cursor: "pointer",
		marginLeft: theme.spacing(2),
	},
}));

enum FeaturedDoctorTemplates {
	Boston = "boston",
	Everglades = "everglades",
}

interface DoctorAssignmentBodyProps extends FormikProps<DoctorAssignmentInput> {
	portalId: string;
	doctorAssignment: DoctorsQueryHookResult;
	assignDoctor: AssignDoctorMutationFn;
	deleteDoctor: DeleteDoctorAssignmentMutationFn;
	arrangeDoctors: ArrangeDoctorsMutationFn;
}

export const DoctorAssignmentBody: React.FC<DoctorAssignmentBodyProps> = ({
	portalId,
	doctorAssignment,
	assignDoctor,
	deleteDoctor,
	arrangeDoctors,
	...formik
}) => {
	const classes = useStyles();
	const { setSnackbarProps } = useGlobalSnackbar();
	const [searchQuery, setSearchQuery] = React.useState<string>("");
	const [debouncedSearchQuery] = useDebounce(searchQuery, 200);
	const [dropdownOpen, setDropdownOpen] = React.useState(false);
	const [setHomeOptionsMutation] = useSetHomeOptionsMutation();

	const { data, error } = useDoctorSearchQuery({
		variables: {
			query: debouncedSearchQuery,
		},
		skip: !debouncedSearchQuery,
	});

	const { data: bannerData } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});
	const homeOptions = bannerData?.homeOptions;
	//Get the index number for the welcome banner
	const bannerIndex =
		homeOptions?.widgets.findIndex(({ name }) => name === "welcome_banner") ??
		-1;
	const savedBanner: HomeOptionsWidgetInput | undefined = React.useMemo(
		() => removeTypename(homeOptions?.widgets[bannerIndex]),
		[bannerIndex, homeOptions?.widgets]
	);

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Doctors!",
			});
		}
		if (doctorAssignment.error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "No Doctors found!",
			});
		}
	}, [error, setSnackbarProps, doctorAssignment.error]);

	const handleAssignDoctor = async (doctor: DoctorSearchFragment) => {
		const result = await assignDoctor({
			variables: { portalId, npi: doctor.npi },
			refetchQueries: [
				{
					query: DoctorsDocument,
					variables: {
						portalId: portalId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
		if (result?.data?.assignDoctor) {
			setSearchQuery("");
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: true,
				message: "Doctor Assigned!",
			});
		} else {
			const error = result?.errors?.[0] && mapGraphQlError(result.errors[0]);
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: error?.displayableError || "Doctor Not Assigned!",
			});
		}
	};

	const handleDeleteDoctorAssignmentMutation = async (npi: string) => {
		const result = await deleteDoctor({
			variables: { portalId, npi },
			refetchQueries: [
				{
					query: DoctorsDocument,
					variables: {
						portalId: portalId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
		if (result?.data?.deleteDoctorAssignment) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: true,
				message: "Doctor Removed!",
			});
			//Check to see if the site uses Boston or Everglades for the Welcome Banner.
			if (
				Object.values(FeaturedDoctorTemplates).includes(
					(bannerData?.homeOptions
						?.template as unknown) as FeaturedDoctorTemplates
				) &&
				homeOptions &&
				savedBanner &&
				savedBanner.heroImage &&
				savedBanner.heroImage.doctor?.selectedDoctorNpi === npi
			) {
				const defaultImage = "_common/hero/groups/group-16.jpg";
				const homeOptionsWithoutWelcomeBanner = removeTypename(
					homeOptions.widgets.filter(
						(widget) => widget.name !== "welcome_banner"
					)
				);
				const bannerOptions: HomeOptionsInput = {
					template: homeOptions.template,
					accentColor: homeOptions.accentColor,
					buttonStyle: homeOptions.buttonStyle,
					aboutSection: homeOptions.aboutSection,
					widgets: [
						...homeOptionsWithoutWelcomeBanner,
						{
							...savedBanner,
							heroImage: {
								type: HeroImageTypes.Upload,
								upload: {
									publicId: defaultImage,
									altText: "test",
								},
							},
							enabled: true,
							moveable: true,
							name: "welcome_banner",
						},
					],
					header: homeOptions.header,
					footer: homeOptions.footer,
				};

				const bannerUpdate = await setHomeOptionsMutation({
					variables: {
						portalId,
						homeOptions: bannerOptions,
					},
					refetchQueries: [
						{
							query: HomeOptionsDocument,
							variables: {
								portalId,
							},
						},
					],
					awaitRefetchQueries: true,
				});
				if (bannerUpdate?.errors) {
					const error =
						bannerUpdate.errors?.[0] && mapGraphQlError(bannerUpdate.errors[0]);
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: false,
						message:
							error?.displayableError || "Welcome Banner Failed To Update!",
					});
				}
			}
		} else {
			const error = result?.errors?.[0] && mapGraphQlError(result.errors[0]);
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: error?.displayableError || "Doctor Not Removed!",
			});
		}
	};

	const handleSort = (sortedList: Array<Doctor>) => {
		formik.setFieldValue("doctors", [
			...sortedList,
			...formik.values.doctors.filter(
				({ npi }: { npi: string }) =>
					!sortedList.map((item: Doctor) => item.npi).includes(npi)
			),
		]);
	};

	return (
		<>
			<form id="doctor-assignment-form" onSubmit={formik.handleSubmit}>
				<ContentHeader
					title={routes.DOCTOR_ASSIGNMENT.title}
					button={<SaveButton form="doctor-assignment-form" />}
					tooltipText="Doctors can be sorted on this screen. The order displayed here
          is the order the doctors will be listed in the About Your
          Dentist page."
				/>
				<div>
					<div className={classes.search}>
						<TextField
							autoComplete="off"
							name="searchQuery"
							value={searchQuery}
							placeholder="Find By Doctor Name OR NPI Number"
							className={classes.search_input}
							onChange={(
								e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
							) => setSearchQuery(e.target.value)}
							onKeyDown={(e) => {
								if (e.keyCode === 13) {
									e.preventDefault();
									return false;
								}
								return undefined;
							}}
							onFocus={() => setDropdownOpen(true)}
							onBlur={() => setDropdownOpen(false)}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<SearchIcon className={classes.search_icon} />
									</InputAdornment>
								),
							}}
						/>
						{/* TODO: Figure out why the dropdown won't accept spacing in the query... */}
						{(data?.doctorSearch?.length || 0) > 0 && (
							<ul
								className={clsx({
									[classes.dropDown]: true,
									[classes.dropDownOpen]: dropdownOpen,
								})}
							>
								{data?.doctorSearch.map(({ __typename, ...doctor }) => (
									<div
										key={doctor.npi}
										className={classes.row}
										onMouseDown={() => {
											handleAssignDoctor(doctor);
										}}
									>
										<div className={classes.doctor_name}>
											{formatDoctorName(doctor as Doctor, false, true)}
										</div>
										<div className={classes.doctor_npi}>{doctor.npi}</div>
										<div className={classes.doctor_add}>
											<AddCircleIcon />
										</div>
									</div>
								))}
							</ul>
						)}
					</div>
					<div className={classes.content_title}>Assigned Doctors</div>
					<div>
						{!doctorAssignment.loading && (
							<DraggableList<
								{
									onDeleteDoctorAssignmentMutation: typeof handleDeleteDoctorAssignmentMutation;
								},
								Doctor
							>
								droppableId="doctor-assignment"
								listItems={formik.values.doctors.map((doctor) => ({
									draggable: true,
									item: doctor,
								}))}
								setListItems={handleSort}
								component={SortableDoctor}
								onDeleteDoctorAssignmentMutation={
									handleDeleteDoctorAssignmentMutation
								}
							/>
						)}
					</div>
				</div>
			</form>
			<ContentFooter>
				<SaveButton form="doctor-assignment-form" />
			</ContentFooter>
		</>
	);
};
