import React from "react";
import { Section } from "./Section";
import routes, { ProtectedRouteParams } from "../../../routes/Routes";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import SettingsIcon from "@mui/icons-material/Settings";
import PeopleIcon from "@mui/icons-material/People";
import WebIcon from "@mui/icons-material/Web";
import StorageIcon from "@mui/icons-material/Storage";
import LanguageIcon from "@mui/icons-material/Language";

const useStyles = makeStyles((theme: Theme) => ({
	icon: {
		fill: theme.color.grey.dark,
		verticalAlign: "top",
	},
}));

export const Nav: React.FC<ProtectedRouteParams> = ({ portalId }) => {
	const classes = useStyles();

	return (
		<React.Fragment>
			{portalId ? (
				<React.Fragment>
					<Section
						title="Office Admin"
						routes={[
							routes.SOCIAL_MEDIA,
							routes.OFFICE_DETAILS,
							routes.OFFICE_HOURS,
						]}
						portalId={portalId}
					>
						<SettingsIcon className={classes.icon} />
					</Section>
					<Section
						title="People"
						routes={[
							routes.DOCTOR_ASSIGNMENT,
							routes.DOCTOR_PROFILE,
							routes.ABOUT_YOUR_TEAM,
							routes.TEAM_ARRANGEMENT,
							routes.SMILE_GALLERY,
						]}
						portalId={portalId}
					>
						<PeopleIcon className={classes.icon} />
					</Section>
					<Section
						title="Configure Site"
						routes={[
							routes.HEADER,
							routes.WELCOME_BANNER,
							routes.COLORS_AND_STYLES,
							routes.HOME_PAGE_WIDGETS,
							routes.ABOUT_PAGE_WIDGETS,
							routes.FOOTER,
							routes.PRELAUNCH_OPTIONS,
							routes.MEDICAID_STATEMENT_OPTIONS,
						]}
						portalId={portalId}
					>
						<WebIcon className={classes.icon} />
					</Section>
					<Section
						title="Services Offered"
						routes={[routes.SERVICES]}
						portalId={portalId}
					>
						<StorageIcon className={classes.icon} />
					</Section>
					<Section title="Deployment" portalId={portalId} deploy={true}>
						<LanguageIcon className={classes.icon} />
					</Section>
				</React.Fragment>
			) : (
				<React.Fragment>ERROR</React.Fragment>
			)}
		</React.Fragment>
	);
};
