import React from "react";
import { makeStyles } from "@mui/styles";
import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Paper,
	Theme,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import {
	HomeOptionsDocument,
	SmileGalleryWidgetFragment,
	useDeleteSmileGalleryMemberMutation,
} from "../../types/graphql-types";
import cloudinaryUtil from "../../utils/cloudinaryUtil";
import { RichTextViewer } from "../../components/RichTextViewer";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		marginTop: "32px !important",
		paddingBottom: "40px",
		fontSize: "0.875rem",
		flexGrow: 1,
	},
	card: {
		backgroundColor: theme.color.white.light,
		borderRadius: 4,
		padding: 6,
		width: "90%",
		marginLeft: "auto",
		marginRight: "auto",
	},
	infoContainer: {
		fontSize: 18,
		margin: "8px 8px",
		padding: "0px 8px",
		display: "flex",
		justifyContent: "space-between",
	},
	name: {},
	imgContainer: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	description: {
		margin: theme.spacing(4),
	},
	headshot: {
		height: 128,
		width: 96,
		margin: "0px 8px",
		borderRadius: 8,
	},
	captionText: {
		color: theme.color.grey.main,
	},
	actionsContainer: {
		margin: 8,
		padding: 8,
		display: "flex",
		justifyContent: "space-around",
	},
	actionButton: {
		margin: "0px 164px",
		color: theme.color.grey.dark,
	},
	deleteButton: {
		marginLeft: theme.spacing(1),
	},
}));

type SmileGalleryCardProps = {
	guid: string;
	patientName: string;
	before: string;
	after: string;
	description?: string | null;
	portalId: string;
};

const SmileGalleryCard = ({
	guid,
	patientName,
	before,
	after,
	description,
	portalId,
}: SmileGalleryCardProps) => {
	const classes = useStyles();
	const [
		deleteSmileGalleryMember,
		{ loading },
	] = useDeleteSmileGalleryMemberMutation();

	const [confirmDelete, setConfirmDelete] = React.useState(false);

	return (
		<Grid item xs={6} className={classes.root}>
			<Paper className={classes.card} elevation={8}>
				<div className={classes.infoContainer}>
					<span className={classes.name}>{patientName}</span>
				</div>
				<div className={classes.imgContainer}>
					<div>
						<img className={classes.headshot} src={before} alt="before" />
						<div className={classes.captionText}>Before</div>
					</div>
					<div>
						<img className={classes.headshot} src={after} alt="after" />
						<div className={classes.captionText}>After</div>
					</div>
				</div>
				<RichTextViewer className={classes.description} value={description} />
				<div className={classes.actionsContainer}>
					{confirmDelete ? (
						<div>
							{loading ? (
								<CircularProgress />
							) : (
								<>
									<Button
										color="primary"
										variant="contained"
										onClick={() => setConfirmDelete(false)}
									>
										Cancel
									</Button>

									<Button
										className={classes.deleteButton}
										color="secondary"
										variant="contained"
										onClick={() => {
											deleteSmileGalleryMember({
												variables: { portalId, guid },
												refetchQueries: [
													{
														query: HomeOptionsDocument,
														variables: {
															portalId,
														},
													},
												],
												awaitRefetchQueries: true,
											});
										}}
									>
										Delete
									</Button>
								</>
							)}
						</div>
					) : (
						<IconButton
							size="small"
							className={classes.actionButton}
							onClick={() => setConfirmDelete(true)}
						>
							<Delete />
						</IconButton>
					)}
				</div>
			</Paper>
		</Grid>
	);
};

type SmileGalleryItemsProps = {
	members: SmileGalleryWidgetFragment["members"];
	portalId: string;
};

export const SmileGalleryItems = ({
	members,
	portalId,
}: SmileGalleryItemsProps) => {
	return (
		<Grid container spacing={10}>
			{members.map(
				(
					{
						guid,
						patientName,
						beforeImagePublicId,
						afterImagePublicId,
						description,
					},
					index
				) => (
					<SmileGalleryCard
						key={`${guid}${index}`}
						guid={guid}
						patientName={patientName}
						before={cloudinaryUtil.imageSrc({ publicId: beforeImagePublicId })}
						after={cloudinaryUtil.imageSrc({ publicId: afterImagePublicId })}
						description={description}
						portalId={portalId}
					/>
				)
			)}
		</Grid>
	);
};
