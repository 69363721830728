/**
 * Shamelessly stolen and adapted to TypeScript from:
 * https://github.com/Stanko/react-animate-height
 */
import React from "react";
import clsx from "clsx";
import { omit } from "lodash";

interface AnimateHeightProps {
	/**
	 * Numeric or percentage value, or "auto". When changed, element will be
	 * animated to that height.
	 */
	height: string | number;
	/**
	 * The component whose height changes you'd like to animate.
	 */
	children: React.ReactNode;
	/**
	 * By default, AnimateHeight will set aria-hidden to true when height is zero.
	 * If you wish to override this behavior, you can pass the prop yourself.
	 *
	 * Default: undefined
	 */
	"aria-hidden"?: boolean;
	/**
	 * If set to true, content will fade in and out while height is animated.
	 *
	 * Default: false
	 */
	animateOpacity?: boolean;
	/**
	 * Object containing CSS class names for the animation states.
	 */
	animationStateClasses?: {
		[K in keyof typeof ANIMATION_STATE_CLASSES]?: string;
	};
	/**
	 * If set to false, only CSS classes will be applied to the element, and
	 * inline transition styles will not be present (i.e. you will have to
	 * implement the styling for the class names of each step of the animation).
	 *
	 * Default: true
	 */
	applyInlineTransitions?: boolean;
	/**
	 * CSS class to be applied to the element.
	 *
	 * Do not apply properties like display, height, etc., that might break
	 * height calculations.
	 *
	 * Default: undefined
	 */
	className?: string;
	/**
	 * CSS class to be applied to the content wrapper element.
	 *
	 * Do not apply properties like display, height, etc., that might break
	 * height calculations.
	 *
	 * Default: undefined
	 */
	contentClassName?: string;
	/**
	 * Animation delay in milliseconds.
	 *
	 * Default: 0
	 */
	delay?: number;
	/**
	 * Duration of the animation in milliseconds.
	 *
	 * Default: 250
	 */
	duration?: number;
	/**
	 * CSS easing function to be applied to the animation.
	 *
	 * Default: "ease"
	 */
	easing?: string;
	/**
	 * HTML id attribute to be applied to the animation wrapper.
	 */
	id?: string;
	/**
	 * CSS style object to be merged with the inline transition styles of the
	 * component.
	 *
	 * Do not apply properties like display, height, etc., that might break
	 * height calculations.
	 */
	style?: React.CSSProperties;
	/**
	 * Callback which will be called when the animation starts.
	 */
	onAnimationEnd?: (args: { newHeight?: string | number }) => void;
	onAnimationStart?: (args: { newHeight?: string | number }) => void;
}

interface AnimateHeightState {
	height: string | number;
	overflow: string;
	animationStateClasses: string;
	shouldUseTransitions: boolean;
}

const ANIMATION_STATE_CLASSES = {
	animating: "rah-animating",
	animatingUp: "rah-animating--up",
	animatingDown: "rah-animating--down",
	animatingToHeightZero: "rah-animating--to-height-zero",
	animatingToHeightAuto: "rah-animating--to-height-auto",
	animatingToHeightSpecific: "rah-animating--to-height-specific",
	static: "rah-static",
	staticHeightZero: "rah-static--height-zero",
	staticHeightAuto: "rah-static--height-auto",
	staticHeightSpecific: "rah-static--height-specific",
};

const PROPS_TO_OMIT = [
	"animateOpacity",
	"animationStateClasses",
	"applyInlineTransitions",
	"children",
	"contentClassName",
	"delay",
	"duration",
	"easing",
	"height",
	"onAnimationEnd",
	"onAnimationStart",
];

// Start animation helper using nested requestAnimationFrames
function startAnimationHelper(callback: () => void) {
	const requestAnimationFrameIDs = [] as number[];

	requestAnimationFrameIDs[0] = requestAnimationFrame(() => {
		requestAnimationFrameIDs[1] = requestAnimationFrame(() => {
			callback();
		});
	});

	return requestAnimationFrameIDs;
}

function cancelAnimationFrames(requestAnimationFrameIDs: number[]) {
	requestAnimationFrameIDs.forEach((id) => cancelAnimationFrame(id));
}

/**
 * Whether or not the provided value is a number or numeric string
 */
function isNumber(n: unknown): boolean {
	return !isNaN(parseFloat(n as string)) && isFinite(n as number);
}

/**
 * Whether or not the provided value is a string percentage value
 */
function isPercentage(n: unknown): boolean {
	// Percentage height
	return (
		typeof n === "string" &&
		n.search("%") === n.length - 1 &&
		isNumber(n.substr(0, n.length - 1))
	);
}

function runCallback<T extends ((params: U) => void) | undefined, U>(
	callback: T,
	params: T extends (args?: infer U) => void ? U : never
): void {
	if (callback && typeof callback === "function") {
		callback(params);
	}
}

class AnimateHeight extends React.Component<
	AnimateHeightProps,
	AnimateHeightState
> {
	public static defaultProps = {
		animateOpacity: false,
		animationStateClasses: ANIMATION_STATE_CLASSES,
		applyInlineTransitions: true,
		duration: 250,
		delay: 0,
		easing: "ease",
		style: {},
	};

	private animationFrameIDs: number[];
	private animationStateClasses: Record<string, string>;
	private contentElement?: HTMLDivElement | null;
	private timeoutID?: number;
	private animationClassesTimeoutID?: number;

	constructor(props: AnimateHeightProps) {
		super(props);

		this.animationFrameIDs = [];

		let height: string | number = "auto";
		let overflow = "visible";

		if (isNumber(props.height)) {
			// If value is string "0" make sure we convert it to number 0
			height = props.height < 0 || props.height === "0" ? 0 : props.height;
			overflow = "hidden";
		} else if (isPercentage(props.height)) {
			// If value is string "0%" make sure we convert it to number 0
			height = props.height === "0%" ? 0 : props.height;
			overflow = "hidden";
		}

		this.animationStateClasses = {
			...ANIMATION_STATE_CLASSES,
			...props.animationStateClasses,
		};

		const animationStateClasses = this.getStaticStateClasses(height);

		this.state = {
			animationStateClasses,
			height,
			overflow,
			shouldUseTransitions: false,
		};
	}

	public componentDidMount() {
		const { height } = this.state;

		// Hide content if height is 0 (to prevent tabbing into it)
		// Check for contentElement is added cause this would fail in tests (react-test-renderer)
		// Read more here: https://github.com/Stanko/react-animate-height/issues/17
		if (this.contentElement && this.contentElement.style) {
			this.hideContent(height);
		}
	}

	public componentDidUpdate(
		prevProps: AnimateHeightProps,
		prevState: AnimateHeightState
	) {
		const {
			delay = AnimateHeight.defaultProps.delay,
			duration = AnimateHeight.defaultProps.duration,
			height,
			onAnimationEnd,
			onAnimationStart,
		} = this.props;

		// Check if 'height' prop has changed
		if (this.contentElement && height !== prevProps.height) {
			// Remove display: none from the content div
			// if it was hidden to prevent tabbing into it
			this.showContent(prevState.height);

			// Cache content height
			this.contentElement.style.overflow = "hidden";
			const contentHeight = this.contentElement.offsetHeight;
			this.contentElement.style.overflow = "";

			// set total animation time
			const totalDuration = duration + delay;

			let newHeight: string | number | undefined = undefined;
			const timeoutState: Partial<AnimateHeightState> = {
				height: undefined, // it will be always set to either 'auto' or specific number
				overflow: "hidden",
			};
			const isCurrentHeightAuto = prevState.height === "auto";

			if (isNumber(height)) {
				// If value is string "0" make sure we convert it to number 0
				newHeight = height < 0 || height === "0" ? 0 : height;
				timeoutState.height = newHeight;
			} else if (isPercentage(height)) {
				// If value is string "0%" make sure we convert it to number 0
				newHeight = height === "0%" ? 0 : height;
				timeoutState.height = newHeight;
			} else {
				// If not, animate to content height
				// and then reset to auto
				newHeight = contentHeight; // TODO solve contentHeight = 0
				timeoutState.height = "auto";
				timeoutState.overflow = undefined;
			}

			if (isCurrentHeightAuto) {
				// This is the height to be animated to
				timeoutState.height = newHeight;

				// If previous height was 'auto'
				// set starting height explicitly to be able to use transition
				newHeight = contentHeight;
			}

			// Animation classes
			const animationStateClasses = clsx({
				[this.animationStateClasses.animating]: true,
				[this.animationStateClasses.animatingUp]:
					prevProps.height === "auto" || height < prevProps.height,
				[this.animationStateClasses.animatingDown]:
					height === "auto" || height > prevProps.height,
				[this.animationStateClasses.animatingToHeightZero]:
					timeoutState.height === 0,
				[this.animationStateClasses.animatingToHeightAuto]:
					timeoutState.height === "auto",
				[this.animationStateClasses.animatingToHeightSpecific]:
					timeoutState.height > 0,
			});

			// Animation classes to be put after animation is complete
			const timeoutAnimationStateClasses = this.getStaticStateClasses(
				timeoutState.height
			);

			// Set starting height and animating classes
			// We are safe to call set state as it will not trigger infinite loop
			// because of the "height !== prevProps.height" check
			this.setState({
				// eslint-disable-line react/no-did-update-set-state
				animationStateClasses,
				height: newHeight,
				overflow: "hidden",
				// When animating from 'auto' we first need to set fixed height
				// that change should be animated
				shouldUseTransitions: !isCurrentHeightAuto,
			});

			// Clear timeouts
			clearTimeout(this.timeoutID);
			clearTimeout(this.animationClassesTimeoutID);

			if (isCurrentHeightAuto) {
				// When animating from 'auto' we use a short timeout to start animation
				// after setting fixed height above
				timeoutState.shouldUseTransitions = true;

				cancelAnimationFrames(this.animationFrameIDs);
				this.animationFrameIDs = startAnimationHelper(() => {
					this.setState(timeoutState as AnimateHeightState);

					// ANIMATION STARTS, run a callback if it exists
					runCallback(onAnimationStart, { newHeight: timeoutState.height });
				});

				// Set static classes and remove transitions when animation ends
				this.animationClassesTimeoutID = window.setTimeout(() => {
					this.setState({
						animationStateClasses: timeoutAnimationStateClasses,
						shouldUseTransitions: false,
					});

					// ANIMATION ENDS
					// Hide content if height is 0 (to prevent tabbing into it)
					this.hideContent(timeoutState.height);
					// Run a callback if it exists
					runCallback(onAnimationEnd, { newHeight: timeoutState.height });
				}, totalDuration);
			} else {
				// ANIMATION STARTS, run a callback if it exists
				runCallback(onAnimationStart, { newHeight });

				// Set end height, classes and remove transitions when animation is complete
				this.timeoutID = window.setTimeout(() => {
					timeoutState.animationStateClasses = timeoutAnimationStateClasses;
					timeoutState.shouldUseTransitions = false;

					this.setState(timeoutState as AnimateHeightState);

					// ANIMATION ENDS
					// If height is auto, don't hide the content
					// (case when element is empty, therefore height is 0)
					if (height !== "auto") {
						// Hide content if height is 0 (to prevent tabbing into it)
						this.hideContent(newHeight); // TODO solve newHeight = 0
					}
					// Run a callback if it exists
					runCallback(onAnimationEnd, { newHeight });
				}, totalDuration);
			}
		}
	}

	public componentWillUnmount() {
		cancelAnimationFrames(this.animationFrameIDs);

		clearTimeout(this.timeoutID);
		clearTimeout(this.animationClassesTimeoutID);

		this.timeoutID = undefined;
		this.animationClassesTimeoutID = undefined;
		this.animationStateClasses = {};
	}

	private showContent(height?: string | number) {
		if (this.contentElement && height === 0) {
			this.contentElement.style.display = "";
		}
	}

	private hideContent(newHeight?: string | number) {
		if (this.contentElement && newHeight === 0) {
			this.contentElement.style.display = "none";
		}
	}

	private getStaticStateClasses(height: string | number) {
		return clsx({
			[this.animationStateClasses.static]: true,
			[this.animationStateClasses.staticHeightZero]: height === 0,
			[this.animationStateClasses.staticHeightSpecific]: height > 0,
			[this.animationStateClasses.staticHeightAuto]: height === "auto",
		});
	}

	public render() {
		const {
			animateOpacity,
			applyInlineTransitions,
			children,
			className,
			contentClassName,
			delay,
			duration,
			easing,
			id,
			style,
		} = this.props;
		const {
			height,
			overflow,
			animationStateClasses,
			shouldUseTransitions,
		} = this.state;

		const componentStyle = {
			...style,
			height,
			overflow: overflow || style?.overflow,
		};

		if (shouldUseTransitions && applyInlineTransitions) {
			componentStyle.transition = `height ${duration}ms ${easing} ${delay}ms`;

			// Include transition passed through styles
			if (style?.transition) {
				componentStyle.transition = `${style?.transition}, ${componentStyle.transition}`;
			}

			// Add webkit vendor prefix still used by opera, blackberry...
			componentStyle.WebkitTransition = componentStyle.transition;
		}

		const contentStyle: React.CSSProperties = {};

		if (animateOpacity) {
			contentStyle.transition = `opacity ${duration}ms ${easing} ${delay}ms`;
			// Add webkit vendor prefix still used by opera, blackberry...
			contentStyle.WebkitTransition = contentStyle.transition;

			if (height === 0) {
				contentStyle.opacity = 0;
			}
		}

		const componentClasses = clsx({
			[animationStateClasses]: true,
			[className ?? ""]: className,
		});

		// Check if user passed aria-hidden prop
		const hasAriaHiddenProp = typeof this.props["aria-hidden"] !== "undefined";
		const ariaHidden = hasAriaHiddenProp
			? this.props["aria-hidden"]
			: height === 0;

		return (
			<div
				{...omit(this.props, ...PROPS_TO_OMIT)}
				aria-hidden={ariaHidden}
				className={componentClasses}
				id={id}
				style={componentStyle}
			>
				<div
					className={contentClassName}
					style={contentStyle}
					ref={(el) => (this.contentElement = el)}
				>
					{children}
				</div>
			</div>
		);
	}
}

export default AnimateHeight;
