import React, { useState } from "react";
import { Field, FieldProps, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@mui/styles";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Theme,
} from "@mui/material";
import { EditorState } from "draft-js";
import { ImageUpload } from "../../../components/ImageUpload";
import { TextFieldAdapter } from "../../../components/TextField";
import {
	getLineCount,
	getWordCount,
	RichTextEditorAdapter,
} from "../../../components/RichTextEditor";
import SaveButton from "../../../components/SaveButton";
import { required, createValidatorFunction } from "../../../utils/validators";
import {
	Tooltip,
	TooltipTypes,
	TooltipProps,
} from "../../../components/Tooltip";
import AnimateHeight from "../../../components/AnimateHeight/AnimateHeight";
import {
	CloudinaryResource,
	useUploadImageMutation,
	SmileGalleryWidgetFragment,
	SmileGalleryMemberInput,
} from "../../../types/graphql-types";
import cloudinaryUtil from "../../../utils/cloudinaryUtil";

const useStyles = makeStyles((theme: Theme) => ({
	formContainer: {
		display: "flex",
		flexWrap: "nowrap",
		flexDirection: "column",
	},
	tooltipContent: {
		"div:first-child": {
			textAlign: "right",
		},
	},
	imageUploadGroup: {
		margin: theme.spacing(4),
		[theme.breakpoints.down("md")]: {
			flexBasis: `calc(50% - ${theme.spacing(8)}px)`,
		},
	},
	imageUploadErrors: {
		// Space the error messages down to roughly match vertical position of image uploader
		marginTop: theme.spacing(6),
		flexBasis: "50%",
		"& > div": {
			// Space out the actual tooltip components slightly
			margin: theme.spacing(1),
		},
		[theme.breakpoints.down("md")]: {
			// Remove vertical spacing on small screens
			marginTop: 0,
			// Make the tooltips reorder on small screens so they show up on row 2
			"&:first-of-type": { order: 3 },
			"&:last-of-type": { order: 4 },
		},
	},
	imageUploadContainer: {
		display: "flex",
		justifyContent: "center",
		[theme.breakpoints.down("md")]: {
			flexWrap: "wrap",
		},
	},
	imageUpload: {
		height: "50%",
		width: "50%",
	},
	captionText: {
		display: "block",
		margin: "auto",
		// This should match the width of the image upload component's props
		width: 150,
		color: theme.color.grey.main,
	},
	submitContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		margin: `${theme.spacing(4)}px 0`,
	},
	checkboxControl: {
		alignItems: "flex-end",
	},
	saveButton: {
		width: "12.5%",
		marginTop: 16,
	},
}));

export type NewSmileFormValues = Omit<
	SmileGalleryMemberInput,
	"__typename" | "description" | "approverId"
> & {
	description: EditorState;
	confirmationChecked: boolean;
};

interface NewSmileFormProps {
	portalId: string;
	members?: SmileGalleryWidgetFragment["members"];
}
interface Images {
	before?: CloudinaryResource;
	after?: CloudinaryResource;
}

interface ValidationObject {
	before: TooltipProps[];
	after: TooltipProps[];
}

enum ValidationMessages {
	MultipleFaces = "There are multiple faces detected",
	MouthOccluded = "The subject's mouth may be covered or obscured",
	NotSmiling = "The subject doesn't appear to be smiling",
	Blurry = "The subject is a little too blurry",

	DifferentPeople = "There appear to be different subjects",
	NoPeople = "There doesn't appear to be a subject",
}

const validateImage = (image: CloudinaryResource): TooltipProps[] => {
	if (!image?.faces) {
		return [];
	} else if (image.faces.length === 0) {
		return [
			{
				type: TooltipTypes.Critical,
				text: ValidationMessages.NoPeople,
			},
		];
	} else if (image.faces.length > 1) {
		return [
			{
				type: TooltipTypes.Critical,
				text: ValidationMessages.MultipleFaces,
			},
		];
	} else {
		const messages: TooltipProps[] = [];

		const face = image.faces[0];
		if (face.smile < 0.5) {
			messages.push({
				type: TooltipTypes.Warning,
				text: ValidationMessages.NotSmiling,
			});
		}
		if (face.mouthOccluded) {
			messages.push({
				type: TooltipTypes.Warning,
				text: ValidationMessages.MouthOccluded,
			});
		}
		if (face.blur !== "low") {
			messages.push({
				type: TooltipTypes.Warning,
				text: ValidationMessages.Blurry,
			});
		}

		return messages;
	}
};

const validateImages = (images: Images): ValidationObject => {
	const beforeMessages = (images.before && validateImage(images.before)) || [];
	const afterMessages = (images.after && validateImage(images.after)) || [];

	const beforeFace =
		images?.before?.faces &&
		images.before.faces.length === 1 &&
		images.before.faces[0];
	const afterFace =
		images?.after?.faces &&
		images.after.faces.length === 1 &&
		images.after.faces[0];
	if (
		beforeFace &&
		afterFace &&
		(Math.abs(beforeFace?.age - afterFace?.age) > 10 ||
			beforeFace.gender !== afterFace.gender)
	) {
		afterMessages.push({
			type: TooltipTypes.Critical,
			text: ValidationMessages.DifferentPeople,
		});
	}

	function getNumericPriority(message: TooltipProps) {
		switch (message.type) {
			case TooltipTypes.Critical:
				return 0;
			case TooltipTypes.Warning:
				return 1;
			case TooltipTypes.Info:
				return 2;
			default:
				return 3;
		}
	}

	return {
		before: beforeMessages.sort(
			(a, b) => getNumericPriority(a) - getNumericPriority(b)
		),
		after: afterMessages.sort(
			(a, b) => getNumericPriority(a) - getNumericPriority(b)
		),
	};
};

const descriptionValidator = createValidatorFunction(
	Yup.object()
		.test(
			"word count",
			"Descriptions must be 30 words or less",
			(value: EditorState) => {
				return getWordCount(value.getCurrentContent()) <= 30;
			}
		)
		.test(
			"line count",
			"Descriptions must be 3 lines or less",
			(value: EditorState) => {
				return getLineCount(value.getCurrentContent()) <= 3;
			}
		)
);

const checkboxRequired = createValidatorFunction(
	Yup.boolean().oneOf([true], "Required")
);

export const NewSmileForm: React.FC<NewSmileFormProps> = ({
	portalId,
	members,
}) => {
	const classes = useStyles();
	const [images, setImages] = useState<Images>();
	const formik = useFormikContext<NewSmileFormValues>();
	const [uploadImageMutation] = useUploadImageMutation();

	const formFieldsVisible = React.useMemo(
		() => formik.values.beforeImagePublicId && formik.values.afterImagePublicId,
		[formik.values.beforeImagePublicId, formik.values.afterImagePublicId]
	);

	const imageErrors = React.useMemo(() => images && validateImages(images), [
		images,
	]);

	const handleUpload = async (fieldName: string, newImage: File) => {
		const order = fieldName === "beforeImagePublicId" ? "before" : "after";
		const imageId =
			`${order}_` +
			Math.random().toString(36).substring(2, 15) +
			Math.random().toString(36).substring(2, 15);
		const { publicId, tags } = cloudinaryUtil.smileGalleryImage(
			portalId,
			imageId
		);
		const result = await uploadImageMutation({
			variables: {
				file: newImage,
				publicId,
				tags,
				requestAdvFace: true,
			},
		});
		formik.setFieldValue(fieldName, result?.data?.uploadImage.publicId);
		setImages({ ...images, [order]: result?.data?.uploadImage });
	};

	return (
		<Form key="new-smile-form" id="new-smile-form">
			<div className={classes.formContainer}>
				<div className={classes.imageUploadContainer}>
					<div className={classes.imageUploadErrors}>
						{imageErrors?.before.map((message) => (
							<Tooltip key={`before_${message.text}`} {...message} />
						))}
					</div>
					<div className={classes.imageUploadGroup}>
						<span className={classes.captionText}>Before</span>
						<ImageUpload
							id="before"
							width={150}
							aspectRatio={2 / 3}
							onChange={(newImage) => {
								newImage && handleUpload("beforeImagePublicId", newImage);
							}}
						/>
					</div>
					<div className={classes.imageUploadGroup}>
						<span className={classes.captionText}>After</span>
						<ImageUpload
							// TODO: What is ID used for?
							id="after"
							width={150}
							aspectRatio={2 / 3}
							onChange={(newImage) =>
								newImage && handleUpload("afterImagePublicId", newImage)
							}
						/>
					</div>
					<div className={classes.imageUploadErrors}>
						{imageErrors?.after.map((message) => (
							<Tooltip key={`after_${message.text}`} {...message} />
						))}
					</div>
				</div>

				<AnimateHeight height={formFieldsVisible ? "auto" : 0} animateOpacity>
					<>
						<Grid container spacing={4}>
							<Grid item xs={12}>
								<Field
									name="patientName"
									label="Patient Name"
									component={TextFieldAdapter}
									// TODO: Validate last initial
									validate={required}
								/>
							</Grid>
						</Grid>
						<Field
							name="description"
							label="Description (Optional)"
							component={RichTextEditorAdapter}
							lineLimit={3}
							wordLimit={30}
							maxEditorHeight={130}
							validate={descriptionValidator}
							formattingOptions={{
								bold: true,
								Italic: true,
							}}
						/>
						<div className={classes.submitContainer}>
							<Field name="confirmationChecked" validate={checkboxRequired}>
								{({ field, form, meta }: FieldProps<boolean>) => (
									<FormControl
										className={classes.checkboxControl}
										error={meta.touched && !!meta.error}
									>
										<FormControlLabel
											labelPlacement="start"
											control={
												<Checkbox
													checked={field.value}
													style={{
														color:
															meta.touched && !!meta.error ? "red" : undefined,
													}}
													onChange={(e) =>
														form.setFieldValue(field.name, e.target.checked)
													}
												/>
											}
											label="I have appropriately processed the patient's privacy release form"
											style={{
												color: meta.touched && !!meta.error ? "red" : undefined,
											}}
										/>
										{meta.touched && !!meta.error && (
											<FormHelperText>{meta.error}</FormHelperText>
										)}
									</FormControl>
								)}
							</Field>
							<SaveButton
								disabled={!!formik.errors.description}
								className={classes.saveButton}
								form="new-smile-form"
								onClick={() => formik.submitForm}
							/>
						</div>
					</>
				</AnimateHeight>
			</div>
		</Form>
	);
};
