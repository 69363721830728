import React, { Dispatch, SetStateAction } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { ListItems, ListItem, DraggableComponentProps } from "./ListItems";

type DraggableListProps<TProps extends {}, TItemType extends {}> = TProps & {
	listItems: ListItem<TItemType>[];
	setListItems: Dispatch<SetStateAction<TItemType[] | null | undefined>>;
	droppableId: string;
	component: React.ComponentType<TProps & DraggableComponentProps<TItemType>>;
	className?: string;
	draggingClassName?: string;
};

export const DraggableList = <TProps extends {}, TItemType extends {}>({
	listItems,
	setListItems,
	droppableId,
	component,
	className,
	draggingClassName,
	...restProps
}: DraggableListProps<TProps, TItemType>) => {
	const handleDragEnd = (result: DropResult) => {
		if (
			!result.destination ||
			result.destination.index === result.source.index
		) {
			return;
		}

		const newListItems = Array.from(listItems.map(({ item }) => item));
		const [removed] = newListItems.splice(result.source.index, 1);
		newListItems.splice(result.destination.index, 0, removed);

		setListItems(newListItems);
	};

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<ListItems<TProps, TItemType>
						{...((restProps as unknown) as TProps)}
						provided={provided}
						snapshot={snapshot}
						listItems={listItems}
						droppableId={droppableId}
						component={component}
						className={className}
						draggingClassName={draggingClassName}
					/>
				)}
			</Droppable>
		</DragDropContext>
	);
};
