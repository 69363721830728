import React, {
	FC,
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";
import { makeStyles } from "@mui/styles";
import { Theme, IconButton, Snackbar as MuiSnackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface SnackbarProps {
	message: string;
	success: boolean;
	open: boolean;
	autoHideDuration?: number | null;
	horizontal?: "left" | "center" | "right";
	vertical?: "top" | "bottom";
}

interface GlobalSnackbarContextValues {
	snackbarProps: SnackbarProps;
	setSnackbarProps: (props: SnackbarProps) => void;
	closeSnackbar: () => void;
}

const GlobalSnackbarContext = createContext<GlobalSnackbarContextValues>({
	snackbarProps: {
		open: false,
		message: "",
		success: true,
	},
	setSnackbarProps: () => {
		// eslint-disable-next-line no-console
		console.error("Called setSnackbarProps outside of context provider.");
	},
	closeSnackbar: () => void 0,
});

const useStyles = makeStyles((theme: Theme) => ({
	success: {
		"& > div": {
			backgroundColor: theme.color.green.main,
		},
	},
	error: {
		"& > div": {
			backgroundColor: theme.color.red.main,
		},
	},
	closeAlert: {
		"& > div": {
			fill: theme.color.black.main,
		},
	},
}));

export const GlobalSnackbarProvider: FC<{}> = ({ children }) => {
	const classes = useStyles();

	const defaultSnackbarProps: Required<SnackbarProps> = React.useMemo(
		() => ({
			open: false,
			message: "",
			success: true,
			autoHideDuration: 5000,
			horizontal: "left",
			vertical: "bottom",
		}),
		[]
	);

	const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>(
		defaultSnackbarProps
	);

	const closeSnackbar = useCallback(() => {
		setSnackbarProps(defaultSnackbarProps);
	}, [setSnackbarProps, defaultSnackbarProps]);

	const value: GlobalSnackbarContextValues = useMemo(
		() => ({
			snackbarProps,
			setSnackbarProps,
			closeSnackbar,
		}),
		[snackbarProps, setSnackbarProps, closeSnackbar]
	);

	return (
		<GlobalSnackbarContext.Provider value={value}>
			{snackbarProps.open && (
				<MuiSnackbar
					open={snackbarProps.open}
					autoHideDuration={snackbarProps.autoHideDuration}
					onClose={closeSnackbar}
					anchorOrigin={{
						vertical: snackbarProps.vertical || defaultSnackbarProps.vertical,
						horizontal:
							snackbarProps.horizontal || defaultSnackbarProps.horizontal,
					}}
					message={snackbarProps.message}
					className={snackbarProps.success ? classes.success : classes.error}
					action={
						<React.Fragment>
							<IconButton
								size="small"
								aria-label="close"
								onClick={closeSnackbar}
								className={classes.closeAlert}
							>
								<CloseIcon fontSize="small" />
							</IconButton>
						</React.Fragment>
					}
				/>
			)}

			{children}
		</GlobalSnackbarContext.Provider>
	);
};

export const useGlobalSnackbar = () => useContext(GlobalSnackbarContext);
