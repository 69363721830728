import React from "react";
import { compile } from "path-to-regexp";
import { AboutYourTeam } from "./AboutYourTeam";
import { ColorsAndStyles } from "./ColorsAndStyles";
import { Dashboard } from "./Dashboard";
import { DoctorAssignment } from "./DoctorAssignment";
import { DoctorProfile } from "./DoctorProfile";
import { OfficeDetails } from "./OfficeDetails";
import { OfficeHours } from "./OfficeHours";
import { Services } from "./Services";
import { SmileGallery } from "./SmileGallery";
import { SocialMedia } from "./SocialMedia";
import { WelcomeBanner } from "./WelcomeBanner";
import { HomePageWidgets } from "./HomePageWidgets";
import { Footer } from "./Footer";
import { AboutPageWidgets } from "./AboutPageWidgets";
import { TeamArrangement } from "./TeamArrangement";
import { Header } from "./Header";
import { PrelaunchOptions } from "./PrelaunchOptions";
import { MedicaidStatementOptions } from "./MedicaidStatementOptions";

export interface Route<TParams extends object = {}> {
	path: string;
	title: string;
	/**
	 * If the component isn't provided, the route must be handled separately in
	 * the route component. Examples of when this would be necessary:
	 * 	 - the route path is not exact (e.g. wildcards are accepted)
	 *   - the order is important (e.g. a catch-all route that must go last)
	 */
	component?: React.FC<{}>;
	public?: boolean;
	toPath: (params: TParams) => string;
}

export type ProtectedRouteParams<TParams extends object = {}> = TParams & {
	portalId: string;
};

export interface ProtectedDoctorRouteParams extends ProtectedRouteParams {
	npi: string;
}

export interface ProtectedTeamMemberRouteParams extends ProtectedRouteParams {
	teamMemberId: string;
}

export interface Protected extends ProtectedRouteParams {
	teamMemberId: string;
}

export interface Routes {
	LOGIN: Route;
	HOME_PAGE: Route;
	ERROR: Route<{ code: number }>;
	DASHBOARD: Route<ProtectedRouteParams>;
	SOCIAL_MEDIA: Route<ProtectedRouteParams>;
	OFFICE_DETAILS: Route<ProtectedRouteParams>;
	OFFICE_HOURS: Route<ProtectedRouteParams>;
	DOCTOR_ASSIGNMENT: Route<ProtectedRouteParams>;
	DOCTOR_PROFILE: Route<ProtectedRouteParams>;
	DOCTOR: Route<ProtectedDoctorRouteParams>;
	ABOUT_YOUR_TEAM: Route<ProtectedRouteParams>;
	SMILE_GALLERY: Route<ProtectedRouteParams>;
	TEAM_MEMBER: Route<ProtectedTeamMemberRouteParams>;
	TEAM_ARRANGEMENT: Route<ProtectedRouteParams>;
	WELCOME_BANNER: Route<ProtectedRouteParams>;
	COLORS_AND_STYLES: Route<ProtectedRouteParams>;
	HOME_PAGE_WIDGETS: Route<ProtectedRouteParams>;
	SERVICES: Route<ProtectedRouteParams>;
	FOOTER: Route<ProtectedRouteParams>;
	PRELAUNCH_OPTIONS: Route<ProtectedRouteParams>;
	MEDICAID_STATEMENT_OPTIONS: Route<ProtectedRouteParams>;
	ABOUT_PAGE_WIDGETS: Route<ProtectedRouteParams>;
	HEADER: Route<ProtectedRouteParams>;
	PORTAL_404: Route<ProtectedRouteParams>;
}

const paths = {
	LOGIN: "/login",
	HOME_PAGE: "/",
	ERROR: "/error/:code",
	DASHBOARD: "/:portalId",
	SOCIAL_MEDIA: "/:portalId/social-media",
	OFFICE_DETAILS: "/:portalId/office-details",
	OFFICE_HOURS: "/:portalId/office-hours",
	DOCTOR_ASSIGNMENT: "/:portalId/doctor-assignment",
	DOCTOR_PROFILE: "/:portalId/doctor-profile",
	DOCTOR: "/:portalId/doctor-profile/:npi",
	ABOUT_YOUR_TEAM: "/:portalId/about-your-team",
	SMILE_GALLERY: "/:portalId/smile-gallery",
	TEAM_MEMBER: "/:portalId/about-your-team/:teamMemberId",
	TEAM_ARRANGEMENT: "/:portalId/team-arrangement",
	WELCOME_BANNER: "/:portalId/welcome-banner",
	COLORS_AND_STYLES: "/:portalId/colors-and-styles",
	HOME_PAGE_WIDGETS: "/:portalId/home-page-widgets",
	SERVICES: "/:portalId/services",
	FOOTER: "/:portalId/footer",
	PRELAUNCH_OPTIONS: "/:portalId/prelaunch",
	MEDICAID_STATEMENT_OPTIONS: "/:portalId/medicaid-statement",
	ABOUT_PAGE_WIDGETS: "/:portalId/about-page-widgets",
	HEADER: "/:portalId/header",
	PORTAL_404: "/:portalId/*",
};

const routes: Routes = {
	LOGIN: {
		path: paths.LOGIN,
		title: "Login",
		toPath: compile<{}>(paths.LOGIN),
	},
	HOME_PAGE: {
		path: paths.HOME_PAGE,
		title: "Home",
		toPath: compile<{}>(paths.HOME_PAGE),
	},
	ERROR: {
		path: paths.ERROR,
		title: "Error",
		toPath: compile(paths.ERROR),
	},
	DASHBOARD: {
		path: paths.DASHBOARD,
		title: "Dashboard",
		component: Dashboard,
		toPath: compile(paths.DASHBOARD),
	},
	SOCIAL_MEDIA: {
		path: paths.SOCIAL_MEDIA,
		title: "Social Media",
		component: SocialMedia,
		toPath: compile(paths.SOCIAL_MEDIA),
	},
	OFFICE_DETAILS: {
		path: paths.OFFICE_DETAILS,
		title: "Office Details",
		component: OfficeDetails,
		toPath: compile(paths.OFFICE_DETAILS),
	},
	OFFICE_HOURS: {
		path: paths.OFFICE_HOURS,
		title: "Office Hours",
		component: OfficeHours,
		toPath: compile(paths.OFFICE_HOURS),
	},
	DOCTOR_ASSIGNMENT: {
		path: paths.DOCTOR_ASSIGNMENT,
		title: "Doctor Assignment",
		component: DoctorAssignment,
		toPath: compile(paths.DOCTOR_ASSIGNMENT),
	},
	DOCTOR_PROFILE: {
		path: paths.DOCTOR_PROFILE,
		title: "Doctor Profile",
		component: DoctorProfile,
		toPath: compile(paths.DOCTOR_PROFILE),
	},
	DOCTOR: {
		path: paths.DOCTOR,
		title: "Doctor Profile",
		component: DoctorProfile,
		toPath: compile(paths.DOCTOR),
	},
	ABOUT_YOUR_TEAM: {
		path: paths.ABOUT_YOUR_TEAM,
		title: "About Your Team",
		component: AboutYourTeam,
		toPath: compile(paths.ABOUT_YOUR_TEAM),
	},
	SMILE_GALLERY: {
		path: paths.SMILE_GALLERY,
		title: "Smile Gallery",
		component: SmileGallery,
		toPath: compile(paths.SMILE_GALLERY),
	},
	TEAM_MEMBER: {
		path: paths.TEAM_MEMBER,
		title: "About Your Team",
		component: AboutYourTeam,
		toPath: compile(paths.TEAM_MEMBER),
	},
	TEAM_ARRANGEMENT: {
		path: paths.TEAM_ARRANGEMENT,
		title: "Team Arrangement",
		component: TeamArrangement,
		toPath: compile(paths.TEAM_ARRANGEMENT),
	},
	WELCOME_BANNER: {
		path: paths.WELCOME_BANNER,
		title: "Welcome Banner",
		component: WelcomeBanner,
		toPath: compile(paths.WELCOME_BANNER),
	},
	COLORS_AND_STYLES: {
		path: paths.COLORS_AND_STYLES,
		title: "Colors & Styles",
		component: ColorsAndStyles,
		toPath: compile(paths.COLORS_AND_STYLES),
	},
	HOME_PAGE_WIDGETS: {
		path: paths.HOME_PAGE_WIDGETS,
		title: "Home Page Widgets",
		component: HomePageWidgets,
		toPath: compile(paths.HOME_PAGE_WIDGETS),
	},
	SERVICES: {
		path: paths.SERVICES,
		title: "Services",
		component: Services,
		toPath: compile(paths.SERVICES),
	},
	FOOTER: {
		path: paths.FOOTER,
		title: "Footer Options",
		component: Footer,
		toPath: compile(paths.FOOTER),
	},
	PRELAUNCH_OPTIONS: {
		path: paths.PRELAUNCH_OPTIONS,
		title: "Prelaunch Options",
		component: PrelaunchOptions,
		toPath: compile(paths.PRELAUNCH_OPTIONS),
	},
	MEDICAID_STATEMENT_OPTIONS: {
		path: paths.MEDICAID_STATEMENT_OPTIONS,
		title: "Medicaid Statement Options",
		component: MedicaidStatementOptions,
		toPath: compile(paths.MEDICAID_STATEMENT_OPTIONS),
	},
	ABOUT_PAGE_WIDGETS: {
		path: paths.ABOUT_PAGE_WIDGETS,
		title: "About Page Widgets",
		component: AboutPageWidgets,
		toPath: compile(paths.ABOUT_PAGE_WIDGETS),
	},
	HEADER: {
		path: paths.HEADER,
		title: "Header Options",
		component: Header,
		toPath: compile(paths.HEADER),
	},
	PORTAL_404: {
		path: paths.PORTAL_404,
		title: "Not Found",
		toPath: compile(paths.ERROR),
	},
};

export default routes;
