export type Feedback = {
	feedback_title: string;
	feedback_description: string;
};

export function createFeedbackPayload(feedback: Feedback) {
	const { feedback_title, feedback_description } = feedback;

	return {
		"@type": "MessageCard",
		"@context": "https://schema.org/extensions",
		summary: "Feedback card",
		themeColor: "0078D7",
		title: feedback_title,
		sections: [
			{
				text: feedback_description,
			},
		],
		potentialAction: [
			{
				"@type": "OpenUri",
				name: "Open Lakestreet",
				targets: [
					{
						os: "default",
						uri: "https://lakestreet.heartland.com/login",
					},
				],
			},
			{
				"@type": "OpenUri",
				name: "Github Issues (Client)",
				targets: [
					{
						os: "default",
						uri:
							"https://github.com/heartlanddental/lakestreet-app-client/issues",
					},
				],
			},
			{
				"@type": "OpenUri",
				name: "Github Issues (Server)",
				targets: [
					{
						os: "default",
						uri:
							"https://github.com/heartlanddental/lakestreet-app-server/issues",
					},
				],
			},
		],
	};
}

export async function postFeedbackPayload(payload: unknown) {
	const baseUrl = "https://outlook.office.com";
	const incoming_webhook_url = `${baseUrl}/webhook/e75413bc-60d9-490e-8df8-b55fc9caecb9@ac5247c3-614f-4779-b685-83ce98154bfc/IncomingWebhook/5d8c8d8372a34bb4ab0ed40cae8067f9/57a00719-b92c-452d-8e2f-a23d8fb5d687`;

	fetch(incoming_webhook_url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": baseUrl,
			"Access-Control-Allow-Methods": "POST",
		},
		mode: "no-cors",
		body: JSON.stringify(payload),
	});
}
