import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Theme, Button, Grid, Divider } from "@mui/material";
import { Layout } from "../../components/Layout";
import { ProtectedRouteParams } from "../Routes";
import { SmileGalleryItems } from "./SmileGalleryItems";
import { NewSmile } from "./NewSmile";
import ImageGuidelines from "../../components/ImageGuidelines";
import AnimateHeight from "../../components/AnimateHeight/AnimateHeight";
import {
	useHomeOptionsQuery,
	SmileGalleryWidgetFragment,
} from "../../types/graphql-types";
import { WidgetNames } from "../../components/WidgetManager/WidgetNames";
import { UseGlobalDarkModeContext } from "../../components/GlobalDarkMode";

const useStyles = makeStyles((theme: Theme) => ({
	contentTitle: {
		fontSize: theme.typography.fontSizeContentTitle,
		fontWeight: theme.typography.fontWeightBold,
	},
	tooltipHeaderContainer: {
		display: "flex",
	},
	newSmileForm: {
		/**
		 * When the animation starts, overflow is set to hidden on this component,
		 * which collapses the negative margin on the child components.
		 *
		 * So let's just set overflow to hidden always, and compensate for what the
		 * negative margin would've done by adding some padding at the top.
		 *
		 * This has to be paddingTop, not marginTop, as padding affects the
		 * element's offsetHeight, while margin doesn't, and we need the padding
		 * here to be included in the animation's height so it isn't jerky.
		 */
		overflow: "hidden",
		paddingTop: theme.spacing(1.5),
	},
}));

export const SmileGallery: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });

	const [newSmileFormOpen, setNewSmileFormOpen] = React.useState(false);
	const [shouldRenderForm, setShouldRenderForm] = React.useState(false);
	React.useEffect(() => {
		if (!shouldRenderForm) {
			setShouldRenderForm(true);
		}
	}, [shouldRenderForm]);
	const onAnimationEnd = React.useCallback(() => {
		if (!newSmileFormOpen) {
			setShouldRenderForm(false);
		}
	}, [newSmileFormOpen]);

	const { data: homeOptionsData } = useHomeOptionsQuery({
		variables: {
			portalId: match.params.portalId,
		},
	});

	const members = React.useMemo(() => {
		const smileGalleryWidget = homeOptionsData?.homeOptions?.widgets?.find(
			({ name }) => name === WidgetNames.smile_gallery
		) as SmileGalleryWidgetFragment | undefined;
		return smileGalleryWidget?.members;
	}, [homeOptionsData]);

	const buttonColor = newSmileFormOpen ? "#EF4A25" : "#25B84A";
	const buttonHoverColor = newSmileFormOpen ? "#BC4A25" : "#258E4A";

	return (
		<Layout portalId={match.params.portalId}>
			<Grid item container justifyContent="space-between" spacing={3}>
				<Grid item className={classes.contentTitle}>
					Smile Gallery
				</Grid>
				<Grid item>
					<Button
						sx={{
							textTransform: "none",
							color: "white",
							backgroundColor: buttonColor,
							"&:hover": {
								backgroundColor: buttonHoverColor,
							},
						}}
						onClick={() => setNewSmileFormOpen(!newSmileFormOpen)}
					>
						{newSmileFormOpen ? "Cancel" : "Add New Gallery Member"}
					</Button>
				</Grid>
			</Grid>
			<AnimateHeight
				height={newSmileFormOpen ? "auto" : 0}
				animateOpacity
				onAnimationEnd={onAnimationEnd}
				contentClassName={classes.newSmileForm}
			>
				{shouldRenderForm && (
					<>
						<ImageGuidelines />
						<NewSmile
							members={members}
							setAddNewSmile={setNewSmileFormOpen}
							portalId={match.params.portalId}
						/>
						<Divider variant="middle" />
					</>
				)}
			</AnimateHeight>
			{members && (
				<SmileGalleryItems members={members} portalId={match.params.portalId} />
			)}
		</Layout>
	);
};
