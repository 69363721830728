import React from "react";
import { Field, FieldProps } from "formik";
import { makeStyles } from "@mui/styles";
import { Theme, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { FeaturedService, Service } from "../../../../types/graphql-types";
import { DraggableComponentProps } from "../../../../components/DraggableList";

const useStyles = makeStyles((theme: Theme) => ({
	selectedService: {
		borderRadius: "20px !important",
		marginLeft: "16px !important",
		marginBottom: "16px !important",
		paddingLeft: "8px !important",
	},
	removeService: {
		fill: theme.color.white.main,
		marginRight: 8,
	},
	featuredIcon: {
		color: theme.color.orange.main,
		paddingRight: theme.spacing(0.5),
	},
}));

export interface SortableFeaturedServiceProps
	extends DraggableComponentProps<FeaturedService> {
	services: Array<Service>;
	widgetIndex: number;
	disableService: (taxonomyId: string) => void;
}
/**
 * This is not very DRY, but it's the best we could do on a time crunch:
 *
 * See comment on other component for details.
 *
 * Changes made here should likely also be made in the other component until we
 * can create a more global version that combines the functionality of both.
 *
 * @see {src/routes/Services/SortableFeaturedService.tsx}
 */
export const SortableFeaturedService: React.FC<SortableFeaturedServiceProps> = ({
	services,
	item,
	provided,
	widgetIndex,
	disableService,
}) => {
	const classes = useStyles();

	const serviceText = React.useMemo(() => {
		let retVal = "";

		services?.forEach((service) => {
			if (service.taxonomyId === item.taxonomyId) {
				retVal = `${service.text} (summary)`;
			}
			service.children?.forEach((subService: Service) => {
				if (subService.taxonomyId === item.taxonomyId) {
					retVal = `${service.text} > ${subService.text}`;
				}
			});
		});

		return retVal;
	}, [services, item.taxonomyId]);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<span {...provided.dragHandleProps}>
				<DragIndicatorIcon />
			</span>
			<Field name={`widgets.${widgetIndex}.services`}>
				{({ field }: FieldProps) => (
					<Button
						{...field}
						className={classes.selectedService}
						color="primary"
						variant="contained"
						onClick={() => disableService(item.taxonomyId)}
					>
						<CloseIcon className={classes.removeService} />
						{serviceText}
					</Button>
				)}
			</Field>
		</div>
	);
};
