import React from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TextField } from "@mui/material";

export type DateFieldProps = {
	onChange: (value: string) => void;
	value: string | null;
	label?: string;
	error?: string;
	helperText?: string;
};

export const DateField: React.FC<DateFieldProps> = (props) => {
	return (
		<DesktopDatePicker
			{...props}
			value={props.value}
			onChange={props.onChange}
			renderInput={(params) => (
				<TextField
					{...params}
					sx={{
						marginBottom: "32px",
						width: "100%",
					}}
					helperText={props.error || props.helperText}
					error={props.error ? true : false}
				/>
			)}
		/>
	);
};
