/**
 * Recursively strips the __typename field out of a given type.
 *
 * - If the type is an array, it will apply itself to the inner type; e.g.,
 *   `StripTypename<Array<Foo>>` is equivalent to `Array<StripTypename<Foo>>`.
 * - If the type is an object, it will strip the `__typename` field (if it
 *   exists) off of it and then recursively apply `StripTypename` to all child
 *   fields; e.g. `StripTypename<{ foo: { __typename: string } }>` becomes
 *   `{ foo: {} }`.
 * - If the type is anything else, it will return itself; e.g.
 *   `StripTypename<string>` becomes `string`.
 */
type StripTypename<T> = T extends Array<infer InnerType>
	? Array<StripTypename<InnerType>>
	: T extends Record<string, unknown>
	? Omit<
			{
				[K in keyof T]: T[K] extends {} ? StripTypename<T[K]> : T[K];
			},
			"__typename"
	  >
	: T;

/**
 * Recursively strips the __typename field out of a given value.
 *
 * - If the value is an array, it will apply itself to the inner array values.
 * - If the value is an object, it will strip the `__typename` field (if it
 *   exists) off of it and then recursively apply `removeTypename` to all
 *   child properties.
 * - If the type is anything else, it will return itself; e.g.
 *   `removeTypename("foo")` returns `"foo"`.
 */
export default function removeTypename<T>(data: T): StripTypename<T> {
	if (Array.isArray(data)) {
		return data.map((element) => removeTypename(element)) as StripTypename<T>;
	} else if (data && typeof data === "object" && "__typename" in data) {
		const newObj = {} as StripTypename<T>;
		Object.entries(data).forEach(([key, value]) => {
			if (key !== "__typename") {
				newObj[key] = removeTypename(value);
			}
		});
		return newObj;
	} else {
		return data as StripTypename<T>;
	}
}
