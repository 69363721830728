import React from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import {
	Draggable,
	DroppableProvided,
	DraggableProvided,
	DraggableStateSnapshot,
	DroppableStateSnapshot,
} from "react-beautiful-dnd";

const useStyles = makeStyles(() => ({
	sortable_list: {
		paddingLeft: 0,
	},
}));

export interface ListItem<TItemType extends {}> {
	draggable?: boolean;
	item: TItemType;
}

export interface DraggableComponentProps<TItemType extends {}> {
	item: TItemType;
	provided: DraggableProvided;
	snapshot: DraggableStateSnapshot;
	index: number;
}

type ListItemsProps<TProps extends {}, TItemType extends {}> = TProps & {
	provided: DroppableProvided;
	snapshot: DroppableStateSnapshot;
	listItems: ListItem<TItemType>[];
	droppableId: string;
	component: React.ComponentType<TProps & DraggableComponentProps<TItemType>>;
	className?: string;
	draggingClassName?: string;
};

export const ListItems = <TProps extends {}, TItemType extends {}>({
	provided,
	snapshot,
	listItems,
	droppableId,
	component: Component,
	className,
	draggingClassName,
	...restProps
}: ListItemsProps<TProps, TItemType>) => {
	const classes = useStyles();

	return (
		<div
			className={clsx(
				classes.sortable_list,
				className,
				snapshot.isDraggingOver && draggingClassName
			)}
			{...provided.droppableProps}
			ref={provided.innerRef}
		>
			{listItems.map((item, index) => (
				<Draggable
					key={index}
					draggableId={`${droppableId}_${index}`}
					index={index}
					isDragDisabled={!item.draggable}
				>
					{(provided, snapshot) => (
						<Component
							{...((restProps as unknown) as TProps)}
							item={item.item}
							provided={provided}
							snapshot={snapshot}
							index={index}
						/>
					)}
				</Draggable>
			))}
			{provided.placeholder}
		</div>
	);
};
