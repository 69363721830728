import React from "react";
import { Box } from "@mui/material";

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export const TabPanel: React.FC<TabPanelProps> = ({
	children,
	value,
	index,
}) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`wrapped-tabpanel-${index}`}
			aria-labelledby={`wrapped-tab-${index}`}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
};
