export enum WidgetNames {
	about_office = "about_office",
	page_listing = "page_listing",
	welcome_banner = "welcome_banner",
	global_promo = "global_promo",
	home_promo = "home_promo",

	meet_the_team = "meet_the_team",
	google_block = "google_block",
	doctor_bio = "doctor_bio",
	featured_services = "featured_services",

	invisalign = "invisalign",
	mission_statement = "mission_statement",

	smile_gallery = "smile_gallery",

	about = "about",
	virtual_tour = "virtual_tour",
	esteem = "esteem",
}
