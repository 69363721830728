import React from "react";
import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import { ProtectedRouteParams } from "../Routes";
import { PrelaunchOptionsForm } from "./PrelaunchOptionsForm";

export const PrelaunchOptions: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const portalId = match?.params?.portalId?.toString() || "";

	return (
		<Layout portalId={match.params.portalId}>
			<PrelaunchOptionsForm portalId={portalId} />
		</Layout>
	);
};
