import { useMemo } from "react";
import {
	useMutation,
	MutationHookOptions,
	MutationTuple,
} from "@apollo/client/react";
import { OperationVariables } from "@apollo/client";
import { DocumentNode } from "graphql";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useCustomMutation<TData = any, TVariables = OperationVariables>(
	mutation: DocumentNode,
	options?: MutationHookOptions<TData, TVariables>
): MutationTuple<TData, TVariables> {
	/**
	 * If you don't provide an `onError` prop in the mutation options, the
	 * mutation will throw an unhandled exception on error. This deviates from the
	 * default behavior of both `useQuery` and `useLazyQuery`, which both call the
	 * default error handler in the Apollo client's error link, and then don't
	 * throw.
	 *
	 * (Note that regardless of whether `useMutation`'s `onError` prop is
	 * provided, the error link still runs as expected; there is no need to do any
	 * additional error handling that would already be covered by the error link).
	 *
	 * So to prevent uncaught exceptions from crashing the page in development,
	 * we've overridden the implementation of `useMutation` here to always provide
	 * an `onError` prop.
	 *
	 * Note that this file is used in gql-gen-config.yml in place of
	 * @apollo/client/react/hooks.
	 *
	 * TODO: Once Apollo fixes this issue within their package, we can upgrade to
	 * the fixed version and remove all of this code.
	 *
	 * @see https://github.com/apollographql/apollo-client/issues/5708
	 * @see https://github.com/apollographql/apollo-client/blob/0540f98af9e46c5cccbd5b5cd4bd03bc6b915aab/src/react/data/MutationData.ts#L76
	 */
	const defaultOptions = useMemo(() => ({}), []);
	if (!options) {
		options = defaultOptions;
	}
	if (!options.onError) {
		options.onError = () => null;
	}

	/**
	 * Also fixed here:
	 *
	 * The useMutation function returns a new object on every render, even if the
	 * properties of the object are all the same.
	 *
	 * What this means practically is that we wouldn't be able to use a mutation
	 * response as a dependency for an effect, since every rerender would return
	 * a "new" mutation response with all the same values, and that would trigger
	 * the effect to re-run, which would return the new mutation response, which
	 * would trigger the effect, etc...
	 *
	 * By memoizing the object here based on its actual properties, we can ensure
	 * it is safe to use the response as an effect dependency, while knowing that
	 * the effect(s) in question will only trigger if the actual mutation state
	 * has triggered, e.g. if it's been called, or if its loading state changes.
	 *
	 * This is a bug with Apollo client v3, but there's no actual ticket for it,
	 * and I can't be bothered to write one up only for it to sit in unread in the
	 * repo for 3 years, so we're just fixing it here.
	 */
	const [mutationFn, mutationResponse] = useMutation(mutation, options);
	const { called, client, loading, data, error } = mutationResponse;

	const memoizedMutationResponse = useMemo(
		() => ({
			called,
			client,
			loading,
			data,
			error,
		}),
		[called, client, loading, data, error]
	);

	return [mutationFn, memoizedMutationResponse];
}

export * from "@apollo/client/react";
export { useCustomMutation as useMutation };
