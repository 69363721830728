import React from "react";
import { Formik, Form, Field } from "formik";
import { string } from "yup";

import { Layout } from "../../components/Layout";
import {
	useSiteMetadataQuery,
	useEditOfficeMetadataMutation,
	OfficeMetadataInput,
	SiteMetadataDocument,
} from "../../types/graphql-types";
import routes, { ProtectedRouteParams } from "../Routes";
import { TextFieldAdapter } from "../../components/TextField";

import { makeStyles } from "@mui/styles";
import { InputAdornment, Theme } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { SnapchatIcon } from "../../icons/Snapchat";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import AccessTime from "@mui/icons-material/AccessTime"

import { RouteComponentProps } from "react-router-dom";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { mapGraphQlError } from "../../utils/errorHandler";
// import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
// import ContentFooter from "../../components/ContentFooter";

const useStyles = makeStyles((theme: Theme) => ({
	adornment: {
		marginRight: 2,
	},
	https: {
		color: `${theme.color.grey.main} !important`,
		marginLeft: 8,
	},
}));

const parseUrlForField = (url?: string | null) => {
	if (url && url !== "") {
		let revisedUrl = url;

		revisedUrl = revisedUrl.replace("https://", "");
		revisedUrl = revisedUrl.replace("http://", "");

		return revisedUrl;
	}

	return "";
};

const addHttpsToUrl = (url?: string | null) => {
	if (url && url !== "") {
		return `https://${url}`;
	}
	return null;
};

const validateUrl = (url?: string | null): string | undefined => {
	try {
		// Parse out any potential https from the user, then add the standard https
		const parsedUrl = addHttpsToUrl(parseUrlForField(url));
		if (parsedUrl) {
			string().url().validateSync(parsedUrl);
		}
	} catch (error) {
		return "Invalid URL";
	}
	return undefined;
};

export const SocialMedia: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useSiteMetadataQuery({
		variables: {
			portalId,
		},
	});

	const [
		editOfficeMetadataMutation,
		editOfficeMutationResponse,
	] = useEditOfficeMetadataMutation();

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Social Media!",
			});
		}
	}, [error, setSnackbarProps]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || editOfficeMutationResponse.loading,
		});
	}, [loading, editOfficeMutationResponse.loading, setLoadingIndicatorProps]);

	const officeMetadataResult = data?.officeMetadata;

	// Strip out __typename fields from the response for initial values
	// Memoize the initial value to optimize re-renders
	const initialValues: OfficeMetadataInput = React.useMemo(() => {
		return {
			facebookUrl: parseUrlForField(officeMetadataResult?.facebookUrl),
			twitterUrl: parseUrlForField(officeMetadataResult?.twitterUrl),
			instagramUrl: parseUrlForField(officeMetadataResult?.instagramUrl),
			youtubeUrl: parseUrlForField(officeMetadataResult?.youtubeUrl),
			snapchatUrl: parseUrlForField(officeMetadataResult?.snapchatUrl),
			linkedinUrl: parseUrlForField(officeMetadataResult?.linkedinUrl),
			// tiktokUrl: parseUrlForField(officeMetadataResult?.tiktokUrl),
		};
	}, [officeMetadataResult]);

	return (
		<Formik<OfficeMetadataInput>
			initialValues={initialValues}
			onSubmit={async (metadata: OfficeMetadataInput) => {
				const result = await editOfficeMetadataMutation({
					variables: {
						portalId,
						officeMetadata: {
							...metadata,
							facebookUrl: addHttpsToUrl(metadata.facebookUrl),
							twitterUrl: addHttpsToUrl(metadata.twitterUrl),
							instagramUrl: addHttpsToUrl(metadata.instagramUrl),
							youtubeUrl: addHttpsToUrl(metadata.youtubeUrl),
							snapchatUrl: addHttpsToUrl(metadata.snapchatUrl),
							linkedinUrl: addHttpsToUrl(metadata.linkedinUrl),
							// tiktokUrl: addHttpsToUrl(metadata.tiktokUrl),
						},
					},
					refetchQueries: [
						{
							query: SiteMetadataDocument,
							variables: {
								portalId: portalId,
							},
						},
					],
					awaitRefetchQueries: true,
				});
				if (result?.data) {
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: true,
						message: "Social Media Information Saved!",
					});
				} else {
					const error =
						result?.errors?.[0] && mapGraphQlError(result.errors[0]);
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: false,
						message:
							error?.displayableError || "Social Media Information Not Saved!",
					});
				}
			}}
			enableReinitialize={true}
		>
			{(formik) => (
				<Layout portalId={match.params.portalId}>
					<ContentHeader
						title={routes.SOCIAL_MEDIA.title}
						tooltipText="Social media links are synced from GEMS and cannot be edited here."
					/>
					<Form id="social-media-form">
						<Field
							name="facebookUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<FacebookIcon />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"facebookUrl",
										parseUrlForField(formik.getFieldProps("facebookUrl").value)
									);
								},
							}}
							label="Enter Facebook URL"
							validate={validateUrl}
							disabled={true}
						/>
						<Field
							name="twitterUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<TwitterIcon />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"twitterUrl",
										parseUrlForField(formik.getFieldProps("twitterUrl").value)
									);
								},
							}}
							label="Enter Twitter URL"
							validate={validateUrl}
							disabled={true}
						/>

						<Field
							name="instagramUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<InstagramIcon />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"instagramUrl",
										parseUrlForField(formik.getFieldProps("instagramUrl").value)
									);
								},
							}}
							label="Enter Instagram URL"
							validate={validateUrl}
							disabled={true}
						/>
						<Field
							name="youtubeUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<YouTubeIcon />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"youtubeUrl",
										parseUrlForField(formik.getFieldProps("youtubeUrl").value)
									);
								},
							}}
							label="Enter Youtube Channel"
							validate={validateUrl}
							disabled={true}
						/>
						<Field
							name="snapchatUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<SnapchatIcon />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"snapchatUrl",
										parseUrlForField(formik.getFieldProps("snapchatUrl").value)
									);
								},
							}}
							label="Enter Snapchat URL"
							validate={validateUrl}
							disabled={true}
						/>
						<Field
							name="linkedinUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<LinkedInIcon />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"linkedinUrl",
										parseUrlForField(formik.getFieldProps("tiktokUrl").value)
									);
								},
							}}
							label="Enter LinkedIn URL"
							validate={validateUrl}
							disabled={true}
						/>
						{/* <Field
							name="tiktokUrl"
							component={TextFieldAdapter}
							InputProps={{
								startAdornment: (
									<InputAdornment
										position="start"
										className={classes.adornment}
									>
										<AccessTime />
										<div className={classes.https}>https://</div>
									</InputAdornment>
								),
								onBlur: () => {
									formik.setFieldValue(
										"tiktokUrl",
										parseUrlForField(formik.getFieldProps("tiktokUrl").value)
									);
								},
							}}
							label="Enter TikTok URL"
							validate={validateUrl}
						/> */}
					</Form>
					{/* <ContentFooter>
						<SaveButton form="social-media-form" disabled={!formik.isValid} />
					</ContentFooter> */}
				</Layout>
			)}
		</Formik>
	);
};
