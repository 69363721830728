import React from "react";
import { makeStyles } from "@mui/styles";
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	Theme,
	Modal,
	Box,
	Typography,
} from "@mui/material";
import { useFormikContext, getIn } from "formik";
import { WidgetNames } from "../WidgetNames";
import { InvisalignModal } from "./Invisalign";
import { GoogleBlockModal } from "./GoogleBlock";
import { MissionStatementModal } from "./MissionStatement";
import { FeaturedServicesModal } from "./FeaturedServices";
import { EsteemModal } from "./Esteem";
import { ModalProps } from "../utils";
import { HomeOptionsInput } from "../../../types/graphql-types";
import { UseGlobalDarkModeContext } from "../../GlobalDarkMode";

interface ThemeProps {
	darkMode: boolean;
}

interface WidgetManagerModalProps extends ModalProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles((theme: Theme) => ({
	warningModal: ({ darkMode }: ThemeProps) => ({
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: darkMode ? theme.color.black.main : theme.color.white.main,
		borderRadius: "4px",
		textAlign: "center",
		padding: "10px",
	}),
}));

export const WidgetManagerModal: React.FC<WidgetManagerModalProps> = ({
	open,
	setOpen,
	widget,
	services,
	index,
}) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });
	const form = useFormikContext<HomeOptionsInput>();
	const errors = getIn(form.errors, `widgets.${index}`);

	let Component: React.FC<ModalProps> | null;
	switch (widget.name) {
		case WidgetNames.google_block:
			Component = GoogleBlockModal;
			break;
		case WidgetNames.invisalign:
			Component = InvisalignModal;
			break;
		case WidgetNames.mission_statement:
			Component = MissionStatementModal;
			break;
		case WidgetNames.featured_services:
			Component = FeaturedServicesModal;
			break;
		case WidgetNames.esteem:
			Component = EsteemModal;
			break;
		default:
			Component = null;
	}

	const onCancel = React.useCallback(() => {
		const originalIndex = form.initialValues.widgets.findIndex(
			({ name }) => widget.name === name
		);
		switch (widget.name) {
			case WidgetNames.google_block:
				form.setFieldValue(
					`widgets.${index}.option`,
					form.initialValues.widgets[originalIndex].option
				);
				break;
			case WidgetNames.invisalign:
				form.setFieldValue(
					`widgets.${index}.display`,
					form.initialValues.widgets[originalIndex].display
				);
				break;
			case WidgetNames.mission_statement:
				form.setFieldValue(
					`widgets.${index}.text`,
					form.initialValues.widgets[originalIndex].text
				);
				break;
			case WidgetNames.featured_services:
				form.setFieldValue(
					`widgets.${index}.services`,
					form.initialValues.widgets[originalIndex].services
				);
				break;
			case WidgetNames.esteem:
				form.setFieldValue(
					`widgets.${index}.videoPath`,
					form.initialValues.widgets[originalIndex].videoPath
				);
				break;
			default:
				break;
		}
		setOpen(false);
	}, [form, widget.name, index, setOpen]);

	const onDone = React.useCallback(() => {
		const originalIndex = form.initialValues.widgets.findIndex(
			({ name }) => widget.name === name
		);
		switch (widget.name) {
			case WidgetNames.google_block:
				form.initialValues.widgets[originalIndex].option =
					form.values.widgets[originalIndex].option;
				break;
			case WidgetNames.invisalign:
				form.initialValues.widgets[originalIndex].display =
					form.values.widgets[originalIndex].display;
				break;
			case WidgetNames.mission_statement:
				form.initialValues.widgets[originalIndex].text =
					form.values.widgets[originalIndex].text;
				break;
			case WidgetNames.featured_services:
				form.initialValues.widgets[originalIndex].services =
					form.values.widgets[originalIndex].services;
				break;
			case WidgetNames.esteem:
				form.initialValues.widgets[originalIndex].videoPath =
					form.values.widgets[originalIndex].videoPath;
				break;
			default:
				break;
		}
		setOpen(false);
	}, [form, widget.name, setOpen]);

	const [modalOpen, setModalOpen] = React.useState(false);
	const handleOpen = () => {
		setModalOpen(true);
	};
	const handleClose = () => {
		setModalOpen(false);
		onCancel();
	};

	function handleEscape(event: React.KeyboardEvent) {
		if (event.key === "Esc" || event.key === "Escape") {
			event.preventDefault();
			handleOpen();
		}
	}

	return (
		<Dialog
			open={open}
			onClose={(event, reason) => {
				if (reason !== "backdropClick") {
					setOpen(false);
				}
			}}
			maxWidth="md"
			fullWidth
			onBackdropClick={handleOpen}
			disableEscapeKeyDown={!!errors}
			onKeyDown={handleEscape}
		>
			<DialogContent>
				<div>
					{Component && (
						<Component widget={widget} services={services} index={index} />
					)}
				</div>
			</DialogContent>
			<DialogActions>
				<Button
					sx={{
						backgroundColor: "#d4d4d4",
						color: "black",
						padding: "2px 24px",
						position: "unset",
						textTransform: "lowercase",
						"&:hover": {
							backgroundColor: "#c5c5c5",
						},
					}}
					onClick={onCancel}
				>
					cancel
				</Button>
				<Button
					sx={{
						backgroundColor: "#FCB332",
						color: "black",
						padding: "2px 24px",
						position: "unset",
						textTransform: "lowercase",
						"&:hover": {
							backgroundColor: "#FFBA2C",
						},
					}}
					onClick={onDone}
					disabled={!!errors}
				>
					done
				</Button>
			</DialogActions>
			<Modal
				open={modalOpen}
				onClose={(event, reason) => {
					if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
						handleClose();
					}
				}}
			>
				<Box className={classes.warningModal}>
					<Typography id="modal-modal-title" variant="h6" component="h2">
						You're about to lose your progress. Do you want to close?
					</Typography>
					<div>
						<Button
							sx={{
								textTransform: "none",
								backgroundColor: "#25B84A",
								color: "black",
								margin: "4px",
								marginRight: "0",
								padding: "5px",
								"&:hover": {
									backgroundColor: "#258E4A",
								},
							}}
							onClick={handleClose}
						>
							close and lose work
						</Button>
						<Button
							sx={{
								textTransform: "none",
								backgroundColor: "#EF4A25",
								color: "black",
								margin: "4px",
								marginRight: "0",
								padding: "5px",
								"&:hover": {
									backgroundColor: "#BC4A25",
								},
							}}
							onClick={() => setModalOpen(false)}
						>
							keep working
						</Button>
					</div>
				</Box>
			</Modal>
		</Dialog>
	);
};
