import React from "react";
import { useField, useFormikContext } from "formik";

import { makeStyles } from "@mui/styles";
import { Typography, Theme, Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ModalProps, formatWidgetName } from "../../utils";
import {
	Service,
	Maybe,
	FeaturedService,
	HomeOptionsWidgetInput,
} from "../../../../types/graphql-types";
import {
	DraggableList,
	DraggableComponentProps,
} from "../../../../components/DraggableList";
import {
	SortableFeaturedService,
	SortableFeaturedServiceProps,
} from "./SortableFeaturedService";
import { ServiceCheckbox } from "./ServiceCheckbox";

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		marginBottom: "0.5rem",
	},
	secondary_text: {
		fontSize: "0.75rem",
		marginBottom: theme.spacing(1),
	},
	services: {
		display: "flex",
	},
	left: {
		height: "min-content",
		padding: "0rem 1rem",
	},
	parentService: {
		display: "flex !important",
		margin: "8px !important",
		textAlign: "left",
		width: "-webkit-fill-available",

		"&:first-child": {
			marginTop: "0px !important",
		},

		"& .MuiButton-endIcon": {
			marginLeft: "auto !important",
			paddingLeft: 8,
		},
	},
	right: {
		flex: 1,
	},
	column: {
		display: "flex",
		flexDirection: "column",
		flex: 1,
		float: "left",
		marginLeft: theme.spacing(2),

		"&:first-child": {
			marginLeft: 0,
		},
	},
	selectedServices: {
		display: "flex",
		flexDirection: "column",
		marginBottom: theme.spacing(1),
	},
	selectedServiceList: {
		fontSize: "0.875rem",
		paddingTop: theme.spacing(1),
		paddingLeft: theme.spacing(2),
	},
}));

export const FeaturedServicesModal: React.FC<ModalProps> = ({
	widget,
	services,
	index,
}) => {
	const classes = useStyles();
	const [field, , actions] = useField<HomeOptionsWidgetInput["services"]>(
		`widgets.${index}.services`
	);
	const form = useFormikContext();

	const featuredServices: FeaturedService[] = React.useMemo(
		() =>
			(field.value?.filter((s) => !!s?.taxonomyId) as FeaturedService[]) || [],
		[field]
	);
	const [selectedParentService, setSelectedParentService] = React.useState<
		Maybe<Service>
	>(services?.filter(({ enabled }) => enabled)?.[0] || null);

	const setServices = React.useCallback(
		(newServices: FeaturedService[]) => {
			actions.setValue(newServices);
			form.setFieldValue(`widgets.${index}.enabled`, newServices.length > 0);
		},
		[actions, form, index]
	);

	// On component mount, disable the widget if there are no selected services
	React.useEffect(() => {
		if (field.value?.filter((s) => !!s?.taxonomyId).length === 0) {
			form.setFieldValue(`widgets.${index}.enabled`, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const enableService = React.useCallback(
		(taxonomyId: string) => {
			if (featuredServices.length < 3) {
				const newServices = [
					...featuredServices,
					{
						taxonomyId,
						text: "",
					},
				];
				setServices(newServices);
			}
		},
		[featuredServices, setServices]
	);

	const disableService = React.useCallback(
		(taxonomyId: string) => {
			const service = featuredServices.findIndex(
				(s) => s?.taxonomyId === taxonomyId
			);
			if (service >= 0) {
				const newServices = [...featuredServices];
				newServices.splice(service, 1);
				setServices(newServices);
			}
		},
		[featuredServices, setServices]
	);

	const enabledChildServices = React.useMemo(
		() => selectedParentService?.children?.filter(({ enabled }) => enabled),
		[selectedParentService]
	);
	const col1 = React.useMemo(
		() => enabledChildServices?.slice(0, enabledChildServices.length / 2) ?? [],
		[enabledChildServices]
	);
	const col2 = React.useMemo(
		() => enabledChildServices?.slice(enabledChildServices.length / 2) ?? [],
		[enabledChildServices]
	);

	return (
		<div>
			<Typography variant="h5" className={classes.header}>
				{formatWidgetName(widget.name)}
			</Typography>
			<div className={classes.secondary_text}>
				<em>Select up to 3 featured services.</em>
			</div>
			<div className={classes.selectedServices}>
				<div>
					<Typography variant="h6">Selected:</Typography>
				</div>
				<div className={classes.selectedServiceList}>
					{featuredServices.length > 0 ? (
						<DraggableList<
							Omit<
								SortableFeaturedServiceProps,
								keyof DraggableComponentProps<FeaturedService>
							>,
							FeaturedService
						>
							droppableId="featured-services"
							listItems={featuredServices
								.filter(
									(service) => service !== null && service.taxonomyId !== ""
								)
								.map((doctor) => ({
									draggable: true,
									item: doctor,
								}))}
							setListItems={setServices}
							component={SortableFeaturedService}
							services={services || []}
							widgetIndex={index}
							disableService={disableService}
						/>
					) : (
						"None"
					)}
				</div>
			</div>
			<div className={classes.services}>
				<div className={classes.left}>
					{services
						?.filter(({ enabled }) => enabled)
						.map((service: Service) => {
							if (service.taxonomyId === selectedParentService?.taxonomyId) {
								return (
									<Button
										key={service.taxonomyId}
										variant="contained"
										color="primary"
										className={classes.parentService}
										sx={{ textTransform: "none" }}
										endIcon={<ChevronRightIcon />}
									>
										{service.text}
									</Button>
								);
							}
							return (
								<Button
									key={service.taxonomyId}
									variant="outlined"
									color="primary"
									className={classes.parentService}
									sx={{ textTransform: "none" }}
									endIcon={<ChevronRightIcon />}
									onClick={() => setSelectedParentService(service)}
								>
									{service.text}
								</Button>
							);
						})}
				</div>
				<div className={classes.right}>
					{selectedParentService && (
						<>
							<div className={classes.column}>
								<ServiceCheckbox
									featuredServices={featuredServices}
									service={selectedParentService}
									enableService={enableService}
									disableService={disableService}
									isParent
								/>
								{col1
									.filter((service) => service.hasFeaturedServiceContent)
									.map((subservice) => (
										<ServiceCheckbox
											key={subservice.taxonomyId}
											featuredServices={featuredServices}
											service={subservice}
											enableService={enableService}
											disableService={disableService}
										/>
									))}
							</div>
							<div className={classes.column}>
								{col2
									.filter((service) => service.hasFeaturedServiceContent)
									.map((subservice) => (
										<ServiceCheckbox
											key={subservice.taxonomyId}
											featuredServices={featuredServices}
											service={subservice}
											enableService={enableService}
											disableService={disableService}
										/>
									))}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
