import { HomeOptionsWidget, Service } from "../../types/graphql-types";

export const formatWidgetName = (name: string) => {
	let formattedName = "";

	let nameArray = name.split("_");

	nameArray = nameArray.map((n) => {
		const firstLetter = n.substring(0, 1).toUpperCase();
		return firstLetter + n.substring(1);
	});

	formattedName = nameArray.join(" ");

	return formattedName;
};

export interface ModalProps {
	widget: HomeOptionsWidget;
	services?: Array<Service>;
	index: number;
}
