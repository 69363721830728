import React from "react";

import { ProtectedRouteParams } from "../Routes";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import {
	AboutOptionsInput,
	useSetAboutOptionsMutation,
	GetAboutOptionsDocument,
	useGetServicesQuery,
	GetFeaturedServicesDocument,
	useGetAboutOptionsQuery,
} from "../../types/graphql-types";
import { Formik } from "formik";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { WidgetManager } from "../../components/WidgetManager";
import { mapGraphQlError } from "../../utils/errorHandler";
import removeTypename from "../../utils/removeTypename";
import routes from "../../routes/Routes";

export const AboutPageWidgets: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data: aboutData, error, loading } = useGetAboutOptionsQuery({
		variables: {
			portalId,
		},
	});

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Widgets!",
			});
		}
	}, [error, setSnackbarProps]);

	const servicesQueryResponse = useGetServicesQuery({
		variables: {
			portalId,
		},
	});

	const [
		setAboutOptionsMutation,
		setAboutOptionsMutationResponse,
	] = useSetAboutOptionsMutation();

	const aboutOptionsResult = aboutData?.getAboutOptions;

	// Strip out __typename fields from the response for initial values
	// Memoize the initial value to optimize re-renders
	const initialValues: AboutOptionsInput = React.useMemo(() => {
		const widgets = removeTypename(aboutOptionsResult?.widgets);

		return {
			widgets: widgets || [],
		};
	}, [aboutOptionsResult]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading:
				loading ||
				setAboutOptionsMutationResponse.loading ||
				servicesQueryResponse.loading,
		});
	}, [
		loading,
		setAboutOptionsMutationResponse.loading,
		setLoadingIndicatorProps,
		servicesQueryResponse.loading,
	]);

	return (
		<Layout portalId={match.params.portalId}>
			<Formik
				initialValues={initialValues}
				onSubmit={async (aboutOptions: AboutOptionsInput) => {
					const result = await setAboutOptionsMutation({
						variables: {
							portalId,
							aboutOptions,
						},
						refetchQueries: [
							{
								query: GetAboutOptionsDocument,
								variables: {
									portalId: portalId,
								},
							},
							{
								query: GetFeaturedServicesDocument,
								variables: { portalId },
							},
						],
						awaitRefetchQueries: true,
					});
					if (result?.data) {
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: true,
							message: "Widget Manager Information Saved!",
						});
					} else {
						const error =
							result?.errors?.[0] && mapGraphQlError(result.errors[0]);
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: false,
							message:
								error?.displayableError ||
								"Widget Manager Information Not Saved!",
						});
					}
				}}
				enableReinitialize={true}
			>
				{(formik) => (
					<WidgetManager
						{...formik}
						servicesQueryResponse={servicesQueryResponse}
						pageTitle={routes.ABOUT_PAGE_WIDGETS.title}
					/>
				)}
			</Formik>
		</Layout>
	);
};
