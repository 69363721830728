import { ApolloClient, InMemoryCache, from, ApolloLink } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
import { logErrorsInDev, notifyBugTracker } from "./utils/errorHandler";
import introspectionQueryResultData from "./types/introspection-result";
import {
	AuthUser,
	CloudinaryResource,
	Doctor,
	HomeOptions,
	Service,
} from "./types/graphql-types";
import routes from "./routes/Routes";

/**
 * For some reason we need to pull window.fetch out to a callback for LogRocket
 * @see https://docs.logrocket.com/docs/troubleshooting-sessions#section-apollo-client
 */
const fetch: Window["fetch"] = (...args) => window.fetch(...args);

export const createApolloCache = () =>
	new InMemoryCache({
		possibleTypes: introspectionQueryResultData.possibleTypes,
		// TODO: Find a way to type value
		dataIdFromObject: (value) => {
			switch (value.__typename) {
				case "AuthUser":
					return `${value.__typename}_${(value as AuthUser).userId}`;
				case "CloudinaryResource":
					return `${value.__typename}_${
						(value as CloudinaryResource).publicId
					}`;
				case "HomeOptions":
					return `${value.__typename}_${(value as HomeOptions).portalId}`;
				case "Doctor":
					return `${value.__typename}_${(value as Doctor).npi}`;
				case "Service":
					return `${value.__typename}_${(value as Service).taxonomyId}`;
				default:
					return undefined;
			}
		},
	});

export const createApolloClient = () => {
	const errorLink = onError((error) => {
		logErrorsInDev(error);
		notifyBugTracker(error);
		if (error.graphQLErrors?.some((err) => err.extensions?.code === 404)) {
			window.location.href = routes.ERROR.toPath({ code: 404 });
		}
	});
	const uploadLink = createUploadLink({
		uri: window.apolloServerUri || process.env.REACT_APP_APOLLO_DOMAIN,
		credentials: "include",
		fetchOptions: {
			credentials: "include",
		},
		fetch,
	});
	const cache = createApolloCache();

	return new ApolloClient({
		link: from([
			errorLink as ApolloLink,
			// `as unknown as ApolloLink` here because we're forcing a resolution of
			// graphql-upload package to a higher version with a slightly different,
			// slightly incompatible type definition. We have to do this for Node 12+
			// compatibility ಠ_ಠ
			(uploadLink as unknown) as ApolloLink,
		]),
		cache,
		connectToDevTools: process.env.NODE_ENV !== "production",
	});
};
