import React from "react";
import { Field, FieldProps } from "formik";
import { makeStyles } from "@mui/styles";
import {
	Typography,
	RadioGroup,
	FormControlLabel,
	Radio,
	Theme,
} from "@mui/material";
import { ModalProps, formatWidgetName } from "../utils";
import { InvisalignWidgetOptions } from "../../../types/graphql-types";
import invisalign from "../../../assets/images/invisalign-preferred.png";
import invisalignSplit from "../../../assets/images/invisalign-block-50.png";

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		marginBottom: "32px !important",
	},
	radio: {
		alignItems: "start !important",

		"& > .MuiFormControlLabel-label": {
			marginTop: 10,
			width: "100%",
		},
	},
	radioLabel: {
		width: "100%",
	},
	radioName: {
		marginBottom: 4,
	},
	secondary_text: {
		fontSize: "0.75rem",
		marginBottom: theme.spacing(2),
	},
	tooltip: {
		marginLeft: theme.spacing(6),
		marginRight: theme.spacing(6),
		marginBottom: theme.spacing(2),
	},
	radioImage: {
		textAlign: "center",
	},
}));

const OptionMappings = {
	[InvisalignWidgetOptions.Full]: {
		imgSrc: invisalign,
		text: "This is the standard Invisalign option.",
	},
	[InvisalignWidgetOptions.Split]: {
		imgSrc: invisalignSplit,
		text:
			"This option splits the view in half - one half being the Invisalign logo, the other text showing that the office is an Invisalign provider.",
	},
};

export const InvisalignModal: React.FC<ModalProps> = ({ widget, index }) => {
	const classes = useStyles();

	return (
		<div>
			<Typography variant="h5" className={classes.header}>
				{formatWidgetName(widget.name)} Widget Options
			</Typography>
			<Field name={`widgets.${index}.display`}>
				{({ field }: FieldProps) => (
					<RadioGroup {...field}>
						{Object.keys(InvisalignWidgetOptions).map((display) => (
							<FormControlLabel
								key={display}
								value={InvisalignWidgetOptions[display]}
								control={<Radio color="secondary" />}
								className={classes.radio}
								label={
									<div className={classes.radioLabel}>
										<div className={classes.radioName}>
											{InvisalignWidgetOptions[display] ===
											InvisalignWidgetOptions.Split
												? "Invisalign 50/50"
												: "Invisalign (default)"}
										</div>
										<div className={classes.secondary_text}>
											<em>
												{OptionMappings[InvisalignWidgetOptions[display]].text}
											</em>
										</div>
										<div className={classes.radioImage}>
											<img
												src={
													OptionMappings[InvisalignWidgetOptions[display]]
														.imgSrc
												}
												alt={display}
											/>
										</div>
									</div>
								}
							/>
						))}
					</RadioGroup>
				)}
			</Field>
		</div>
	);
};
