import {
	HomeOptionsWidgetInput,
	Templates,
	GoogleWidgetOptions,
	InvisalignWidgetOptions,
	AboutOptionsWidgetInput,
} from "../../types/graphql-types";

import global_promo from "../../assets/images/global_promo.png";
import home_promo from "../../assets/images/home_promo.png";
import about from "../../assets/images/about-services.png";
import pageListing from "../../assets/images/page-listing.png";
import doctorBio from "../../assets/images/doctor-bio.png";
import meetTheTeam from "../../assets/images/meet-the-team.png";
import featuredServices from "../../assets/images/featured-services.png";
import googleBlock from "../../assets/images/google-block-default.png";
import googleBlockTour from "../../assets/images/google-block-alt.png";
import googleBlockDir from "../../assets/images/google-block-50.png";
import missionStatement from "../../assets/images/mission-statement.png";
import invisalign from "../../assets/images/invisalign-preferred.png";
import invisalignSplit from "../../assets/images/invisalign-block-50.png";
import smileGallery from "../../assets/images/smile-gallery.png";
import esteemLogo from "../../assets/images/esteem.png";
import welcomeBanner from "../../assets/images/welcome-banner.png";
import { WidgetNames } from "./WidgetNames";

export const WidgetImageMapper = (
	widget: HomeOptionsWidgetInput | AboutOptionsWidgetInput,
	template: Templates = Templates.Acadia
) => {
	switch (widget.name) {
		case WidgetNames.welcome_banner:
			return welcomeBanner;
		case WidgetNames.global_promo:
			return global_promo;
		case WidgetNames.home_promo:
			return home_promo;
		case WidgetNames.about_office:
			return about;
		case WidgetNames.page_listing:
			return pageListing;
		case WidgetNames.doctor_bio:
			return doctorBio;
		case WidgetNames.featured_services:
			return featuredServices;
		case WidgetNames.meet_the_team:
			return meetTheTeam;
		case WidgetNames.smile_gallery:
			return smileGallery;
		case WidgetNames.google_block:
			if (!widget.enabled) {
				return googleBlock;
			}
			if ("option" in widget) {
				switch (widget.option) {
					case GoogleWidgetOptions.FiftyFifty:
						return googleBlockDir;
					case GoogleWidgetOptions.VirtualTour:
						return googleBlockTour;
					default:
						return googleBlock;
				}
			}
			return googleBlock;
		case WidgetNames.mission_statement:
			return missionStatement;
		case WidgetNames.invisalign:
			if (!widget.enabled) {
				return invisalign;
			}
			switch (widget.display) {
				case InvisalignWidgetOptions.Split:
					return invisalignSplit;
				default:
					return invisalign;
			}
		case WidgetNames.esteem:
			return esteemLogo;
		//TODO: get images for new widgets
		case WidgetNames.about:
			return invisalign;
		case WidgetNames.virtual_tour:
			return invisalign;
		default:
			return undefined;
	}
};
