import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Typography, Theme } from "@mui/material";
import { OfficeSearchResult } from "../../types/graphql-types";

import {
	getOffices,
	setOffices,
	RecentOffice,
} from "../../utils/RecentOffices";
import routes from "../../routes/Routes";
import { UseGlobalDarkModeContext } from "../../components/GlobalDarkMode";

interface ThemeProps {
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	row: {
		fontSize: "0.875rem",
		padding: 8,
		"&:nth-child(odd)": {
			backgroundColor: theme.color.grey.light,
		},
	},
	row_link: ({ darkMode }: ThemeProps) => ({
		cursor: "pointer",
		display: "block",
		width: "100%",
		color: darkMode ? theme.color.pink.main : "",
	}),
}));

export const RecentOffices: React.FC<{}> = () => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });

	const selectOffice = (office: OfficeSearchResult) => {
		setOffices(office);
	};

	return (
		<React.Fragment>
			<Typography sx={{ fontWeight: "bold" }}>Recent Offices</Typography>
			{getOffices().map((office: RecentOffice) => (
				<div key={office.portalId} className={classes.row}>
					<Link
						to={routes.DASHBOARD.toPath({ portalId: office.portalId })}
						className={classes.row_link}
						onClick={() => selectOffice(office)}
					>
						{office.officeName}
					</Link>
				</div>
			))}
		</React.Fragment>
	);
};
