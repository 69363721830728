import React from "react";
import { Formik } from "formik";
import { EditorState } from "draft-js";
import { NewSmileForm, NewSmileFormValues } from "./NewSmileForm";
import { serialize } from "../../../components/RichTextEditor/dataUtils";
import {
	useCurrentUserQuery,
	useAddSmileGalleryMemberMutation,
	SmileGalleryWidgetFragment,
	HomeOptionsDocument,
} from "../../../types/graphql-types";

type NewSmileProps = {
	setAddNewSmile: React.Dispatch<React.SetStateAction<boolean>>;
	portalId: string;
	members?: SmileGalleryWidgetFragment["members"];
};

export const NewSmile = ({
	setAddNewSmile,
	portalId,
	members,
}: NewSmileProps) => {
	const { data: currentUserData } = useCurrentUserQuery();
	const [addNewSmileGalleryMember] = useAddSmileGalleryMemberMutation();
	return (
		<Formik<NewSmileFormValues>
			initialValues={{
				patientName: "",
				beforeImagePublicId: "",
				afterImagePublicId: "",
				description: EditorState.createEmpty(),
				confirmationChecked: false,
			}}
			onSubmit={async (newSmile) => {
				if (currentUserData?.currentUser?.userId) {
					await addNewSmileGalleryMember({
						variables: {
							portalId,
							smileGalleryMember: {
								patientName: newSmile.patientName,
								beforeImagePublicId: newSmile.beforeImagePublicId,
								afterImagePublicId: newSmile.afterImagePublicId,
								approverId: currentUserData.currentUser.userId,
								description: serialize(newSmile.description),
							},
						},
						refetchQueries: [
							{
								query: HomeOptionsDocument,
								variables: {
									portalId,
								},
							},
						],
						awaitRefetchQueries: true,
					});
					setAddNewSmile(false);
				}
			}}
		>
			<NewSmileForm portalId={portalId} members={members} />
		</Formik>
	);
};
