interface ImageDimensions {
	height: number;
	width: number;
}

export const getHeightAndWidthFromDataUrl = (dataURL) =>
	new Promise<ImageDimensions>((resolve) => {
		const img = new Image();
		img.onload = () => {
			resolve({
				height: img.height,
				width: img.width,
			});
		};
		img.src = dataURL;
	});
