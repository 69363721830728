import React from "react";
import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import { ProtectedRouteParams } from "../Routes";
import { MedicaidStatementOptionsForm } from "./MedicaidStatementOptionsForm";

export const MedicaidStatementOptions: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const portalId = match?.params?.portalId?.toString() || "";

	return (
		<Layout portalId={portalId}>
			<MedicaidStatementOptionsForm portalId={portalId} />
		</Layout>
	);
};
