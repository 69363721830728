import React from "react";
import { Field } from "formik";
import { makeStyles } from "@mui/styles";
import { Theme, Typography } from "@mui/material";
import * as Yup from "yup";
import { ModalProps } from "../utils";
import {
	characterLimit,
	createValidatorFunction,
} from "../../../utils/validators";
import { TextFieldAdapter } from "../../../components/TextField";
import { MissionStatementWidget } from "../../../types/graphql-types";

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		marginBottom: "32px !important",
	},
}));

export const MissionStatementModal: React.FC<ModalProps> = ({
	widget,
	index,
}) => {
	const classes = useStyles();

	const charCount = React.useMemo(() => {
		// This logic comes from the getCharCount logic in src/components/RichTextEditor/dataUtils
		if (!(widget as MissionStatementWidget).text) {
			return 0;
		} else {
			return (
				(widget as MissionStatementWidget).text
					.replace(/(?:\r\n|\r|\n)/g, "")
					.trim().length || 0
			);
		}
	}, [widget]);

	return (
		<div>
			<Typography variant="h5" className={classes.header}>
				Office Motto Widget Options
			</Typography>
			<Field
				name={`widgets.${index}.text`}
				component={TextFieldAdapter}
				validate={createValidatorFunction(
					characterLimit(80).concat(Yup.string().required("Required"))
				)}
				helperText={`${80 - charCount} characters remaining`}
				fullWidth={true}
			/>
		</div>
	);
};
