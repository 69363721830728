import { createTheme } from "@mui/material/styles";
/*eslint-disable @typescript-eslint/no-unused-vars*/
import type * as _augmentations from "@mui/lab/themeAugmentation";

const fontFamily = `"Open Sans", "Helvetica", "Arial", sans-serif`;

enum FontWeights {
	Regular = 400,
	Bold = 700,
}

export const lightTheme = createTheme({
	spacing: 8,
	palette: {
		primary: {
			main: "#288cc6",
		},
		secondary: {
			main: "#c93e92",
		},
		additional: {
			main: "#fcb331",
			dark: "#f7a81b",
			contrastText: "#000",
		},
	},
	typography: {
		fontFamily,
		fontSizeContentTitle: "1.1rem",
		fontWeightRegular: FontWeights.Regular,
		fontWeightBold: FontWeights.Bold,
	},
	customBreakpoints: {
		lg: 1024,
		md: 720,
		sm: 540,
		xs: 361,
	},
	color: {
		grey: {
			light: "#f5f5f5",
			main: "#6c6c6c",
			dark: "#4a4a4a",
		},
		green: {
			light: "#94D264",
			main: "#25B84A",
			dark: "#258E4A",
		},
		teal: {
			main: "#12B594",
			dark: "#17aeab",
		},
		red: {
			light: "#FFDBCC",
			main: "#EF4A25",
			dark: "#BC4A25",
		},
		white: {
			main: "#fff",
		},
		black: {
			main: "#000",
		},
		transparentBlack: {
			main: "rgba(0, 0, 0, 0.5)",
		},
		sand: {
			main: "#e0d9c4",
		},
		stone: {
			main: "#f1f1f1",
		},
		purple: {
			main: "#694BAC",
		},
		yellow: {
			light: "#FEF7C2",
			main: "#FCB332",
			dark: "#FFBA2C",
		},
		orange: {
			light: "#F8A048",
			main: "#EB6532",
		},
		blue: {
			light: "#CBE5FE",
			main: "#004086",
			dark: "#004086",
		},
		pink: {
			main: "#C93E92",
			dark: "#AE2169",
		},
		darkBlue: {
			main: "#393b42",
		},
	},
	loginFormMaxWidth: 816,
	desktopMaxWidth: 1400,
	contentContainerMaxWidth: 720,
	leftSideMaxWidth: 420,
	centralBlockMaxWidth: 540,
	overrides: {
		MuiCssBaseline: {
			"@global": {
				/**
				 * CSS hack to delay the background-color transition of autofilled input
				 * elements to such a ridiculously slow timeline that the user will
				 * never see it (since we can't just directly override the native
				 * browser background-color for autofilled inputs).
				 */
				[`input:-webkit-autofill,
				input:-webkit-autofill:hover, 
				input:-webkit-autofill:focus,
				textarea:-webkit-autofill,
				textarea:-webkit-autofill:hover,
				textarea:-webkit-autofill:focus,
				select:-webkit-autofill,
				select:-webkit-autofill:hover,
				select:-webkit-autofill:focus`]: {
					transition: "background-color 9999s ease-in-out 9999s",
				},
			},
		},
		MuiButton: {
			root: {
				textTransform: "unset",
			},
		},
		MuiSkeleton: {
			text: {
				transform: "unset",
			},
		},
	},
});
