import React from "react";
import { Switch, Route } from "react-router-dom";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { Error } from "./Error";
import { Home } from "./Home";
import { Login } from "./Login";
import routes, { Route as RouteType } from "./Routes";

const Routes: React.FC<{}> = () => (
	<Switch>
		<Route path={routes.LOGIN.path} component={Login} />
		<Route path={routes.ERROR.path} component={Error} />
		<ProtectedRoute exact path={routes.HOME_PAGE.path} component={Home} />
		{Object.values(routes).map((route: RouteType) => {
			if (route.component) {
				const RouteComponent = route.public ? Route : ProtectedRoute;
				return (
					<RouteComponent
						key={route.path}
						exact
						path={route.path}
						component={route.component}
					/>
				);
			}
			return null;
		})}
		<Route path={routes.PORTAL_404.path} component={Error} />
		<Route component={Error} />
	</Switch>
);

export default Routes;
