import React from "react";

import routes, { ProtectedRouteParams } from "../Routes";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import {
	HomeOptionsInput,
	useSetHomeOptionsMutation,
	useHomeOptionsQuery,
	Templates,
	Colors,
	ButtonStyles,
	AboutSectionLayouts,
	HomeOptionsDocument,
	useGetServicesQuery,
	HeaderTypes,
	FooterTypes,
	GetFeaturedServicesDocument,
} from "../../types/graphql-types";
import { Formik } from "formik";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { mapGraphQlError } from "../../utils/errorHandler";
import removeTypename from "../../utils/removeTypename";
import { WidgetManager } from "../../components/WidgetManager";

export const HomePageWidgets: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Widgets!",
			});
		}
	}, [error, setSnackbarProps]);

	const servicesQueryResponse = useGetServicesQuery({
		variables: {
			portalId,
		},
	});

	const [
		setHomeOptionsMutation,
		setHomeOptionsMutationResponse,
	] = useSetHomeOptionsMutation();

	const homeOptionsResult = data?.homeOptions;

	// Strip out __typename fields from the response for initial values
	// Memoize the initial value to optimize re-renders
	const initialValues: HomeOptionsInput = React.useMemo(() => {
		const widgets = removeTypename(homeOptionsResult?.widgets);

		return {
			template: homeOptionsResult?.template || Templates.Acadia,
			accentColor: homeOptionsResult?.accentColor || Colors.White,
			buttonStyle: homeOptionsResult?.buttonStyle || ButtonStyles.Tab,
			aboutSection:
				homeOptionsResult?.aboutSection || AboutSectionLayouts.Default,
			widgets: widgets || [],
			header: homeOptionsResult?.header || HeaderTypes.Default,
			footer: homeOptionsResult?.footer || FooterTypes.Default,
		};
	}, [homeOptionsResult]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading:
				loading ||
				setHomeOptionsMutationResponse.loading ||
				servicesQueryResponse.loading,
		});
	}, [
		loading,
		setHomeOptionsMutationResponse.loading,
		setLoadingIndicatorProps,
		servicesQueryResponse.loading,
	]);

	return (
		<Layout portalId={match.params.portalId}>
			<Formik
				initialValues={initialValues}
				onSubmit={async (homeOptions: HomeOptionsInput) => {
					const result = await setHomeOptionsMutation({
						variables: {
							portalId,
							homeOptions,
						},
						refetchQueries: [
							{
								query: HomeOptionsDocument,
								variables: {
									portalId: portalId,
								},
							},
							{
								query: GetFeaturedServicesDocument,
								variables: { portalId },
							},
						],
						awaitRefetchQueries: true,
					});
					if (result?.data) {
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: true,
							message: "Widget Manager Information Saved!",
						});
					} else {
						const error =
							result?.errors?.[0] && mapGraphQlError(result.errors[0]);
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: false,
							message:
								error?.displayableError ||
								"Widget Manager Information Not Saved!",
						});
					}
				}}
				enableReinitialize={true}
			>
				{(formik) => (
					<WidgetManager
						{...formik}
						servicesQueryResponse={servicesQueryResponse}
						pageTitle={routes.HOME_PAGE_WIDGETS.title}
					/>
				)}
			</Formik>
		</Layout>
	);
};
