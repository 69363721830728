import React from "react";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";

import { ProtectedRouteParams } from "../Routes";
import {
	useTeamMembersQuery,
	useArrangeTeamMembersMutation,
	TeamMember,
	TeamMembersDocument,
} from "../../types/graphql-types";

import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { Formik } from "formik";
import { TeamMemberAssignmentBody } from "./TeamArrangementBody";
import { mapGraphQlError } from "../../utils/errorHandler";

export type TeamMemberAssignmentInput = {
	teamMembers: Array<TeamMember>;
};

export const TeamArrangement: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const teamMemberAssignment = useTeamMembersQuery({
		variables: {
			portalId: portalId,
		},
	});

	const [
		arrangeTeamMembers,
		arrangeTeamMembersResult,
	] = useArrangeTeamMembersMutation({
		variables: {
			portalId,
			order: [],
		},
	});

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: teamMemberAssignment.loading || arrangeTeamMembersResult.loading,
		});
	}, [
		teamMemberAssignment.loading,
		arrangeTeamMembersResult.loading,
		setLoadingIndicatorProps,
	]);

	const teamMemberAssignmentResult = teamMemberAssignment.data?.teamMembers;

	const initialValues: TeamMemberAssignmentInput = React.useMemo(() => {
		return {
			teamMembers: teamMemberAssignmentResult || [],
		};
	}, [teamMemberAssignmentResult]);

	return (
		<Layout portalId={match.params.portalId}>
			<Formik
				initialValues={initialValues}
				onSubmit={async (
					teamMemberAssignmentInput: TeamMemberAssignmentInput
				) => {
					const result = await arrangeTeamMembers({
						variables: {
							portalId,
							order: teamMemberAssignmentInput.teamMembers.map(
								({ teamMemberId }) => teamMemberId
							),
						},
						refetchQueries: [
							{
								query: TeamMembersDocument,
								variables: {
									portalId: portalId,
								},
							},
						],
						awaitRefetchQueries: true,
					});
					if (result?.data) {
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: true,
							message: "TeamMember Assignment Order Saved!",
						});
					} else {
						const error =
							result?.errors?.[0] && mapGraphQlError(result.errors[0]);
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: false,
							message:
								error?.displayableError ||
								"TeamMember Assignment Order Not Saved!",
						});
					}
				}}
				enableReinitialize={true}
			>
				{(formik) => (
					<TeamMemberAssignmentBody
						{...formik}
						portalId={portalId}
						teamMemberAssignment={teamMemberAssignment}
						arrangeTeamMembers={arrangeTeamMembers}
					/>
				)}
			</Formik>
		</Layout>
	);
};
