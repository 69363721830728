export default {
	imageSrc: ({
		version,
		publicId,
		transforms,
	}: {
		publicId: string;
		version?: number | null;
		transforms?: string;
	}) =>
		`https://res.cloudinary.com/heartland-dental/image/upload/${
			transforms ? `${transforms}/` : ""
		}v${version ?? 1}/${publicId}`,
	officeLogoSrc: (contentUrl: string) =>
		`https://res.cloudinary.com/heartland-dental/t_logo-black/logos/${contentUrl}`,
	doctor: (npi: number | string) => ({
		publicId: `doctorphotos/${npi}`,
	}),
	teamMember: (
		portal_id: number | string,
		team_member_id: number | string
	) => ({
		publicId: `_practice/${portal_id}/team/${team_member_id}`,
	}),
	smileGallery: (portal_id: number | string) => ({
		publicId: `_practice/${portal_id}/smilegallery`,
	}),
	officeExterior: (portal_id: number | string) => ({
		publicId: `_practice/${portal_id}/location/exterior`,
	}),
	customHeroImage: (
		portal_id: number | string,
		hero_id: string,
		tag: string
	) => ({
		publicId: `_practice/${portal_id}/hero/${hero_id}`,
		tags: [`${tag}_custom`, portal_id.toString(), `${portal_id}_${tag}`],
	}),
	smileGalleryImage: (portal_id: number | string, image_id: string) => ({
		publicId: `_practice/${portal_id}/smile_gallery/${image_id}`,
		tags: ["smile_gallery", portal_id.toString(), `${portal_id}_smile`],
	}),
};
