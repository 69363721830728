import { useEffect, useRef } from "react";

export default function useInterval(callback: () => void, delay: number) {
	const savedCallback = useRef<() => void>();

	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	useEffect(() => {
		function tick() {
			if (savedCallback.current) {
				savedCallback.current();
			}
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		} else {
			return undefined;
		}
	}, [delay]);
}
